import { FC, useEffect } from "react";

interface Props {
  title?: string;
}

const DocumentTitle: FC<Props> = ({ title }) => {
  useEffect(() => {
    if (title) {
      document.title = title;
    }
  }, [title]);
  return null;
};

export default DocumentTitle;
