import { Column as DataGridColumn } from "react-data-grid";
import { TableColumn, TableRow } from "../../../../types";
import { BASE_COLUMN_PROPS } from "../constants";
import getEditorForColumn from "../editors/getEditorForColumn";
import getFormatterForColumn from "../formatters/getFormatterForColumn";
import HeaderRenderer from "../HeaderRenderer";

export const getDataGridColumn = (
  column: TableColumn
): DataGridColumn<TableRow> => ({
  ...BASE_COLUMN_PROPS,
  ...column,
  editor: getEditorForColumn(column),
  formatter: getFormatterForColumn(column),
  minWidth: column.type === "boolean" ? 50 : undefined,
  resizable: true,
  headerRenderer: HeaderRenderer,
  ...(column.type === "function" ? { cellClass: "rdg-function-cell" } : {}),
  ...(column.width ? { width: column.width } : {}),
});
