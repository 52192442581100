// Source: https://github.com/browserify/vm-browserify/blob/master/index.js

import { v4 as uuid4 } from "uuid";
import _, { isRegExp } from "lodash";
import memoize from "memoize-weak";
import { defineProp } from "./utils";

declare global {
  interface Window {
    eval: (code: string) => unknown;
    execScript?: (code: string) => unknown;
    [key: string]: unknown;
  }
}

const globals = [
  "Array",
  "Boolean",
  "Date",
  "Error",
  "EvalError",
  "Function",
  "Infinity",
  "JSON",
  "Math",
  "NaN",
  "Number",
  "Object",
  "RangeError",
  "ReferenceError",
  "RegExp",
  "String",
  "SyntaxError",
  "TypeError",
  "URIError",
  "decodeURI",
  "decodeURIComponent",
  "encodeURI",
  "encodeURIComponent",
  "escape",
  "eval",
  "isFinite",
  "isNaN",
  "parseFloat",
  "parseInt",
  "undefined",
  "unescape",
];

class Context {
  [key: string]: unknown;
}

const getIframeId = (id: string) => `eval-context-${id}`;

export class Script {
  private code: string;
  private id: string;
  private isPersistent: boolean;

  constructor(code: string, id?: string) {
    this.code = code;
    this.id = getIframeId(id ?? uuid4());
    this.isPersistent = Boolean(id);
  }

  private getOrCreateIframe = () => {
    const iframe =
      (document.getElementById(this.id) as HTMLIFrameElement) ??
      document.createElement("iframe");

    iframe.setAttribute("id", this.id);

    return iframe;
  };

  runInContext = (context: Context) => {
    if (!(context instanceof Context)) {
      throw new TypeError("needs a 'context' argument.");
    }

    const iframe = this.getOrCreateIframe();
    if (!iframe.style) {
      // @ts-expect-error
      iframe.style = {};
    }
    iframe.style.display = "none";

    document.body.appendChild(iframe);

    const win = iframe.contentWindow!;
    let wEval = win.eval;
    let wExecScript = win.execScript;

    if (!wEval && wExecScript) {
      // win.eval() magically appears when this is called in IE:
      wExecScript.call(win, "null");
      wEval = win.eval;
    }

    _.forEach(_.keys(context), (key) => {
      const value = context[key];

      // EXPLANATION: RegExp has to be copied
      if (isRegExp(value)) {
        win[key] = new RegExp(value);
      }
      // Everything else can be referenced
      else {
        win[key] = context[key];
      }
    });

    _.forEach(globals, (key) => {
      if (context[key]) {
        win[key] = context[key];
      }
    });

    const winKeys = _.keys(win);

    try {
      return wEval.call(win, this.code);
    } catch (error: unknown) {
      console.error(`Failed to evaluate ${this.id}`, error);
      throw error;
    } finally {
      _.forEach(_.keys(win), (key) => {
        // Avoid copying circular objects like `top` and `window` by only
        // updating existing context properties or new properties in the `win`
        // that was only introduced after the eval.
        if (key in context || _.indexOf(winKeys, key) === -1) {
          context[key] = win[key];
        }
      });

      _.forEach(globals, (key) => {
        if (!(key in context)) {
          defineProp(context, key, win[key]);
        }
      });

      if (!this.isPersistent) {
        document.body.removeChild(iframe);
      }
    }
  };

  runInNewContext = (context: Context) => {
    const ctx = this.createContext(context);
    const res = this.runInContext(ctx);

    return res;
  };

  createContext = (context: Context) => {
    const copy = new Context();
    if (typeof context === "object") {
      _.forEach(_.keys(context), (key) => {
        copy[key] = context[key];
      });
    }
    return copy;
  };
}

export const isContext = (context: unknown) => context instanceof Context;

export const runInNewContext = memoize(
  (code: string, context: Context, persistentContextId?: string) =>
    new Script(code, persistentContextId).runInNewContext(context)
);
