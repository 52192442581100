import { css } from "@emotion/react";
import { mdiClose } from "@mdi/js";
import Icon from "@mdi/react";
import { isEmpty, last } from "lodash";
import { ChangeEvent, FC, RefObject, useEffect } from "react";
import { Colors, flatButtonCss } from "../../styles";
import { formFieldCss } from "../Form";
import { PromptEditor } from "./types";

interface StringEditorProps {
  index: number;
  value: string;
  setValue: (update: string, index: number) => void;
  deleteValue: (index: number) => void;
  focusRef?: RefObject<HTMLInputElement>;
  placeholder?: string;
  isLast?: boolean;
}

const layoutCss = css`
  ${formFieldCss}

  display: grid;
  grid-template-columns: 1fr 26px;
  gap: 5px;
  align-items: center;
  justify-content: stretch;
  flex-direction: row;
  margin-bottom: 5px;

  & > input {
    width: 100%;
  }

  & > button {
    height: 26px;
    min-width: auto;
  }
`;

const StringEditor: FC<StringEditorProps> = ({
  index,
  isLast,
  value,
  setValue,
  deleteValue,
  focusRef,
  placeholder,
}) => {
  const onChangeValue = (event: ChangeEvent<HTMLInputElement>) => {
    setValue(event.target.value, index);
  };

  const onDelete = () => {
    deleteValue(index);
  };

  return (
    <div css={layoutCss}>
      <input
        ref={focusRef}
        name="prompt-value"
        type="text"
        autoComplete="off"
        placeholder={placeholder}
        value={value}
        onChange={onChangeValue}
      />
      {!isLast && (
        <button type="button" css={flatButtonCss} onClick={onDelete}>
          <Icon path={mdiClose} size={0.7} color={Colors.neutral600} />
        </button>
      )}
    </div>
  );
};

const updateValues = (values: string[], update: string, index: number) => {
  const updated = values.map((v, i) => (i === index ? update : v));

  if (!isEmpty(last(updated))) {
    return [...updated, ""];
  }

  return updated;
};

const StringListEditor: PromptEditor<string[]> = ({
  message,
  placeholder,
  value: values,
  setValue: setValues,
  focusRef,
}) => {
  const setValue = (update: string, index: number) => {
    setValues(updateValues(values, update, index));
  };

  const deleteValue = (index: number) => {
    const updated = values.filter((_value, i) => i !== index);
    setValues(isEmpty(updated) ? [""] : updated);
  };

  useEffect(() => {
    setValues(
      isEmpty(values) ? [""] : isEmpty(last(values)) ? values : [...values, ""]
    );
  });

  return (
    <div css={formFieldCss}>
      <label htmlFor="prompt-value">{message}</label>
      {values.map((value, index) => (
        <StringEditor
          key={index}
          focusRef={index === 0 ? focusRef : undefined}
          isLast={index === values.length - 1}
          placeholder={placeholder}
          index={index}
          value={value}
          setValue={setValue}
          deleteValue={deleteValue}
        />
      ))}
    </div>
  );
};

export default StringListEditor;
