import { css, Global } from "@emotion/react";
import DataGrid, { RowRendererProps } from "react-data-grid";
import { createPortal } from "react-dom";
import {
  mdiTableRowPlusBefore,
  mdiTableRowPlusAfter,
  mdiTableColumnPlusBefore,
  mdiTableColumnPlusAfter,
  mdiTrashCanOutline,
  mdiCog,
  mdiImport,
} from "@mdi/js";
import {
  useTableElement,
  useDeleteTableColumn,
  useDeleteTableRow,
  useInsertTableColumnAfter,
  useInsertTableColumnBefore,
  useInsertTableRowAbove,
  useInsertTableRowBelow,
  useUpdateTableColumn,
  useUpdateTableRows,
  useAppendTableColumn,
  usePromptToUpgrade,
  useImportExcelData,
} from "../../../state";
import {
  ElementActionsComponent,
  ElementComponent,
} from "../../../components/types";
import { useCallback, useMemo, MouseEvent, useState, Fragment } from "react";
import { ContextMenu } from "react-contextmenu";
import { ContextMenuItem } from "../../../components/ContextMenu";
import { containerCss, contextMenuCss, dataGridCss, globalCss } from "./styles";
import { RowMenuIdProvider } from "./RowMenuIdContext";
import {
  ColumnContextMenuParams,
  OnCommitColumnChange,
  RowContextMenuParams,
} from "./types";
import RowRenderer from "./RowRenderer";
import { ROW_HEIGHT, ROW_NUMBER_COLUMN } from "./constants";
import { getColumnName } from "./utils";
import { ColumnMenuIdProvider } from "./ColumnMenuIdContext";
import { TableColumn, TableRow } from "../../../types";
import NewColumnModal from "./NewColumnModal";
import EditColumnModal from "./EditColumnModal";
import { debounce, isNumber, isUndefined, omit } from "lodash";
import {
  IsomorphicPickFileMenuItem,
  MenuItem,
  MenuSeparator,
} from "../../../components/Menu";
import { useColumns } from "./hooks/useColumns";
import { useRows } from "./hooks/useRows";
import { useMaxHeight } from "./hooks/useMaxHeight";
import { AddRowButton } from "./AddRowButton";
import { AddColumnButton } from "./AddColumnButton";
import { isFeatureEnabled, isFeatureUpgradable } from "../../../feature-flags";
import { uint8ArrayToBuffer } from "../../../utils/buffers";
import { useShowToast } from "../../../toasts";

const layoutCss = css`
  display: grid;
  grid-template-columns: 1fr 32px;
`;

const TableElement: ElementComponent = ({ elementId }) => {
  const element = useTableElement(elementId);
  const { columns = [] } = element;

  const updateTableRows = useUpdateTableRows(elementId);
  const onRowsChange = useCallback(
    (update: TableRow[]) => {
      const updatedRows = update.map((row) => omit(row, ROW_NUMBER_COLUMN.key));
      return updateTableRows(updatedRows);
    },
    [updateTableRows]
  );

  const gridColumns = useColumns(elementId);
  const gridRows = useRows(elementId);

  const deleteTableRow = useDeleteTableRow(elementId);
  const onDeleteRow = useCallback(
    (_e: MouseEvent<HTMLDivElement>, { rowIndex }: RowContextMenuParams) => {
      deleteTableRow(rowIndex);
    },
    [deleteTableRow]
  );

  const insertTableRowAbove = useInsertTableRowAbove(elementId);
  const onRowInsertAbove = useCallback(
    (_e: MouseEvent<HTMLDivElement>, { rowIndex }: RowContextMenuParams) => {
      insertTableRowAbove(rowIndex);
    },
    [insertTableRowAbove]
  );

  const insertTableRowBelow = useInsertTableRowBelow(elementId);
  const onRowInsertBelow = useCallback(
    (_e: MouseEvent<HTMLDivElement>, { rowIndex }: RowContextMenuParams) => {
      insertTableRowBelow(rowIndex);
    },
    [insertTableRowBelow]
  );

  const updateColumn = useUpdateTableColumn(elementId);
  const [onCommitColumnUpdate, setOnCommitColumnUpdate] =
    useState<OnCommitColumnChange | null>(null);
  const [columnToUpdateIndex, setColumnToUpdateIndex] = useState<
    number | null
  >();
  const onCancelColumnUpdate = useCallback(() => {
    setColumnToUpdateIndex(null);
    setOnCommitColumnUpdate(null);
  }, []);
  const onConfigureColumn = useCallback(
    (
      _e: MouseEvent<HTMLDivElement>,
      { columnIndex }: ColumnContextMenuParams
    ) => {
      const columIndexMinusRowNumberColumn = columnIndex - 1;

      setColumnToUpdateIndex(columIndexMinusRowNumberColumn);

      setOnCommitColumnUpdate(() => (update: TableColumn) => {
        setColumnToUpdateIndex(null);
        setOnCommitColumnUpdate(null);

        updateColumn(columIndexMinusRowNumberColumn, update);
      });
    },
    [updateColumn]
  );

  const deleteTableColumn = useDeleteTableColumn(elementId);
  const onDeleteColumn = useCallback(
    (
      _e: MouseEvent<HTMLDivElement>,
      { columnIndex }: ColumnContextMenuParams
    ) => {
      deleteTableColumn(columnIndex - 1);
    },
    [deleteTableColumn]
  );

  const [newColumnIndex, setNewColumnIndex] = useState<number | undefined>();
  const [onAddColumn, setOnAddColumn] = useState<OnCommitColumnChange | null>(
    null
  );
  const onCancelAddColumn = useCallback(() => {
    setOnAddColumn(null);
  }, []);

  const insertTableColumnBefore = useInsertTableColumnBefore(elementId);
  const onColumnInsertBefore = useCallback(
    (
      _e: MouseEvent<HTMLDivElement>,
      { columnIndex }: ColumnContextMenuParams
    ) => {
      setNewColumnIndex(columnIndex - 1);
      setOnAddColumn(() => (newColumn: TableColumn) => {
        setOnAddColumn(null);
        insertTableColumnBefore(columnIndex - 1, newColumn);
      });
    },
    [insertTableColumnBefore]
  );

  const insertTableColumnAfter = useInsertTableColumnAfter(elementId);
  const onColumnInsertAfter = useCallback(
    (
      _e: MouseEvent<HTMLDivElement>,
      { columnIndex }: ColumnContextMenuParams
    ) => {
      setNewColumnIndex(columnIndex);
      setOnAddColumn(() => (newColumn: TableColumn) => {
        setOnAddColumn(null);
        insertTableColumnAfter(columnIndex - 1, newColumn);
      });
    },
    [insertTableColumnAfter]
  );

  const appendTableColumn = useAppendTableColumn(elementId);
  const onAppendColumn = useCallback(() => {
    setNewColumnIndex(columns.length);
    setOnAddColumn(() => (newColumn: TableColumn) => {
      setOnAddColumn(null);
      appendTableColumn(newColumn);
    });
  }, [columns.length, appendTableColumn]);

  const maxHeight = useMaxHeight(elementId);
  const rowMenuId = `data-grid-context-menu-${elementId}`;
  const columnMenuId = `data-grid-column-context-menu-${elementId}`;

  const onReorderRow = useCallback(
    (fromIndex: number, toIndex: number) => {
      updateTableRows((rows: TableRow[]) => {
        const newRows = [...rows];
        newRows.splice(toIndex, 0, newRows.splice(fromIndex, 1)[0]);
        return newRows.map((row) => omit(row, ROW_NUMBER_COLUMN.key));
      });
    },
    [updateTableRows]
  );

  const debouncedUpdateColumn = useMemo(
    () => debounce(updateColumn),
    [updateColumn]
  );
  const onResizeColumn = useCallback(
    (columnIndex: number, width: number) => {
      debouncedUpdateColumn(columnIndex - 1, { width });
    },
    [debouncedUpdateColumn]
  );

  const ReorderableRowRenderer = useCallback(
    (props: RowRendererProps<TableRow>) => (
      <RowRenderer {...props} onReorderRow={onReorderRow} />
    ),
    [onReorderRow]
  );

  const components = useMemo(
    () => ({ rowRenderer: ReorderableRowRenderer }),
    [ReorderableRowRenderer]
  );

  return (
    <Fragment>
      <div css={containerCss}>
        <ColumnMenuIdProvider value={columnMenuId}>
          <RowMenuIdProvider value={rowMenuId}>
            <Global styles={[globalCss, contextMenuCss]} />

            <div css={layoutCss}>
              <DataGrid
                className="rdg-light fill-grid"
                css={dataGridCss(maxHeight)}
                columns={gridColumns}
                rows={gridRows}
                onRowsChange={onRowsChange}
                onColumnResize={onResizeColumn}
                rowHeight={ROW_HEIGHT}
                components={components}
              />

              <AddColumnButton onAddColumn={onAppendColumn} />

              <AddRowButton elementId={elementId} />
            </div>

            {/* Per-row context menu */}
            {createPortal(
              <div>
                {/* @ts-expect-error */}
                <ContextMenu id={rowMenuId}>
                  <ContextMenuItem
                    label="Insert row above"
                    icon={mdiTableRowPlusBefore}
                    onClick={onRowInsertAbove}
                  />
                  <ContextMenuItem
                    label="Insert row below"
                    icon={mdiTableRowPlusAfter}
                    onClick={onRowInsertBelow}
                  />
                  <MenuSeparator />
                  <ContextMenuItem
                    label="Delete row"
                    icon={mdiTrashCanOutline}
                    onClick={onDeleteRow}
                  />
                </ContextMenu>
              </div>,
              document.body
            )}

            {/* Per-column context menu */}
            {createPortal(
              <div>
                {/* @ts-expect-error */}
                <ContextMenu id={columnMenuId}>
                  <ContextMenuItem
                    label="Configure column"
                    icon={mdiCog}
                    onClick={onConfigureColumn}
                  />
                  <MenuSeparator />
                  <ContextMenuItem
                    label="Insert column before"
                    icon={mdiTableColumnPlusBefore}
                    onClick={onColumnInsertBefore}
                  />
                  <ContextMenuItem
                    label="Insert column after"
                    icon={mdiTableColumnPlusAfter}
                    onClick={onColumnInsertAfter}
                  />
                  <MenuSeparator />
                  <ContextMenuItem
                    label="Delete column"
                    icon={mdiTrashCanOutline}
                    onClick={onDeleteColumn}
                  />
                </ContextMenu>
              </div>,
              document.body
            )}
          </RowMenuIdProvider>
        </ColumnMenuIdProvider>
      </div>

      {onAddColumn && !isUndefined(newColumnIndex) && (
        <NewColumnModal
          elementId={elementId}
          onCancel={onCancelAddColumn}
          onCommit={onAddColumn}
          columnIndex={newColumnIndex}
          initialValue={{
            key: getColumnName(columns.length),
            name: getColumnName(columns.length),
            type: "string",
          }}
          prohibitedColumnNames={columns.map(({ name }) => name)}
        />
      )}

      {onCommitColumnUpdate && isNumber(columnToUpdateIndex) && (
        <EditColumnModal
          elementId={elementId}
          onCancel={onCancelColumnUpdate}
          onCommit={onCommitColumnUpdate}
          initialValue={columns[columnToUpdateIndex]}
          columnIndex={columnToUpdateIndex}
          prohibitedColumnNames={columns
            .filter(({ name }) => name !== columns[columnToUpdateIndex].name)
            .map(({ name }) => name)}
        />
      )}
    </Fragment>
  );
};

const MenuItems: ElementActionsComponent = ({ elementId, closeMenu }) => {
  const importData = useImportExcelData(elementId);
  const promptToUpgrade = usePromptToUpgrade();
  const showToast = useShowToast();

  const onImportData = useCallback(
    async (filename: string, data: unknown) => {
      closeMenu();

      if (data instanceof Uint8Array) {
        importData(filename, uint8ArrayToBuffer(data));
      } else if (data instanceof ArrayBuffer) {
        importData(filename, data);
      } else {
        console.error(`Unsupported format`, typeof data);
        showToast({ message: "Unsupported data format", level: "danger" });
      }
    },
    [importData, closeMenu, showToast]
  );

  const onPromptToUpgrade = useCallback(() => {
    closeMenu();
    promptToUpgrade("upgrade:import:table");
  }, [closeMenu, promptToUpgrade]);

  return (
    <Fragment>
      {isFeatureEnabled("filesystem:import:table") ? (
        <IsomorphicPickFileMenuItem
          label="Import table data"
          icon={mdiImport}
          extensions={["xlsx"]}
          encoding="buffer"
          onLoaded={onImportData}
        />
      ) : (
        <MenuItem
          label="Import XLSX data"
          icon={mdiImport}
          onClick={onPromptToUpgrade}
          isUpgrade={isFeatureUpgradable("filesystem:import:table")}
        />
      )}
    </Fragment>
  );
};

TableElement.MenuItems = MenuItems;

export default TableElement;
