import _ from "lodash";
import { BuiltIn } from "./types";

const builtIn: BuiltIn = {
  name: "Lodash",
  description: "The entire Lodash library is available",
  examples: ["_.chain(['a', 'b', 'c']).map(_.toUpper).join('').value()"],
  apply: _,
};

export default builtIn;
