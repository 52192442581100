import { FC, useCallback } from "react";
import { HeaderRendererProps } from "react-data-grid";
import { ContextMenuTrigger } from "react-contextmenu";
import { TableColumnAlignment, TableRow } from "../../../types";
import { useColumnMenuId } from "./ColumnMenuIdContext";
import { ColumnContextMenuParams } from "./types";
import { css } from "@emotion/react";
import { useElementId } from "../../../components/ElementIdContext";
import { useTableColumnAlignment } from "../state";

const containerCss = (align: TableColumnAlignment) => css`
  width: 100%;
  height: 100%;
  text-align: ${align};
`;

const HeaderRenderer: FC<HeaderRendererProps<TableRow>> = ({ column }) => {
  const elementId = useElementId();
  const alignment = useTableColumnAlignment({
    elementId,
    columnKey: column.key,
  });
  const rowMenuId = useColumnMenuId();

  const collect = useCallback(
    () => ({ columnIndex: column.idx } as ColumnContextMenuParams),
    [column.idx]
  );

  return (
    // @ts-expect-error
    <ContextMenuTrigger id={rowMenuId} collect={collect}>
      <div css={containerCss(alignment)}>{column.name}</div>
    </ContextMenuTrigger>
  );
};

export default HeaderRenderer;
