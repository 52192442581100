import { PersistStorage } from "recoil-persist";
import { getConfig, setConfig } from "../ipc";

export const storage: PersistStorage = {
  getItem(key) {
    return getConfig(key);
  },

  setItem(key, value) {
    setConfig(key, value);
  },
};
