import { FC, useCallback } from "react";
import { RowRendererProps, Row as DataGridRow } from "react-data-grid";
import { ContextMenuTrigger } from "react-contextmenu";
import { useDrag, useDrop } from "react-dnd";
import { TableRow } from "../../../types";
import { useRowMenuId } from "./RowMenuIdContext";
import { RowContextMenuParams } from "./types";
import { css } from "@emotion/react";
import { Colors } from "../../../styles";

export type Props = RowRendererProps<TableRow> & {
  onReorderRow: (sourceIndex: number, targetIndex: number) => void;
};

const LONG_PRESS_DISABLED = -1;

const draggingCss = css`
  cursor: grabbing;
`;

const dropTargetCss = css`
  background-color: ${Colors.active50};
`;

const RowRenderer: FC<Props> = ({ onReorderRow, ...props }) => {
  const rowMenuId = useRowMenuId();

  const collectForContextMenu = useCallback(
    () => ({ rowIndex: props.rowIdx } as RowContextMenuParams),
    [props.rowIdx]
  );

  const [{ isDragging }, drag] = useDrag({
    type: "ROW_DRAG",
    item: { index: props.rowIdx },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });

  const [{ isOver }, drop] = useDrop({
    accept: "ROW_DRAG",
    drop({ index }: { index: number }) {
      onReorderRow(index, props.rowIdx);
    },
    collect: (monitor) => ({
      isOver: monitor.isOver(),
      canDrop: monitor.canDrop(),
    }),
  });

  return (
    // @ts-expect-error
    <ContextMenuTrigger
      id={rowMenuId}
      collect={collectForContextMenu}
      holdToDisplay={LONG_PRESS_DISABLED}
    >
      <DataGridRow
        ref={(ref) => {
          if (ref) {
            drag(ref.firstElementChild);
          }
          drop(ref);
        }}
        {...props}
        css={[isDragging && draggingCss, isOver && dropTargetCss]}
      />
    </ContextMenuTrigger>
  );
};

export default RowRenderer;
