import { sumBy } from "lodash";
import { BuiltIn } from "./types";

const builtIn: BuiltIn = {
  name: "sumBy",
  description:
    "Use the `sumBy` function to add the given property of all objects in an array",
  examples: ["sumBy([{ val: 1 }, { val: 2 }, { val: 3 }], 'val')"],
  apply: sumBy,
};

export default builtIn;
