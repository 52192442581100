import { isFunction, isNaN, isNumber, isString } from "lodash";
import { Fragment } from "react";
import { ResultDisplayComponent } from "../types";
import { italicCss } from "../styles";
import { useDigitsToDisplay } from "../../../state";

export const ObjectDisplay: ResultDisplayComponent<unknown> = ({ value }) => {
  const digitsToDisplay = useDigitsToDisplay();

  if (isNaN(value)) {
    return <Fragment>NaN</Fragment>;
  }

  if (isNumber(value)) {
    return <Fragment>{value.toFixed(digitsToDisplay)}</Fragment>;
  }

  if (isFunction(value)) {
    return <pre css={italicCss}>&lt;function&gt;</pre>;
  }

  if (isString(value)) {
    return <Fragment>{value}</Fragment>;
  }

  return <pre>{JSON.stringify(value, null, 2) ?? <em>no result</em>}</pre>;
};
