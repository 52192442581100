import { css } from "@emotion/react";
import { FC, useCallback, useMemo } from "react";
import DataGrid, { Column as DataGridColumn } from "react-data-grid";
import { Colors } from "../../../styles";
import { TableRow } from "../../../types";
import { ROW_HEIGHT } from "./constants";
import { useColumns } from "./hooks/useColumns";
import { useMaxHeight } from "./hooks/useMaxHeight";
import { useRows } from "./hooks/useRows";
import { dataGridCss } from "./styles";
import { TableColumnPreview } from "../../../state/types";
import { getDataGridColumn } from "./utils/getDataGridColumn";

interface Props {
  elementId: string;
  columnPreview?: TableColumnPreview;
  onRowClick: (row: TableRow, columnKey: string) => void;
}

const containerCss = css`
  margin-bottom: 20px;
  border: 1px solid ${Colors.neutral300};
  border-radius: 3px;
`;

const TablePreview: FC<Props> = ({ elementId, onRowClick, columnPreview }) => {
  const gridColumns = useColumns(elementId);
  const gridRows = useRows(elementId);

  const maxHeight = useMaxHeight(elementId);

  const gridColumnsWithPreview = useMemo(() => {
    if (columnPreview) {
      return gridColumns.flatMap((column, index) =>
        index === columnPreview.index
          ? [column, getDataGridColumn(columnPreview)]
          : column
      );
    }

    return gridColumns;
  }, [gridColumns, columnPreview]);

  const doOnRowClick = useCallback(
    (row: TableRow, { key }: DataGridColumn<TableRow>) => {
      onRowClick(row, key);
    },
    [onRowClick]
  );

  return (
    <div css={containerCss}>
      <DataGrid
        className="rdg-light fill-grid"
        css={dataGridCss(maxHeight)}
        columns={gridColumnsWithPreview}
        rows={gridRows}
        rowHeight={ROW_HEIGHT}
        onRowClick={doOnRowClick}
      />
    </div>
  );
};

export default TablePreview;
