import { cloneDeep } from "lodash";
import { v4 as uuid4 } from "uuid";
import { Document } from "../types";

const createDocumentCopy = (document: Document) => ({
  id: uuid4(),
  ...(document.title ? { title: `Copy of ${document.title}` } : {}),
  elements: document.elements.map((element) => ({
    ...cloneDeep(element),
    id: uuid4(),
  })),
});

export default createDocumentCopy;
