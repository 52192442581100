import Fuse from "fuse.js";

export const ElementKinds = [
  "text",
  "table",
  "code",
  "formula",
  "json",
  "csv",
] as const;

export type ElementKind = typeof ElementKinds[number];

export type Element = {
  id: string;
  kind: ElementKind;
  name?: string;
  isCollapsed?: boolean;
};

export type TextElement = Element & {
  kind: "text";
  content: string;
};

export type Primitive = boolean | number | string;

export type TableColumnType =
  | "string"
  | "number"
  | "boolean"
  | "function"
  | "formula";
export type TableColumnAlignment = "left" | "center" | "right";

type TableColumnBase = {
  key: string;
  name: string;
  align?: TableColumnAlignment;
  width?: number;
};
type DataTableColumn = TableColumnBase & {
  type?: Exclude<TableColumnType, "function" | "formula">;
};
type FunctionTableColumn = TableColumnBase & {
  type: "function";
  content: string;
};
type FormulaTableColumn = TableColumnBase & {
  type: "formula";
  content: string;
};

export type TableColumn =
  | DataTableColumn
  | FunctionTableColumn
  | FormulaTableColumn;

export type TableRow = Record<string, Primitive>;

export type ComputedTableRow = Record<string, Primitive | unknown>;

export type TableElement = Element & {
  kind: "table";
  columns: TableColumn[];
  data: TableRow[];
};

export type CodeElementAppearance = "code-and-result" | "result-only";

export type CodeElement = Element & {
  kind: "code";
  code: string;
  appearance?: CodeElementAppearance;
};

export type FormulaElement = Element & {
  kind: "formula";
  formula: string;
};

export type EvalResult = {
  result: unknown;
  isEmptyResult?: true;
};

export type JsonElement = Element & {
  kind: "json";
  data: string;
};

export type CsvElement = Element & {
  kind: "csv";
  data: string;
};

export type Document = {
  title?: string;
  id: string;
  elements: Element[];

  /** Optional labels for categorization/organization */
  labels?: string[];
};

export type ElementSuggestion = {
  id: string;
  num: number;
  name: string;
  slug: string;
};

export type BuiltInSuggestion = {
  id: string;
  name: string;
  slug: string;
};

export type Searchable = {
  id: string;
  name: string;
  content: string;
  documentId: string;
  documentTitle: string;
};

export type SearchResult = Fuse.FuseResult<Searchable>;

export type SearchResults = SearchResult[];
