import { v4 as uuid4 } from "uuid";
import { Document } from "../types";

export const prepareDocumentForImport = (document: Document): Document => ({
  ...document,
  id: uuid4(),
  elements: document.elements.map((element) => ({
    ...element,
    id: uuid4(),
  })),
});
