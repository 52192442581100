import { v4 as uuid4 } from "uuid";
import { Document } from "../types";
import { createBuiltInExamples } from "../example/builtInFunctions";
import dataFormats from "../example/dataFormats.json";
import tables from "../example/tables.json";
import markdownSupport from "../example/markdownSupport.json";
import customFunctions from "../example/customFunctions.json";
import crossElementReferences from "../example/crossElementReferences.json";
import invalidCrossElementReferences from "../example/invalidCrossElementReferences.json";
import requiringModules from "../example/requiringModules.json";
import upgrade from "../example/upgrade.json";
import about from "../example/about.json";
import booleanTruthTables from "../example/samples/booleanTruthTables.json";
import regexSandbox from "../example/samples/regexSandbox.json";
import shelvingProject from "../example/samples/shelvingProject.json";
import { prepareExampleDocument } from "../example/prepareExampleDocument";
import { EXAMPLE_LABELS } from "../example/constants";
import { isFeatureUpgradable } from "../feature-flags";

export const createExampleDocuments = () =>
  [
    {
      id: uuid4(),
      title: "Welcome!",
      labels: EXAMPLE_LABELS,
      elements: [
        {
          id: uuid4(),
          kind: "text",
          content: "Hello Calcula!",
        },
        {
          id: uuid4(),
          kind: "text",
          content:
            "Calcula is a mashup of a spreadsheet and a JavaScript REPL.\n\nIn fact, this entire site is built directly in Calcula!",
          name: "Second Text Element",
        },
        {
          id: uuid4(),
          kind: "code",
          code: "['Hello', 'Calcula!'].join(' ');",
          name: "Calculations can be done in JavaScript",
        },
        {
          id: uuid4(),
          kind: "text",
          content:
            "And data can be stored in tables (which are just arrays of JavaScript objects).",
        },
        {
          id: uuid4(),
          kind: "table",
          columns: [
            {
              key: "ID",
              name: "ID",
            },
            {
              key: "name",
              name: "name",
            },
            {
              key: "age",
              name: "age",
            },
          ],
          data: [
            {
              id: uuid4(),
              name: "John",
              age: "37",
              ID: "1",
            },
            {
              id: uuid4(),
              name: "Bill",
              age: "29",
              ID: "2",
            },
            {
              id: uuid4(),
              name: "Mary",
              age: "31",
              ID: "3",
            },
            {
              id: uuid4(),
              name: "Sue",
              age: "43",
              ID: "4",
            },
          ],
          name: "people",
        },
        {
          id: uuid4(),
          kind: "code",
          code: "$(5).map(person => person.name);",
          name: "Previous elements can be read by index",
        },
        {
          id: uuid4(),
          kind: "code",
          code: "$('people').map(person => person.age);",
          name: "Previous elements can also be read by the element's title",
        },
        {
          id: uuid4(),
          kind: "code",
          code: "// Only negative relative indices work, because a\n// positive index would imply time-traveling (to a later value/calculation)\n\n$(-1);",
          name: "Previous elements can be read by relative index too",
        },
        {
          id: uuid4(),
          kind: "code",
          code: "$(2);",
          name: "Even text elements can be read by later code elements",
        },
        {
          id: uuid4(),
          kind: "code",
          code: "$(-1).toLowerCase();",
          name: "Code element rseults can also read previous code elements",
        },
        {
          id: uuid4(),
          kind: "text",
          content: "**Markdown** is _supported_ and so are emoji 🎉",
          name: "Text elements even support markdown",
        },
      ],
    },
    prepareExampleDocument(dataFormats as Document),
    prepareExampleDocument(markdownSupport as Document),
    prepareExampleDocument(tables as Document),
    prepareExampleDocument(crossElementReferences as Document),
    createBuiltInExamples(),
    prepareExampleDocument(customFunctions as Document),
    prepareExampleDocument(invalidCrossElementReferences as Document),
    {
      id: uuid4(),
      title: "Error Handling",
      labels: EXAMPLE_LABELS,
      elements: [
        {
          id: uuid4(),
          kind: "text",
          content: "Calcula has robust error handling",
        },
        {
          id: uuid4(),
          kind: "code",
          code: "const fn = () => {\n  throw new Error('boom!')\n}\n\nconst fn2 = () => {\n  fn();\n}\n\nfn2();",
          name: "JavaScript errors include a stack trace",
        },
        {
          id: uuid4(),
          kind: "code",
          code: 'const invalid /* = */ "JavaScript"',
          name: "JavaScript syntax errors are clearly shown",
        },
        {
          id: uuid4(),
          kind: "json",
          data: '["nope", "not", "going", "to", "parse"',
          name: "Invalid JSON data is detected",
        },
      ],
    },
    prepareExampleDocument(requiringModules as Document, {
      labels: isFeatureUpgradable("require")
        ? [...EXAMPLE_LABELS, "PRO"]
        : EXAMPLE_LABELS,
    }),
    prepareExampleDocument(upgrade as Document, { labels: ["PRO"] }),
    prepareExampleDocument(about as Document, { labels: [] }),

    // Examples
    prepareExampleDocument(booleanTruthTables as Document, {
      labels: ["Example"],
    }),
    prepareExampleDocument(regexSandbox as Document, { labels: ["Example"] }),
    prepareExampleDocument(shelvingProject as Document, {
      labels: ["Example"],
    }),
  ] as Document[];
