import { css } from "@emotion/react";
import { toNumber } from "lodash";
import type { EditorProps } from "react-data-grid";

const editorCss = css`
  appearance: none;

  box-sizing: border-box;
  inline-size: 100%;
  block-size: 100%;
  padding-block: 0;
  padding-inline: 6px;
  vertical-align: top;
  color: var(--rdg-color);
  background-color: var(--rdg-background-color);

  font-family: inherit;
  font-size: var(--rdg-font-size);

  text-align: right;

  /* Hide the spinner controls */
  -moz-appearance: textfield;
  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  &:focus {
    border-color: var(--rdg-selection-color);
    outline: none;
    box-shadow: none !important;
  }

  &::placeholder {
    color: #999;
    opacity: 1;
  }
`;

function autoFocusAndSelect(input: HTMLInputElement | null) {
  input?.focus();
  input?.select();
}

export default function NumberEditor<TRow, TSummaryRow>({
  row,
  column,
  onRowChange,
  onClose,
}: EditorProps<TRow, TSummaryRow>) {
  return (
    <input
      type="number"
      className="rdg-text-editor"
      css={editorCss}
      ref={autoFocusAndSelect}
      value={row[column.key as keyof TRow] as unknown as number}
      onChange={(event) =>
        onRowChange({ ...row, [column.key]: toNumber(event.target.value) })
      }
      onBlur={() => onClose(true)}
    />
  );
}
