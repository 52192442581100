import { FC } from "react";
import usePromise from "react-promise";
import { errorCss } from "../../../styles";
import EvalResultDisplay from "../EvalResultDisplay";
import { RecalculateHotKeyHint } from "../RecalculateHotKeyHint";
import { resultCss } from "../styles";
import { PromiseLoading } from "./PromiseLoading";

interface Props {
  elementId: string;
  result: Promise<unknown>;
  hasTopBorder?: boolean;
  isFocused?: boolean;
}

export const PromiseDisplay: FC<Props> = ({
  elementId,
  result,
  hasTopBorder,
  isFocused,
}) => {
  const { value, loading, error } = usePromise(result);
  const containerCss = resultCss(hasTopBorder);

  if (loading) {
    return (
      <div css={containerCss}>
        <PromiseLoading />
      </div>
    );
  }

  if (error) {
    return (
      <pre css={[containerCss, errorCss]}>
        {isFocused && <RecalculateHotKeyHint />}
        Error: {`${error.message}`}
      </pre>
    );
  }

  return (
    <EvalResultDisplay
      isFocused={isFocused}
      hasTopBorder={hasTopBorder}
      elementId={elementId}
      result={{ result: value }}
    />
  );
};
