import { css } from "@emotion/react";
import type { FormatterProps } from "react-data-grid";

const layoutCss = css`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
`;

const editorCss = css`
  box-sizing: border-box;
  padding-block: 0;
  padding-inline: 6px;
  border: 2px solid #ccc;
  vertical-align: top;
  color: var(--rdg-color);
  background-color: var(--rdg-background-color);

  font-family: inherit;
  font-size: var(--rdg-font-size);

  &:focus {
    border-color: var(--rdg-selection-color);
    outline: none;
  }

  &::placeholder {
    color: #999;
    opacity: 1;
  }
`;

export default function BooleanFormatter<TRow, TSummaryRow>({
  row,
  column,
  onRowChange,
}: FormatterProps<TRow, TSummaryRow>) {
  return (
    <div css={layoutCss}>
      <input
        type="checkbox"
        className="rdg-text-editor"
        css={editorCss}
        checked={(row[column.key as keyof TRow] as unknown as boolean) ?? false}
        onChange={(event) =>
          onRowChange({ ...row, [column.key]: event.target.checked })
        }
      />
    </div>
  );
}
