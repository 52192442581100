import { css } from "@emotion/react";
import { FC, useCallback, useState } from "react";
import { createEditor, Text, NodeEntry, Range } from "slate";
import { withReact, Slate, Editable, RenderLeafProps } from "slate-react";
import Prism from "prismjs";
import { Colors, Typography } from "../../../styles";
import { deserialize, serialize } from "../../../utils/slate/utils";
import { getLength } from "../../../utils/slate/getLength";
import "./configurePrismMarkdownSupport";
import { MAX_ELEMENT_HEIGHT } from "../../../components/constants";

interface Props {
  initialValue: string;
  onChange: (value: string) => void;
  onBlur: () => void;
  placeholder: string;
}

const textareaCss = css`
  border: none;
  resize: vertical;
  width: 100%;
  border-radius: 2px;
  background-color: ${Colors.white};
  margin: 0;
  box-sizing: border-box;
  padding: 5px;
  max-height: ${MAX_ELEMENT_HEIGHT};
  overflow: auto;
  font-size: ${Typography.baseFontSize};
`;

type LeafProps = RenderLeafProps & {
  leaf: {
    bold?: boolean;
    italic?: boolean;
    underlined?: boolean;
    list?: boolean;
    blockquote?: boolean;
    hr?: boolean;
    code?: boolean;
    title?: boolean;
    "heading-1"?: boolean;
    "heading-2"?: boolean;
    "heading-3"?: boolean;
    "heading-4"?: boolean;
    "heading-5"?: boolean;
    "heading-6"?: boolean;
  };
};

const leafCss = (leaf: LeafProps["leaf"]) => [
  css`
    line-height: 140%;
    font-weight: ${leaf.bold && "bold"};
    font-style: ${leaf.italic && "italic"};
    text-decoration: ${leaf.underlined && "underline"};
  `,

  leaf.title &&
    css`
      display: inline-block;
      font-weight: bold;
      font-size: 20px;
      margin: 10px 0 5px 0;
    `,

  leaf["heading-1"] &&
    css`
      font-weight: bold;
      font-size: 22px;
    `,

  leaf["heading-2"] &&
    css`
      font-weight: bold;
      font-size: 18px;
    `,

  leaf["heading-3"] &&
    css`
      font-weight: bold;
      font-size: 16px;
    `,

  leaf["heading-4"] &&
    css`
      font-weight: bold;
      font-size: 14px;
    `,

  leaf["heading-5"] &&
    css`
      font-weight: bold;
      font-size: 12px;
    `,

  leaf["heading-6"] &&
    css`
      font-weight: bold;
      font-size: 11px;
    `,

  leaf.list &&
    css`
      padding-left: 10px;
      font-size: ${Typography.baseFontSize};
    `,

  leaf.hr &&
    css`
      display: block;
      text-align: center;
      border-bottom: 1px solid ${Colors.neutral300};
    `,

  leaf.blockquote &&
    css`
      display: inline-block;
      border-left: 2px solid #ddd;
      padding-left: 10px;
      color: #aaa;
      font-style: italic;
    `,

  leaf.code &&
    css`
      font-family: monospace;
      background-color: ${Colors.neutral200};
      padding: 1px;
      border-radius: 2px;
    `,
];
const Leaf: FC<LeafProps> = ({ attributes, children, leaf }) => {
  return (
    <span {...attributes} css={leafCss(leaf)}>
      {children}
    </span>
  );
};

const MarkdownEditor: FC<Props> = ({
  initialValue,
  onChange,
  onBlur,
  placeholder,
}) => {
  const [editor] = useState(() => withReact(createEditor()));

  const [_initialValue] = useState(() => deserialize(initialValue));

  const renderLeaf = useCallback(
    (props: RenderLeafProps) => <Leaf {...props} />,
    []
  );

  const decorate = useCallback(([node, path]: NodeEntry) => {
    const ranges: Range[] = [];

    if (!Text.isText(node)) {
      return ranges;
    }

    const tokens = Prism.tokenize(node.text, Prism.languages.markdown);
    let start = 0;

    for (const token of tokens) {
      const length = getLength(token);
      const end = start + length;

      if (typeof token !== "string") {
        ranges.push({
          [token.type]: true,
          anchor: { path, offset: start },
          focus: { path, offset: end },
        });
      }

      start = end;
    }

    return ranges;
  }, []);

  return (
    <Slate
      editor={editor}
      value={_initialValue}
      onChange={(value) => {
        const isAstChange = editor.operations.some(
          (op) => "set_selection" !== op.type
        );
        if (isAstChange) {
          onChange(serialize(value));
        }
      }}
    >
      <Editable
        css={textareaCss}
        placeholder={placeholder}
        decorate={decorate}
        renderLeaf={renderLeaf}
        onBlur={onBlur}
      />
    </Slate>
  );
};

export default MarkdownEditor;
