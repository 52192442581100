import { FC } from "react";
import { TableColumn } from "../../../types";
import {
  modalBodyCss,
  modalCss,
  ModalHeader,
  modalPositionerCss,
  modalSizeCss,
} from "../../../components/Modal";
import ColumnConfigurationForm from "./ColumnConfigurationForm";
import { OnCommitColumnChange } from "./types";

interface Props {
  elementId: string;
  initialValue: TableColumn;
  onCancel: () => void;
  onCommit: OnCommitColumnChange;
  prohibitedColumnNames: string[];
  columnIndex: number;
}

const EditColumnModal: FC<Props> = ({
  elementId,
  onCommit,
  onCancel,
  initialValue,
  prohibitedColumnNames,
  columnIndex,
}) => (
  <div css={modalPositionerCss}>
    <div css={[modalCss, modalSizeCss({ width: "800px" })]}>
      <ModalHeader
        title={`Update column ${columnIndex + 1}`}
        onClose={onCancel}
      />

      <div css={modalBodyCss}>
        <ColumnConfigurationForm
          elementId={elementId}
          initialValue={initialValue}
          operation="update"
          commitLabel="Update column"
          prohibitedColumnNames={prohibitedColumnNames}
          columnIndex={columnIndex}
          onCommit={onCommit}
          onCancel={onCancel}
        />
      </div>
    </div>
  </div>
);

export default EditColumnModal;
