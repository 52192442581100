export default class RequireIsUnsupportedError extends Error {
  moduleName: string;

  constructor(moduleName: string) {
    super(
      `Cannot require "${moduleName}". Importing modules with require is a PRO feature.`
    );
    this.moduleName = moduleName;
  }
}

export const isRequireIsUnsupportedError = (
  e: unknown
): e is RequireIsUnsupportedError => e instanceof RequireIsUnsupportedError;
