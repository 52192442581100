import { css } from "@emotion/react";
import { FC } from "react";
import { RenderLeafProps } from "slate-react";
import { Typography } from "../../styles";
import colors from "../../styles/colors";

type LeafProps = RenderLeafProps & {
  leaf: {
    comment?: boolean;
    operator?: boolean;
    url?: boolean;
    keyword?: boolean;
    variable?: boolean;
    regex?: boolean;
    number?: boolean;
    boolean?: boolean;
    tag?: boolean;
    constant?: boolean;
    symbol?: boolean;
    selector?: boolean;
    punctuation?: boolean;
    "attr-name"?: boolean;
    "class-name"?: boolean;
    string?: boolean;
    char?: boolean;
    function?: boolean;
  };
};

const leafCss = (leaf: LeafProps["leaf"]) => [
  css`
    font-family: ${Typography.fontFamily};
    background: hsla(0, 0%, 100%, 0.5);
    ${(leaf.operator || leaf.url) &&
    css`
      color: ${colors.rose[800]};
    `}
    ${leaf.keyword &&
    css`
      color: ${colors.sky[700]};
    `}
${(leaf.variable || leaf.regex) &&
    css`
      color: #e90;
    `}
${(leaf.number ||
      leaf.boolean ||
      leaf.tag ||
      leaf.constant ||
      leaf.symbol ||
      leaf["attr-name"] ||
      leaf.selector) &&
    css`
      color: ${colors.indigo[700]};
    `}
${leaf.punctuation &&
    css`
      color: ${colors.neutral[500]};
    `}
${(leaf.string || leaf.char) &&
    css`
      color: ${colors.cyan[600]};
    `}
${(leaf.function || leaf["class-name"]) &&
    css`
      color: ${colors.blue[800]};
    `}
  `,
  leaf.comment &&
    css`
      color: ${colors.stone[400]} !important;
    `,
];

// different token types, styles found on Prismjs website
export const CodeLeaf: FC<LeafProps> = ({ attributes, children, leaf }) => {
  return (
    <span {...attributes} css={leafCss(leaf)}>
      {children}
    </span>
  );
};
