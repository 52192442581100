import { css } from "@emotion/react";
import { FC, PropsWithChildren, useCallback } from "react";
import RequireIsUnsupportedError from "../../../errors/RequireIsUnsupportedError";
import { usePromptToUpgrade } from "../../../state";
import { baseButtonCss, flexboxCss, secondaryButtonCss } from "../../../styles";
import { Tag } from "../../Tag";

interface Props extends PropsWithChildren {
  error: RequireIsUnsupportedError;
}

const upgradeButtonCss = css`
  ${baseButtonCss}
  ${secondaryButtonCss}
  padding: 5px 10px;
`;

export const RequireIsUnsupportedDisplay: FC<Props> = ({ error }) => {
  const promptToUpgrade = usePromptToUpgrade();

  const onLearnMore = useCallback(() => {
    promptToUpgrade("upgrade:require");
  }, [promptToUpgrade]);

  return (
    <div css={[flexboxCss({ align: "center", justify: "space-between" })]}>
      <div>
        Importing modules with <code>require</code> is a{" "}
        <Tag color="upgrade">PRO</Tag> feature.
      </div>
      <div>
        <button css={upgradeButtonCss} onClick={onLearnMore}>
          Learn more
        </button>
      </div>
    </div>
  );
};
