import { useEffect } from "react";
import { trackPageAction, trackPageView } from "./airlytics";
import { useTrackingId } from "./state";

export default function useAirlytics() {
  const trackingId = useTrackingId();

  // Track the current page
  useEffect(() => {
    const { pathname } = window.location;
    trackPageView(trackingId, pathname);
  }, [trackingId]);

  // Track session visibility changes and session end
  useEffect(() => {
    const onVisibilityChange = () => {
      const { pathname } = window.location;
      const { visibilityState } = document;

      const action = `session:${visibilityState}`;

      trackPageAction(trackingId, pathname, action, { useBeacon: true });
    };

    const onBeforeUnload = () => {
      window.removeEventListener("visibilitychange", onVisibilityChange);

      const { pathname } = window.location;
      trackPageAction(trackingId, pathname, "session:end", { useBeacon: true });
    };

    window.addEventListener("beforeunload", onBeforeUnload);
    window.addEventListener("visibilitychange", onVisibilityChange);

    return () => {
      window.removeEventListener("beforeunload", onBeforeUnload);
      window.removeEventListener("visibilitychange", onVisibilityChange);
    };
  }, [trackingId]);
}
