import { TableColumn } from "../../../../types";
import BooleanFormatter from "./BooleanFormatter";
import FormulaFormatter from "./FormulaFormatter";
import FunctionFormatter from "./FunctionFormatter";
import NumberFormatter from "./NumberFormatter";

const getFormatterForColumn = (column: TableColumn) => {
  switch (column.type) {
    case "number":
      return NumberFormatter;
    case "boolean":
      return BooleanFormatter;
    case "function":
      return FunctionFormatter;
    case "formula":
      return FormulaFormatter;

    default:
      return undefined;
  }
};

export default getFormatterForColumn;
