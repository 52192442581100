import { css } from "@emotion/react";
import { FC, useEffect } from "react";
import { useShowToast, useToasts } from "../state";
import { ToastDisplay } from "./ToastDisplay";

const toastsContainerCss = css`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  height: 0;
  overflow: visible;
  z-index: 10000001;
`;

const toastsLayoutCss = css`
  margin: 20px auto 0;
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: 500px;
`;

export const Toasts: FC = () => {
  const toasts = useToasts();

  const showToast = useShowToast();
  useEffect(() => {
    window.showToast = showToast;
  }, [showToast]);

  return (
    <div css={toastsContainerCss}>
      <div css={toastsLayoutCss}>
        {toasts.map((toast, index) => (
          <ToastDisplay key={index} index={index} toast={toast} />
        ))}
      </div>
    </div>
  );
};
