import sum from "./sum";
import sumBy from "./sumBy";
import product from "./product";
import productBy from "./productBy";
import camelCase from "./camelCase";
import toNumber from "./toNumber";
import lodash from "./lodash";
import { BuiltIns } from "./types";

export type { BuiltIn } from "./types";

const builtIns: BuiltIns = [
  sum,
  sumBy,
  product,
  productBy,
  toNumber,
  camelCase,
  lodash,
];

export default builtIns;
