import { css } from "@emotion/react";
import { isEmpty } from "lodash";
import { FC } from "react";
import { Colors } from "../styles";
import { Tag, tagCss } from "./Tag";

interface Props {
  labels: string[];
  isSelected?: boolean;
}

const containerCss = css`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: 5px;
  margin-left: auto;
  justify-content: flex-end;
`;

const labelCss = (isSelected?: boolean) => css`
  ${tagCss}
  background-color: ${isSelected ? Colors.neutral300 : Colors.neutral200};
  color: ${Colors.neutral500};
`;

const DocumentLabels: FC<Props> = ({ labels, isSelected }) => (
  <div css={containerCss}>
    {labels
      .filter((label) => !isEmpty(label))
      .map((label, index) =>
        label === "PRO" ? (
          <Tag color="upgrade" key={index}>
            PRO
          </Tag>
        ) : (
          <div css={labelCss(isSelected)} key={index}>
            {label}
          </div>
        )
      )}
  </div>
);

export default DocumentLabels;
