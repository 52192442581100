import { css } from "@emotion/react";
import { FC } from "react";
import { Colors } from "../../../styles";
import { useAwaitCount } from "../../AwaitContext";

const contentCss = css`
  color: ${Colors.neutral500};
`;

const CONTENT = [<span>&nbsp;</span>, ".", "..", "..."];

export const PromiseLoading: FC = () => {
  const count = useAwaitCount();

  return <div css={contentCss}>Awaiting {CONTENT[count % CONTENT.length]}</div>;
};
