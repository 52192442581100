export const defineProp = (() => {
  try {
    Object.defineProperty({}, "_", {});
    return (obj: any, name: string, value: unknown) => {
      Object.defineProperty(obj, name, {
        writable: true,
        enumerable: false,
        configurable: true,
        value: value,
      });
    };
  } catch (e) {
    return (obj: any, name: string, value: unknown) => {
      obj[name] = value;
    };
  }
})();
