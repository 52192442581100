import { css } from "@emotion/react";
import { mdiFileDocumentOutline } from "@mdi/js";
import Icon from "@mdi/react";
import { truncate } from "lodash";
import { FC, useCallback } from "react";
import { Colors, flexboxCss, transition } from "../../styles";
import { SearchResult } from "../../types";
import Text from "../Text";

interface Props {
  result: SearchResult;
  onOpen: (result: SearchResult) => void;
}

const resultCss = css`
  display: flex;
  flex-direction: column;
  gap: 3px;
  cursor: pointer;
  padding: 10px;
  box-sizing: border-box;
  border-radius: 3px;
  background-color: ${Colors.transparent};

  transition: ${transition("background-color")};

  &:hover {
    background-color: ${Colors.neutral200};
  }

  &:focus {
    background-color: ${Colors.neutral200};
    outline: none;
    font-weight: bold;
  }
`;

const DocumentSearchResult: FC<Props> = ({ result, onOpen }) => {
  const onClick = useCallback(() => {
    onOpen(result);
  }, [onOpen, result]);
  return (
    <div
      key={result.item.id}
      css={resultCss}
      role="menuitem"
      onClick={onClick}
      tabIndex={0}
    >
      <Text type="normal">{result.item.name}</Text>

      <div css={flexboxCss({ align: "center", gap: "5px" })}>
        <Icon
          path={mdiFileDocumentOutline}
          size={0.5}
          color={Colors.neutral400}
        />
        <Text type="fine" color={Colors.neutral500}>
          {truncate(result.item.documentTitle, { length: 50 })}
        </Text>
      </div>

      <Text type="small" color={Colors.neutral700}>
        {truncate(result.item.content, { length: 50 })}
      </Text>
    </div>
  );
};

export default DocumentSearchResult;
