import { useEffect, useState } from "react";
import { selector } from "recoil";
import { eventLoop } from "../utils/eventLoop";
import { getParamValue } from "../utils/url/getParamValue";
import { activeDocumentId } from "./core";
import { useImportFromShare } from "./sharing";

type GoToDefaultRoute = () => Promise<void>;

export const goToDefaultRouteCallback = selector<GoToDefaultRoute>({
  key: "goToDefaultRoute",
  get: ({ getCallback }) =>
    getCallback(({ reset }) => async () => {
      window.history.pushState({}, "", "/");

      await eventLoop();

      reset(activeDocumentId);
    }),
});

export const useImportFromIntent = () => {
  const importFromShare = useImportFromShare();

  const [isMounted, setMounted] = useState(false);

  useEffect(() => {
    if (!isMounted) {
      setMounted(true);

      const secret = getParamValue("import-from");

      if (secret) {
        importFromShare(secret);
      }
    }
  }, [isMounted, importFromShare]);
};

export const useImportFromIntentURL = (secret: string) => {
  const { location } = window;
  return `${location.protocol}//${location.host}/?import-from=${secret}`;
};
