import { css } from "@emotion/react";
import { FC, PropsWithChildren } from "react";
import {
  TypographicStyleNames,
  TypographicStyles,
  Typography,
} from "../styles";

type Props = PropsWithChildren<{
  tag?: keyof JSX.IntrinsicElements;
  type?: TypographicStyleNames;
  className?: string;
  color?: string;
  margin?: string;
  monospace?: boolean;
  align?: "left" | "center" | "right" | "justify";
  size?: string;
  textTransform?: "uppercase";
}>;

const colorCss = (color: string | undefined) =>
  color
    ? css`
        color: ${color};
      `
    : css`
        color: inherit;
      `;

const monospaceCss = css`
  font-family: ${Typography.fontFamily};
`;

const marginCss = (margin: string) => css`
  margin: ${margin};
`;

const alignCss = (align: string) => css`
  text-align: ${align};
`;

const sizeCss = (size: string) => css`
  font-size: ${size};
`;

const textTransformCss = (textTransform: "uppercase") => css`
  text-transform: ${textTransform};
`;

const Text: FC<Props> = ({
  tag = "div",
  type = "normal",
  monospace = false,
  size,
  align,
  margin,
  color,
  className,
  textTransform,
  children,
  ...props
}) => {
  const Tag = tag as keyof JSX.IntrinsicElements;
  return (
    <Tag
      {...props}
      css={[
        type && TypographicStyles[type],
        colorCss(color),
        monospace && monospaceCss,
        margin && marginCss(margin),
        align && alignCss(align),
        size && sizeCss(size),
        textTransform && textTransformCss(textTransform),
      ]}
      className={className}
    >
      {children}
    </Tag>
  );
};

export default Text;
