import { atom, useRecoilCallback, useRecoilState } from "recoil";

type PromptOptionsBase = {
  message: string;
  okLabel?: string;
  placeholder?: string;
};

export type StringPromptOptions = PromptOptionsBase & {
  initialValue?: string;
  type?: "string";
};

export type StringListPromptOptions = PromptOptionsBase & {
  type: "list";
  initialValue?: string[];
};

export type SelectPromptItem = {
  label: string;
  value: string;
};
export type SelectPromptOptions = PromptOptionsBase & {
  type: "select";
  items: SelectPromptItem[];
  initialValue?: string[];
};

export type NumberPromptOptions = PromptOptionsBase & {
  initialValue?: number;
  type: "number";
};

export type PromptOptions =
  | StringPromptOptions
  | StringListPromptOptions
  | SelectPromptOptions
  | NumberPromptOptions;

type Resolved<T = string> = {
  status: "ok";
  value: T;
};

type Canceled = {
  status: "canceled";
};

type PromptResult<T> = Resolved<T> | Canceled;

type Prompt<T> = PromptOptions & {
  resolver: (result: PromptResult<T>) => void;
};

export const CANCEL: Canceled = {
  status: "canceled",
};

const promptState = atom<Prompt<any> | null>({
  key: "prompt",
  default: null,
});

export const usePromptState = () => useRecoilState(promptState);

type HookResult<T> = (options: PromptOptions) => Promise<PromptResult<T>>;

export const usePrompt = <T = string>(): HookResult<T> =>
  useRecoilCallback(
    ({ set }) =>
      (options: PromptOptions) =>
        new Promise<PromptResult<T>>((resolve) => {
          set(promptState, { ...options, resolver: resolve });
        }),
    []
  ) as HookResult<T>;
