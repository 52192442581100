import { assign, findIndex, map } from "lodash";
import {
  atom,
  DefaultValue,
  selector,
  selectorFamily,
  useRecoilCallback,
  useRecoilValue,
} from "recoil";

import { Element, Document, ElementKind } from "../types";
import { createEmptyDocument } from "../utils/createEmptyDocument";
import { createExampleDocuments } from "../utils/createExampleDocuments";
import { getDocumentTitle } from "../utils/getDocumentTitle";
import createElementCopy from "../utils/createElementCopy";
import createDocumentCopy from "../utils/createDocumentCopy";
import { DEFAULT_ELEMENT_NAME } from "./constants";
import { getParamValue } from "../utils/url/getParamValue";
import { persistAtom } from "./storage";
import { useTrackAction } from "../observability";
import { trackActionCallback } from "../observability/trackActionCallback";
import { usePrompt } from "./prompt";

export const documentsState = atom<Document[]>({
  key: "documents",
  default: createExampleDocuments(),
  effects: [persistAtom],
});

export const documentByIdState = selectorFamily<Document, string>({
  key: "documentById",
  get:
    (documentId: string) =>
    ({ get }) => {
      const documents = get(documentsState);

      const document = documents.find(({ id }) => id === documentId);

      if (!document) {
        throw new Error(`Unable to find document by ID ${documentId}`);
      }

      return document;
    },
  set:
    (documentId: string) =>
    ({ set }, update) => {
      if (update instanceof DefaultValue) {
        return;
      }

      set(documentsState, (documents) =>
        documents.map((document) =>
          document.id === documentId ? update : document
        )
      );
    },
});

export const useDocuments = () => useRecoilValue(documentsState);

export const useAddDocument = () => {
  const prompt = usePrompt();

  return useRecoilCallback(
    ({ set, snapshot }) =>
      async () => {
        const result = await prompt({
          message: "What is the new title?",
          placeholder: "New page title",
          okLabel: "Create page",
          initialValue: "Untitled",
        });

        if (result.status === "ok") {
          const newDocument = { ...createEmptyDocument(), title: result.value };
          set(documentsState, (s) => [...s, newDocument]);

          const trackAction = await snapshot.getPromise(trackActionCallback);

          trackAction("page:create");
        }
      },
    []
  );
};

export const useRenameDocument = (documentId: string) =>
  useRecoilCallback(
    ({ set }) =>
      (title: string) => {
        set(documentsState, (documents) =>
          documents.map((document) =>
            document.id === documentId ? { ...document, title } : document
          )
        );
      },
    []
  );

export const useDuplicateDocument = (documentId: string) =>
  useRecoilCallback(
    ({ set }) =>
      () => {
        set(documentsState, (documents) =>
          documents.flatMap((document) =>
            document.id === documentId
              ? [document, createDocumentCopy(document)]
              : document
          )
        );
      },
    [documentId]
  );

export const useDeleteDocument = () => {
  const trackAction = useTrackAction();

  return useRecoilCallback(
    ({ set, snapshot }) =>
      async (documentId: string) => {
        trackAction("page:delete");

        const activeId = await snapshot.getPromise(activeDocumentId);

        // If the document to delete is the active document...
        if (activeId === documentId) {
          // Then switch to the first non-deleted document
          const documents = await snapshot.getPromise(documentsState);
          const firstDocumentId = documents.find(
            (document) => document.id !== documentId
          );
          set(activeDocumentId, firstDocumentId?.id ?? "");
        }

        set(documentsState, (documents) => {
          // EXPLANATION: only delete the first document with
          // the given ID just in case a bug resulted in dupe
          // document IDs
          const indexToDelete = documents.findIndex(
            (document) => document.id === documentId
          );
          return documents.filter((_document, idx) => indexToDelete !== idx);
        });
      },
    [trackAction]
  );
};

export const useReorderDocuments = () =>
  useRecoilCallback(
    ({ set }) =>
      (fromId: string, toId: string) => {
        if (fromId === toId) {
          return;
        }

        set(documentsState, (documents) => {
          const documentToMove = documents.find((doc) => doc.id === fromId);
          if (documentToMove) {
            return documents.flatMap((document) => {
              if (document.id === toId) {
                return [document, documentToMove];
              }

              if (document.id === fromId) {
                return [];
              }

              return [document];
            });
          }
          return documents;
        });
      },
    []
  );

export const activeDocumentId = atom<string>({
  key: "doc",
  default: selector({
    key: "defaultActiveDocumentId",
    get: ({ get }) => get(documentsState)[0].id,
  }),
  effects: [
    ({ setSelf, onSet, getLoadable }) => {
      onSet((documentId) => {
        window.history.pushState({}, "", `/?page=${documentId}`);
      });

      try {
        const docId = getParamValue("page");

        if (docId) {
          const documentsLoadable = getLoadable(documentsState);

          if (documentsLoadable.state === "hasValue") {
            if (documentsLoadable.contents.find((doc) => doc.id === docId)) {
              setSelf(docId);
            } else {
              const firstDocumentId = documentsLoadable.contents[0].id;
              setSelf(firstDocumentId);
              window.history.pushState({}, "", `/?page=${firstDocumentId}`);
            }
          }
        }
      } catch (e: unknown) {
        console.error(
          `Failed to initialize activeDocumentId`,
          window.location.href
        );
      }
    },
  ],
});

export const useActiveDocumentId = () => useRecoilValue(activeDocumentId);

export const isDocumentSelectedState = selectorFamily<boolean, string>({
  key: "isDocumentSelected",
  get:
    (documentId) =>
    ({ get }) =>
      get(activeDocumentId) === documentId,
});

export const useIsDocumentSelected = (documentId: string) =>
  useRecoilValue(isDocumentSelectedState(documentId));

export const useSelectDocument = () => {
  const trackAction = useTrackAction();

  return useRecoilCallback(
    ({ set }) =>
      (documentId: string) => {
        trackAction("page:view");
        set(activeDocumentId, documentId);
      },
    [trackAction]
  );
};

export const documentTitleState = selector<string>({
  key: "document/title",
  get: ({ get }) => {
    const documentId = get(activeDocumentId);
    return get(documentTitleByIdState(documentId));
  },
});

export const useActiveDocumentTitle = () => useRecoilValue(documentTitleState);

export const documentTitleByIdState = selectorFamily<string, string>({
  key: "document/titleById",
  get:
    (documentId) =>
    ({ get }) => {
      const document = get(documentsState).find(
        (document) => document.id === documentId
      );
      return getDocumentTitle(document);
    },
});

export const useDocumentTitle = (documentId: string) =>
  useRecoilValue(documentTitleByIdState(documentId));

export const documentState = selector<Document>({
  key: "document",

  get: ({ get }) => {
    const documentId = get(activeDocumentId);
    return get(documentsState).find((doc) => doc.id === documentId)!;
  },

  set: ({ get, set }, update) => {
    if (update instanceof DefaultValue) {
      return;
    }

    const documentId = get(activeDocumentId);

    set(documentsState, (documents) =>
      documents.map((document) =>
        document.id === documentId ? update : document
      )
    );
  },
});

export const elementsState = selector<Element[]>({
  key: "document/elements",
  get: ({ get }) => {
    const activeDocument = get(documentState);

    if (!activeDocument) {
      const allDocuments = get(documentsState);
      return allDocuments[0].elements;
    }

    return activeDocument.elements;
  },
  set: ({ set }, update) => {
    if (update instanceof DefaultValue) {
      return;
    }

    set(documentState, (document) => ({ ...document, elements: update }));
  },
});

export const useElements = () => useRecoilValue(elementsState);

export const elementIdsState = selector<string[]>({
  key: "document/elementIds",

  get: ({ get }) => map(get(documentState).elements, "id"),
});

export const elementByIdState = selectorFamily<Element, string>({
  key: "document/elementById",
  get:
    (elementId) =>
    ({ get }) => {
      const { elements } = get(documentState);

      const result = elements.find((element) => element.id === elementId);

      if (!result) {
        throw new Error(`Unable to find code element "${elementId}"`);
      }

      return result;
    },

  set:
    (elementId) =>
    ({ set }, update) => {
      if (update instanceof DefaultValue) {
        return;
      }

      set(elementsState, (elements) =>
        elements.map((element) =>
          element.id === elementId ? assign({}, element, update) : element
        )
      );
    },
});

export const useElement = (elementId: string) =>
  useRecoilValue(elementByIdState(elementId));

const elementKindByIdState = selectorFamily<ElementKind, string>({
  key: "document/elementKindById",
  get:
    (elementId) =>
    ({ get }) =>
      get(elementByIdState(elementId)).kind,
});

export const useElementKind = (elementId: string) =>
  useRecoilValue(elementKindByIdState(elementId));

export const elementIndexByIdState = selectorFamily<number, string>({
  key: "elementIndex",
  get:
    (elementId) =>
    ({ get }) => {
      const elements = get(elementsState);
      const index = findIndex(elements, (element) => element.id === elementId);

      if (index === -1) {
        throw new Error(`Unable to compute index for element ${elementId}`);
      }

      return index;
    },
});

export const useElementIndex = (elementId: string) =>
  useRecoilValue(elementIndexByIdState(elementId));

export const elementNumberByIdState = selectorFamily<number, string>({
  key: "elementIndex",
  get:
    (elementId) =>
    ({ get }) => {
      const index = get(elementIndexByIdState(elementId));

      return index + 1;
    },
});

export const useAddElement = (previousElementId: string | undefined) => {
  const trackAction = useTrackAction();

  return useRecoilCallback(
    ({ set }) =>
      (elementToAdd: Element) => {
        trackAction(`element:add:${elementToAdd.kind}`);

        set(elementsState, (elements) =>
          previousElementId
            ? elements.flatMap((element) =>
                element.id === previousElementId
                  ? [element, elementToAdd]
                  : element
              )
            : [elementToAdd, ...elements]
        );
      },
    [previousElementId, trackAction]
  );
};

export const isElementNamedState = selectorFamily<boolean, string>({
  key: "document/isElementNamed",
  get:
    (elementId) =>
    ({ get }) =>
      Boolean(get(elementByIdState(elementId)).name),
});

export const useIsElementNamed = (elementId: string) =>
  useRecoilValue(isElementNamedState(elementId));

export const elementNameByIdState = selectorFamily<string, string>({
  key: "document/elementNameById",
  get:
    (elementId) =>
    ({ get }) =>
      get(elementByIdState(elementId)).name ?? DEFAULT_ELEMENT_NAME,
});

export const useElementName = (elementId: string) =>
  useRecoilValue(elementNameByIdState(elementId));

export const useRenameElement = (elementId: string) =>
  useRecoilCallback(
    ({ set }) =>
      (name: string) => {
        set(elementsState, (elements) =>
          elements.map((element) =>
            element.id === elementId ? { ...element, name } : element
          )
        );
      },
    []
  );

export const useDeleteElement = () => {
  const trackAction = useTrackAction();

  return useRecoilCallback(
    ({ set, snapshot }) =>
      async (elementId: string) => {
        const elementToDelete = await snapshot.getPromise(
          elementByIdState(elementId)
        );
        trackAction(`element:delete:${elementToDelete.kind}`);

        set(elementsState, (elements) =>
          elements.filter((element) => element.id !== elementId)
        );
      },
    []
  );
};

const isElementCollapsedState = selectorFamily<boolean, string>({
  key: "document/isElementCollapsed",
  get:
    (elementId: string) =>
    ({ get }) =>
      Boolean(get(elementByIdState(elementId)).isCollapsed),
});

export const useIsElementCollapsed = (elementId: string) =>
  useRecoilValue(isElementCollapsedState(elementId));

export const useCollapseElement = (elementId: string) =>
  useRecoilCallback(
    ({ set }) =>
      () => {
        set(elementByIdState(elementId), (element) => ({
          ...element,
          isCollapsed: true,
        }));
      },
    [elementId]
  );

export const useExpandElement = (elementId: string) =>
  useRecoilCallback(
    ({ set }) =>
      () => {
        set(elementByIdState(elementId), (element) => ({
          ...element,
          isCollapsed: false,
        }));
      },
    [elementId]
  );

export const useDuplicateElement = (elementId: string) =>
  useRecoilCallback(
    ({ set }) =>
      () => {
        set(elementsState, (elements) =>
          elements.flatMap((element) =>
            element.id === elementId
              ? [element, createElementCopy(element)]
              : element
          )
        );
      },
    [elementId]
  );
