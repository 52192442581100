import { FC } from "react";
import { errorCss } from "../../styles";
import { PromiseLoading } from "./displays/PromiseLoading";
import { resultCss } from "./styles";

interface LoadingProps {
  hasTopBorder?: boolean;
}

export const EvalResultLoading: FC<LoadingProps> = ({
  hasTopBorder = true,
}) => (
  <div css={[resultCss(hasTopBorder), errorCss]}>
    <PromiseLoading />
  </div>
);
