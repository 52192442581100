import { css } from "@emotion/react";
import { useState } from "react";
import { useTrackAction } from "../../../observability";
import { useTextElementState } from "../state";
import { ElementComponent } from "../../../components/types";
import MarkdownEditor from "./MarkdownEditor";

const containerCss = css``;

const TextElement: ElementComponent = ({ elementId }) => {
  const [element, setElement] = useTextElementState(elementId);

  const [content, setContent] = useState(element.content);
  const [hasChanged, setHasChanged] = useState(false);

  const trackAction = useTrackAction();

  const onBlur = () => {
    if (hasChanged) {
      setElement((s) => ({ ...s, content }));
      trackAction("element:edit:text");
    }

    setHasChanged(false);
  };

  const onChange = (value: string) => {
    setContent(value);
    setHasChanged(true);
  };

  return (
    <div css={containerCss}>
      <MarkdownEditor
        placeholder="Enter text here..."
        initialValue={content}
        onChange={onChange}
        onBlur={onBlur}
      />
    </div>
  );
};

export default TextElement;
