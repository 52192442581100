import { css } from "@emotion/react";
import { FC, MouseEvent, Suspense, useCallback } from "react";
import Markdown from "markdown-to-jsx";
import {
  useDocumentation,
  useDocumentationTitle,
  useIsDocumentationAvailable,
  useIsDocumentationOpen,
  useOpenDocumentation,
} from "../../state";
import { useDocumentationTarget } from "../../state";
import { Colors } from "../../styles";
import { overrides } from "./overrides";
import Text from "../Text";
import { ElementKind, ElementKinds } from "../../types";

const containerCss = css`
  min-width: 300px;
  max-width: 30%;
  height: 100%;
  overflow-y: auto;
  overflow-x: hidden;
  box-sizing: border-box;
  padding: 20px;
  border-left: 2px solid ${Colors.neutral300};
`;

const markdownOptions = {
  overrides,
};

const titleLayoutCss = css``;

const DocTitle: FC = () => {
  const documentationTitle = useDocumentationTitle();

  if (documentationTitle) {
    return (
      <div css={titleLayoutCss}>
        <Text type="title">{documentationTitle}</Text>
      </div>
    );
  }

  return null;
};

interface DocTableOfContentsEntryProps {
  elementKind: ElementKind;
}

const tocEntryCss = css`
  display: flex;
  align-items: center;
  gap: 5px;
  padding: 10px;

  border: 1px solid ${Colors.neutral300};

  &:not(:last-of-type) {
    border-bottom: none;
  }

  &:first-of-type {
    border-top-left-radius: 3px;
    border-top-right-radius: 3px;
  }

  &:last-of-type {
    border-bottom-left-radius: 3px;
    border-bottom-right-radius: 3px;
  }
`;

const DocTableOfContentsEntry: FC<DocTableOfContentsEntryProps> = ({
  elementKind,
}) => {
  const isDocumentAvailable = useIsDocumentationAvailable(elementKind);
  const documentationTitle = useDocumentationTitle(elementKind);
  const openDocumentation = useOpenDocumentation();

  const onOpenDocumentation = useCallback(
    (event: MouseEvent<HTMLAnchorElement>) => {
      event.preventDefault();
      openDocumentation(elementKind);
    },
    [elementKind, openDocumentation]
  );

  if (!isDocumentAvailable) {
    return null;
  }

  return (
    <a css={tocEntryCss} href={`#${elementKind}`} onClick={onOpenDocumentation}>
      {documentationTitle}
    </a>
  );
};

const tocLayoutCss = css`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const DocTableOfContents: FC = () => {
  return (
    <div css={tocLayoutCss}>
      <Text type="title" margin="0 0 10px 0">
        Select documentation
      </Text>
      {ElementKinds.map((elementKind) => (
        <DocTableOfContentsEntry key={elementKind} elementKind={elementKind} />
      ))}
    </div>
  );
};

const DocRenderer: FC = () => {
  const isOpen = useIsDocumentationOpen();
  const source = useDocumentationTarget();

  const documentation = useDocumentation(source);

  if (!isOpen) {
    return null;
  }

  if (!source) {
    return (
      <div css={containerCss}>
        <DocTableOfContents />
      </div>
    );
  }

  return (
    <div css={containerCss}>
      <DocTitle />
      <Markdown options={markdownOptions}>{documentation}</Markdown>
    </div>
  );
};

const Loading: FC = () => <div css={containerCss}>loading...</div>;

export const DocView: FC = () => (
  <Suspense fallback={<Loading />}>
    <DocRenderer />
  </Suspense>
);
