import { css } from "@emotion/react";
import { FC } from "react";
import { ColumnEvalContextKey, useColumnEvalContext } from "../../../state";
import DataView from "../../../components/DataView";
import { formFieldCss } from "../../../components/Form";
import Text from "../../../components/Text";

interface Props {
  previewKey: ColumnEvalContextKey | undefined;
}

const containerCss = css`
  overflow: auto;
  width: 100%;
`;

const ColumnEvalContextPreview: FC<Props> = ({ previewKey }) => {
  const columnEvalContextPreview = useColumnEvalContext(previewKey);

  if (!previewKey) {
    return (
      <div css={formFieldCss}>
        <label>Function column properties</label>
        <Text>
          <em>
            Select a table cell to preview the properties available to the
            column.
          </em>
        </Text>
      </div>
    );
  }

  return (
    <div css={formFieldCss}>
      <label>Column properties</label>
      <div css={containerCss}>
        <DataView value={columnEvalContextPreview} />
      </div>
    </div>
  );
};

export default ColumnEvalContextPreview;
