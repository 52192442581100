import { ChangeEvent } from "react";
import { formFieldCss } from "../Form";
import { PromptEditor } from "./types";

const StringEditor: PromptEditor<string> = ({
  message,
  placeholder,
  value,
  setValue,
  focusRef,
}) => {
  const onChangeValue = (event: ChangeEvent<HTMLInputElement>) => {
    setValue(event.target.value);
  };

  return (
    <div css={formFieldCss}>
      <label htmlFor="prompt-value">{message}</label>
      <input
        ref={focusRef}
        name="prompt-value"
        type="text"
        autoComplete="off"
        placeholder={placeholder}
        value={value}
        onChange={onChangeValue}
      />
    </div>
  );
};

export default StringEditor;
