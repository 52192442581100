import { v4 as uuid4 } from "uuid";
import { first } from "lodash";
import builtIns, { BuiltIn } from "../built-ins";
import { CodeElement, Document } from "../types";
import { EXAMPLE_LABELS } from "./constants";

const getFirstExampleOrThrow = (builtIn: BuiltIn) => {
  const firstExample = first(builtIn.examples);

  if (!firstExample) {
    throw new Error(
      `Unable to find an example for the "${builtIn.name}" built-in function`
    );
  }

  return firstExample;
};

export const createBuiltInExamples = (): Document => ({
  id: uuid4(),
  title: "Built-in Functions",
  labels: EXAMPLE_LABELS,
  elements: builtIns.map(
    (builtIn) =>
      ({
        id: uuid4(),
        kind: "code",
        name: builtIn.name,
        code: getFirstExampleOrThrow(builtIn),
      } as CodeElement)
  ),
});
