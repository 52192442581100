import { createContext, FC, PropsWithChildren, useContext } from "react";

const ElementIdContext = createContext<string>("missing");

export const ElementIdProvider: FC<
  PropsWithChildren<{ elementId: string }>
> = ({ children, elementId }) => (
  <ElementIdContext.Provider value={elementId}>
    {children}
  </ElementIdContext.Provider>
);

export const useElementId = () => useContext(ElementIdContext);
