import { isError } from "lodash";
import { ElementReferenceError } from "../errors";
import { EvalResult } from "../types";

export const handleEvalError = (
  e: unknown,
  subsequentElementCamelCaseNames: string[]
): EvalResult => {
  // Check any "<variable> is not defined" error to see if it's an
  // attempt to reference a subsequent element by its camelCaseName
  if (isError(e) && e.message.endsWith("is not defined")) {
    const error = e as Error;

    const isCamelCaseNameReferenceAttempt =
      subsequentElementCamelCaseNames.some((camelCaseName) =>
        error.message.includes(camelCaseName)
      );

    if (isCamelCaseNameReferenceAttempt) {
      // Attempt to extract the referenced camelCaseName from the error message
      const [, elementName] = /^(.*) is not defined$/.exec(error.message) ?? [];

      // The camelCaseName was successfully extracted
      if (elementName) {
        return {
          result: new ElementReferenceError(
            `Cannot reference ${elementName} from this element`
          ),
        };
      }

      // Couldn't find the camelCaseName in the error message,
      // must be something else, so fallback to the original error
      return { result: e };
    }
  }

  return { result: e };
};
