import { selectorFamily, useRecoilValue } from "recoil";
import { tableElementByIdState } from "../../../../state";
import { TableRow } from "../../../../types";
import { ROW_NUMBER_COLUMN } from "../constants";

const gridRowsState = selectorFamily<TableRow[], string>({
  key: "table/gridRows",
  get:
    (elementId) =>
    ({ get }) => {
      const element = get(tableElementByIdState(elementId));

      if (!element) {
        throw new Error(`Unable to get grid rows for ${elementId}`);
      }

      return element.data.map((row, index) => ({
        ...row,
        [ROW_NUMBER_COLUMN.key]: index + 1,
      }));
    },
});

export const useRows = (elementId: string) =>
  useRecoilValue(gridRowsState(elementId));
