import { camelCase } from "lodash";
import { BuiltIn } from "./types";

const builtIn: BuiltIn = {
  name: "camelCase",
  description:
    "Use the `camelCase` function to compute an element's camelCaseName",
  examples: ["camelCase('My element')"],
  apply: camelCase,
};

export default builtIn;
