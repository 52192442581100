import { css } from "@emotion/react";
import { FC } from "react";
import { RenderLeafProps } from "slate-react";
import { Typography } from "../../styles";
import colors from "../../styles/colors";

type LeafProps = RenderLeafProps & {
  leaf: {
    comment?: boolean;
    string?: boolean;
    reference?: boolean;
    cell?: boolean;
    number?: boolean;
    boolean?: boolean;
    punctuation?: boolean;
    operator?: boolean;
    "function-name"?: boolean;
  };
};

const leafCss = (leaf: LeafProps["leaf"]) => [
  css`
    font-family: ${Typography.fontFamily};
    background: hsla(0, 0%, 100%, 0.5);
    ${(leaf.operator || leaf.reference) &&
    css`
      color: ${colors.rose[800]};
    `}
    ${leaf.cell &&
    css`
      color: #e90;
    `}
    ${(leaf.number || leaf.boolean) &&
    css`
      color: ${colors.indigo[700]};
    `}
    ${leaf.punctuation &&
    css`
      color: ${colors.neutral[500]};
    `}
    ${leaf.string &&
    css`
      color: ${colors.cyan[600]};
    `}
    ${leaf["function-name"] &&
    css`
      color: ${colors.blue[800]};
    `}
  `,
  leaf.comment &&
    css`
      color: ${colors.stone[400]} !important;
    `,
];

// different token types, styles found on Prismjs website
export const FormulaLeaf: FC<LeafProps> = ({ attributes, children, leaf }) => {
  return (
    <span {...attributes} css={leafCss(leaf)}>
      {children}
    </span>
  );
};
