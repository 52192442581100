import { atom } from "recoil";
import { PersistStorage } from "recoil-persist";
import { isFeatureEnabled } from "../feature-flags/isFeatureEnabled";
import { createPersistAtom } from "../utils/recoil/createPersistAtom";

// By default localStorage is used for persistence
let storage: PersistStorage = localStorage;

// If the "filesystem" feature is enabled, then
// dynamically import filesystem config support.
if (isFeatureEnabled("filesystem")) {
  storage = require("./config-filesystem").storage;
}

export const configStorageRootState = atom<string>({
  key: "storage-root",
  effects: [createPersistAtom({ key: "storage-root", storage })],
});
