import { atom, useRecoilCallback, useRecoilValue } from "recoil";
import { Toast, Toasts } from "./types";

export const toastsState = atom<Toasts>({
  key: "toasts",
  default: [],
});

export const useToasts = () => useRecoilValue(toastsState);

export const useDismissToast = (index: number) =>
  useRecoilCallback(
    ({ set }) =>
      () => {
        set(toastsState, (toasts) =>
          toasts.filter((_toast, idx) => index !== idx)
        );
      },
    [index]
  );

export const useShowToast = () =>
  useRecoilCallback(
    ({ set }) =>
      (toast: Toast) => {
        set(toastsState, (toasts) => [...toasts, toast]);
      },
    []
  );
