import { isArray } from "lodash";

export const getLength = (token: string | Prism.Token): number => {
  if (typeof token === "string") {
    return token.length;
  } else if (typeof token.content === "string") {
    return token.content.length;
  } else if (isArray(token.content)) {
    return token.content.reduce(
      (l: number, t: string | Prism.Token) => l + getLength(t),
      0
    );
  }
  return 0;
};
