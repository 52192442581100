import { sum } from "lodash";
import { BuiltIn } from "./types";

const builtIn: BuiltIn = {
  name: "sum",
  description: "Use the `sum` function to add numbers",
  examples: ["sum([1,2,3,4,5,6])"],
  apply: sum,
};

export default builtIn;
