import { BuiltIn } from "./types";

const builtIn: BuiltIn = {
  name: "product",
  description: "Use the `product` function to multiply numbers",
  examples: ["product([1,2,3,4,5,6])"],
  apply: (values: number[]) => values.reduce((acc, num) => acc * num, 1),
};

export default builtIn;
