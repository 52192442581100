import {
  atom,
  useRecoilCallback,
  useRecoilState,
  useRecoilValue,
} from "recoil";
import { useTrackAction } from "../observability";
import { persistAtom } from "./storage";

const menuOpenState = atom<boolean>({
  key: "menuOpen",
  default: true,
  effects: [persistAtom],
});

export const useMenuState = () => useRecoilState(menuOpenState);

const searchOpenState = atom<boolean>({
  key: "searchOpen",
  default: false,
});

export const useSearchOpenState = () => useRecoilState(searchOpenState);

const searchQueryState = atom<string>({
  key: "searchQuery",
  default: "",
});

export const useSearchQueryState = () => useRecoilState(searchQueryState);

export const digitsToDisplayState = atom<number>({
  key: "digitsToDisplay",
  default: 2,
  effects: [persistAtom],
});

export const useDigitsToDisplay = () => useRecoilValue(digitsToDisplayState);

export const isUpgradePromptOpenState = atom<boolean>({
  key: "isUpgradePromptOpen",
  default: false,
});

export const usePromptToUpgrade = () => {
  const trackAction = useTrackAction();

  return useRecoilCallback(
    ({ set }) =>
      (source: string) => {
        set(isUpgradePromptOpenState, true);
        trackAction(source);
      },
    [trackAction]
  );
};

export const useDismissUpgradePrompt = () =>
  useRecoilCallback(
    ({ set }) =>
      () => {
        set(isUpgradePromptOpenState, false);
      },
    []
  );

export const useIsPromptToUpgradeOpen = () =>
  useRecoilValue(isUpgradePromptOpenState);
