import {
  mdiTextBoxOutline,
  mdiLanguageJavascript,
  mdiTable,
  mdiFileQuestionOutline,
  mdiCodeJson,
  mdiCommaBox,
  mdiFunctionVariant,
} from "@mdi/js";
import { ElementKind } from "../types";

export const getElementKindIconPath = (kind: ElementKind) => {
  if (kind === "text") {
    return mdiTextBoxOutline;
  }
  if (kind === "code") {
    return mdiLanguageJavascript;
  }
  if (kind === "table") {
    return mdiTable;
  }
  if (kind === "json") {
    return mdiCodeJson;
  }
  if (kind === "csv") {
    return mdiCommaBox;
  }
  if (kind === "formula") {
    return mdiFunctionVariant;
  }
  return mdiFileQuestionOutline;
};
