export const ROW_HEIGHT = 35;

export const BASE_COLUMN_PROPS = {
  headerClass: "rdg-column-label-cell",
};

const ROW_NUMBER_COLUMN_WIDTH = 50;

export const ROW_NUMBER_COLUMN = {
  ...BASE_COLUMN_PROPS,
  key: "__row_number__",
  name: "",
  cellClass: "rdg-row-number-cell",
  width: ROW_NUMBER_COLUMN_WIDTH,
  minWidth: ROW_NUMBER_COLUMN_WIDTH,
  maxWidth: ROW_NUMBER_COLUMN_WIDTH,
};
