import { css } from "@emotion/react";
import { FC, FormEvent, useEffect, useRef, useState } from "react";
import { Icon } from "@mdi/react";
import { mdiClose } from "@mdi/js";
import { CANCEL, usePromptState } from "../state/prompt";
import {
  modalBodyCss,
  modalCloseButtonContainerCss,
  modalCloseButtonCss,
  modalCss,
  modalHeaderContainerCss,
  modalPositionerCss,
} from "./Modal";
import { baseButtonCss, Colors } from "../styles";
import { formButtonsCss } from "./Form";
import { getPromptEditor } from "./Prompts/getPromptEditor";
import { getDefaultPromptValue } from "./Prompts/getDefaultPromptValue";
import { PromptEditor } from "./Prompts/types";

const submitButtonCss = css`
  ${baseButtonCss}
  padding: 10px;
  min-width: 100px;
`;
const cancelButtonCss = css`
  ${baseButtonCss}
  padding: 10px;
  min-width: 100px;
`;

const Prompt: FC = () => {
  const [promptState, setPromptState] = usePromptState();
  const [value, setValue] = useState(getDefaultPromptValue(promptState));

  const focusRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    setValue(promptState?.initialValue ?? "");
  }, [promptState?.initialValue]);

  useEffect(() => {
    if (focusRef.current) {
      focusRef.current.focus();
    }

    setTimeout(() => {
      if (focusRef.current) {
        focusRef.current.select();
      }
    }, 0);
  }, []);

  if (!promptState || value === null) {
    return null;
  }

  const { message, resolver, placeholder, okLabel = "OK" } = promptState;

  const onCancel = () => {
    resolver(CANCEL);
    setPromptState(null);
  };

  const onSubmit = (event: FormEvent) => {
    event.preventDefault();
    resolver({ status: "ok", value });
    setPromptState(null);
  };

  const PromptEditorComponent: PromptEditor<any, any> =
    getPromptEditor(promptState);

  return (
    <div css={modalPositionerCss}>
      <div css={modalCss}>
        <div css={modalHeaderContainerCss}>
          <div>{message}</div>
          <div css={modalCloseButtonContainerCss}>
            <button type="button" css={modalCloseButtonCss} onClick={onCancel}>
              <Icon path={mdiClose} size={0.7} color={Colors.neutral600} />
            </button>
          </div>
        </div>

        <div css={modalBodyCss}>
          <form onSubmit={onSubmit}>
            <PromptEditorComponent
              focusRef={focusRef}
              message={message}
              placeholder={placeholder}
              value={value}
              setValue={setValue}
              options={promptState}
            />

            <div css={formButtonsCss}>
              <button type="button" css={cancelButtonCss} onClick={onCancel}>
                Cancel
              </button>
              <button type="submit" css={submitButtonCss}>
                {okLabel}
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

const PromptController: FC = () => {
  const [promptState] = usePromptState();

  if (!promptState) {
    return null;
  }

  return <Prompt />;
};

export default PromptController;
