import { css } from "@emotion/react";
import { FC, useCallback, useState } from "react";
import { createEditor, Text, NodeEntry, Range } from "slate";
import { withReact, Slate, Editable, RenderLeafProps } from "slate-react";
import Prism from "prismjs";
import { Colors, Typography } from "../../../styles";
import { deserialize, serialize } from "../../../utils/slate/utils";
import { getLength } from "../../../utils/slate/getLength";
import "./configurePrismJsonSupport";
import colors from "../../../styles/colors";
import { MAX_ELEMENT_HEIGHT } from "../../../components/constants";

interface Props {
  initialValue: string;
  onChange: (value: string) => void;
  onBlur: () => void;
  placeholder: string;
}

const editorCss = css`
  border: none;
  resize: vertical;
  width: 100%;
  border-radius: 2px;
  background-color: ${Colors.white};
  margin: 0;
  box-sizing: border-box;
  padding: 5px;
  font-size: ${Typography.baseFontSize};
  overflow: auto;
  max-height: ${MAX_ELEMENT_HEIGHT};
`;

type LeafProps = RenderLeafProps & {
  leaf: {
    punctuation?: boolean;
    operator?: boolean;
    string?: boolean;
    property?: boolean;
    number?: boolean;
  };
};

const leafCss = (leaf: LeafProps["leaf"]) => css`
  font-family: ${Typography.fontFamily};

  ${(leaf.punctuation || leaf.operator) &&
  css`
    color: ${colors.neutral[500]};
  `}
  ${(leaf.string || leaf.property) &&
  css`
    color: ${colors.cyan[600]};
  `}
  ${leaf.number &&
  css`
    color: ${colors.indigo[700]};
  `}
`;

const Leaf: FC<LeafProps> = ({ attributes, children, leaf }) => {
  return (
    <span {...attributes} css={leafCss(leaf)}>
      {children}
    </span>
  );
};

const JsonEditor: FC<Props> = ({
  initialValue,
  onChange,
  onBlur,
  placeholder,
}) => {
  const [editor] = useState(() => withReact(createEditor()));

  const [_initialValue] = useState(() => deserialize(initialValue));

  const renderLeaf = useCallback(
    (props: RenderLeafProps) => <Leaf {...props} />,
    []
  );

  const decorate = useCallback(([node, path]: NodeEntry) => {
    const ranges: Range[] = [];

    if (!Text.isText(node)) {
      return ranges;
    }

    const tokens = Prism.tokenize(node.text, Prism.languages.json);
    let start = 0;

    for (const token of tokens) {
      const length = getLength(token);
      const end = start + length;

      if (typeof token !== "string") {
        ranges.push({
          [token.type]: true,
          anchor: { path, offset: start },
          focus: { path, offset: end },
        });
      }

      start = end;
    }

    return ranges;
  }, []);

  return (
    <Slate
      editor={editor}
      value={_initialValue}
      onChange={(value) => {
        const isAstChange = editor.operations.some(
          (op) => "set_selection" !== op.type
        );
        if (isAstChange) {
          onChange(serialize(value));
        }
      }}
    >
      <Editable
        css={editorCss}
        placeholder={placeholder}
        decorate={decorate}
        renderLeaf={renderLeaf}
        onBlur={onBlur}
      />
    </Slate>
  );
};

export default JsonEditor;
