import {
  DefaultValue,
  selectorFamily,
  useRecoilState,
  useRecoilValue,
} from "recoil";
import { CsvElement } from "../../types";
import { elementByIdState } from "../../state";
import { isCsvElement } from "../../utils/isCsvElement";

export const csvElementByIdState = selectorFamily<CsvElement, string>({
  key: "document/csvElementById",
  get:
    (elementId) =>
    ({ get }) => {
      const element = get(elementByIdState(elementId));

      if (!isCsvElement(element)) {
        throw new Error(`Element kind is not csv element "${elementId}"`);
      }

      return element;
    },

  set:
    (elementId) =>
    ({ set }, update) => {
      if (update instanceof DefaultValue) {
        return;
      }

      set(elementByIdState(elementId), update);
    },
});

export const useCsvElement = (elementId: string) =>
  useRecoilValue(csvElementByIdState(elementId));
export const useCsvElementState = (elementId: string) =>
  useRecoilState(csvElementByIdState(elementId));
