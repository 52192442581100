import { PromptOptions } from "../../state/prompt";

export const getDefaultPromptValue = (prompt: PromptOptions | null) => {
  if (!prompt) {
    return null;
  }

  if (prompt.type === "list") {
    return [""];
  }

  if (prompt.type === "select") {
    return prompt.items.map((item) => item.value);
  }

  if (prompt.type === "number") {
    return 0;
  }

  return "";
};
