import { css } from "@emotion/react";
import { FC, memo, useCallback, useMemo, useState } from "react";
import { createEditor, Text, NodeEntry, Range, Node, Transforms } from "slate";
import { withReact, Slate, Editable, RenderLeafProps } from "slate-react";
import Prism from "prismjs";
import { Colors, Typography } from "../../styles";
import { getLength } from "../../utils/slate/getLength";
import { deserialize, serialize } from "../../utils/slate/utils";
import { useListener } from "../Editor";
import { MAX_ELEMENT_HEIGHT } from "../constants";
import { LANGUAGES, SupportedLanguage } from "./languages";
import { RecalculateHotKeyHint } from "../EvalResultDisplay";

interface Props {
  initialValue: string;
  onChange: (value: string) => void;
  onFocus: () => void;
  placeholder: string;
  language: SupportedLanguage;
}

const containerCss = css`
  position: relative;
`;

const editorCss = css`
  border: none;
  resize: vertical;
  width: 100%;
  border-radius: 2px;
  background-color: ${Colors.white};
  margin: 0;
  box-sizing: border-box;
  padding: 5px;
  max-height: ${MAX_ELEMENT_HEIGHT};
  min-height: 30px;
  overflow: auto;
  font-size: ${Typography.baseFontSize};
`;

const Editor: FC<Props> = ({
  initialValue,
  onChange,
  onFocus,
  placeholder,
  language,
}) => {
  const editor = useMemo(() => withReact(createEditor()), []);

  const [_initialValue] = useState(() => deserialize(initialValue));

  const { LeafComponent, grammar } = LANGUAGES[language];

  const renderLeaf = useCallback(
    (props: RenderLeafProps) => <LeafComponent {...props} />,
    [LeafComponent]
  );

  const decorate = useCallback(
    ([node, path]: NodeEntry) => {
      const ranges: Range[] = [];

      if (!Text.isText(node)) {
        return ranges;
      }

      const tokens = Prism.tokenize(node.text, grammar);
      let start = 0;

      for (const token of tokens) {
        const length = getLength(token);
        const end = start + length;

        if (typeof token !== "string") {
          ranges.push({
            [token.type]: true,
            anchor: { path, offset: start },
            focus: { path, offset: end },
          });
        }

        start = end;
      }

      return ranges;
    },
    [grammar]
  );

  const onValueChange = useCallback(
    (value: Node[]) => {
      const isAstChange = editor.operations.some(
        (op) => "set_selection" !== op.type
      );
      if (isAstChange) {
        onChange(serialize(value));
      }
    },
    [editor.operations, onChange]
  );

  const onInsert = useCallback(
    (value: string) => {
      Transforms.insertText(editor, value);
    },
    [editor]
  );

  const listener = useMemo(() => ({ onInsert }), [onInsert]);

  useListener(listener);

  const [isFocused, setIsFocused] = useState(false);

  const onFocusWrapped = useCallback(() => {
    setIsFocused(true);

    if (onFocus) {
      onFocus();
    }
  }, [onFocus]);

  const onBlur = useCallback(() => {
    setIsFocused(false);
  }, []);

  return (
    <div css={containerCss}>
      <Slate editor={editor} value={_initialValue} onChange={onValueChange}>
        <Editable
          css={editorCss}
          placeholder={placeholder}
          spellCheck={false}
          decorate={decorate}
          renderLeaf={renderLeaf}
          onFocus={onFocusWrapped}
          onBlur={onBlur}
        />
      </Slate>
      {isFocused && <RecalculateHotKeyHint />}
    </div>
  );
};

export default memo(Editor);
