import { CodeLeaf } from "./CodeLeaf";
import Prism from "prismjs";
import { LanguageSupport } from "./types";
import { FormulaLeaf } from "./FormulaLeaf";

import "./configurePrismJavaScriptSupport";
import "./configurePrismExcelFormulaSupport";

const code: LanguageSupport = {
  LeafComponent: CodeLeaf,
  grammar: Prism.languages.javascript,
};

const formula: LanguageSupport = {
  LeafComponent: FormulaLeaf,
  grammar: Prism.languages["excel-formula"],
};

export const LANGUAGES = {
  code,
  formula,
} as const;

export type SupportedLanguage = keyof typeof LANGUAGES;
