import { FC, PropsWithChildren } from "react";
import { MenuItem, MenuItemProps } from "react-contextmenu";
import { Icon } from "@mdi/react";
import { css } from "@emotion/react";
import { Colors } from "../styles";

type ContextMenuItemProps = PropsWithChildren<MenuItemProps> & {
  icon?: string;
  label?: string;
};

const menuItemLayoutCss = css`
  display: flex;
  align-items: center;
  gap: 10px;

  &:hover {
    background-color: ${Colors.neutral200};
  }
`;

export const ContextMenuItem: FC<ContextMenuItemProps> = ({
  icon,
  label,
  ...props
}) => (
  // @ts-expect-error
  <MenuItem {...props} css={menuItemLayoutCss}>
    {icon && <Icon path={icon} color={Colors.neutral500} size={0.7} />}
    {label}
  </MenuItem>
);
