import {
  DefaultValue,
  selectorFamily,
  useRecoilState,
  useRecoilValue,
} from "recoil";
import { documentByIdState } from "./core";

export const documentLabelsState = selectorFamily<string[], string>({
  key: "documentLabels",
  get:
    (documentId) =>
    ({ get }) => {
      const document = get(documentByIdState(documentId));
      return document.labels ?? [];
    },
  set:
    (documentId) =>
    ({ set }, update) => {
      if (update instanceof DefaultValue) {
        return;
      }

      set(documentByIdState(documentId), (document) => ({
        ...document,
        labels: update ?? [],
      }));
    },
});

export const useDocumentLabels = (documentId: string) =>
  useRecoilValue(documentLabelsState(documentId));

export const useDocumentLabelsState = (documentId: string) =>
  useRecoilState(documentLabelsState(documentId));
