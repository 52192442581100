import { useTableElement } from "../../../../state";
import { ROW_HEIGHT } from "../constants";

export const useMaxHeight = (elementId: string) => {
  const element = useTableElement(elementId);
  const { data } = element;

  // EXPLANATION: the maximum height of the table element
  const maxHeight =
    data.length < 10 ? (data.length + 1) * ROW_HEIGHT : ROW_HEIGHT * 11;

  return maxHeight;
};
