import { css } from "@emotion/react";
import { isEmpty, noop } from "lodash";
import {
  ChangeEvent,
  FC,
  FormEvent,
  useCallback,
  useMemo,
  useState,
} from "react";
import { ColumnEvalContextKey } from "../../../state";
import { baseButtonCss } from "../../../styles";
import {
  TableColumn,
  TableColumnAlignment,
  TableColumnType,
  TableRow,
} from "../../../types";
import { getTableRowIndex } from "../../../utils/getTableRowIndex";
import {
  formButtonsCss,
  formFieldCss,
  formFieldHintCss,
} from "../../../components/Form";
import { ColumnPreview } from "./ColumnPreviewContext";
import ColumnEvalContextPreview from "./ColumnEvalContextPreview";
import TablePreview from "./TablePreview";
import { OnCommitColumnChange } from "./types";
import { eventLoop } from "../../../utils/eventLoop";
import { TableColumnPreview } from "../../../state/types";
import Editor, { EditorController } from "../../../components/Editor";

interface Props {
  elementId: string;
  initialValue: TableColumn;
  onCancel: () => void;
  onCommit: OnCommitColumnChange;
  commitLabel: string;
  prohibitedColumnNames: string[];
  columnIndex: number;
  operation: "create" | "update";
}

const formLayoutCss = css`
  display: flex;
  flex-direction: column;
`;

const scrollContainerCss = css`
  overflow-y: auto;
  padding: 5px;
`;

const submitButtonCss = css`
  ${baseButtonCss}
  padding: 10px;
  min-width: 120px;
`;
const cancelButtonCss = css`
  ${baseButtonCss}
  padding: 10px;
  min-width: 120px;
`;

const ColumnConfigurationForm: FC<Props> = ({
  elementId,
  onCancel,
  onCommit,
  initialValue,
  commitLabel,
  prohibitedColumnNames = [],
  columnIndex,
  operation,
}) => {
  const [columnName, setColumnName] = useState(initialValue.name);
  const [columnType, setColumnType] = useState(initialValue.type ?? "string");
  const [columnAlignment, setColumnAlignment] = useState<TableColumnAlignment>(
    initialValue.align ?? "left"
  );
  const [columnContent, setColumnContent] = useState(
    initialValue.type === "function" || initialValue.type === "formula"
      ? initialValue.content
      : ""
  );

  const onSubmit = useCallback(
    (e: FormEvent) => {
      e.preventDefault();
      onCommit(
        Object.assign(
          {},
          initialValue,
          {
            key: columnName,
            name: columnName,
            type: columnType,
            align: columnAlignment,
          },
          columnType === "function" || columnType === "formula"
            ? { content: columnContent }
            : {}
        )
      );
    },
    [
      onCommit,
      columnName,
      columnType,
      columnContent,
      columnAlignment,
      initialValue,
    ]
  );

  const onChangeColumnName = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    setColumnName(e.target.value);
  }, []);

  const onChangeColumnType = useCallback(
    (e: ChangeEvent<HTMLSelectElement>) => {
      setColumnType(e.target.value as TableColumnType);
    },
    []
  );

  const onChangeColumnAlignment = useCallback(
    (e: ChangeEvent<HTMLSelectElement>) => {
      setColumnAlignment(e.target.value as TableColumnAlignment);
    },
    []
  );

  const onChangeColumnContent = useCallback(async (value: string) => {
    await eventLoop();
    setColumnContent(value);
  }, []);

  const canCommit =
    !isEmpty(columnName) && !prohibitedColumnNames.includes(columnName);

  const preview: TableColumnPreview = useMemo(
    () => ({
      key: operation === "create" ? columnName : initialValue.key,
      index: columnIndex,
      name: columnName,
      type: columnType,
      content: columnContent,
    }),
    [
      operation,
      initialValue.key,
      columnName,
      columnType,
      columnContent,
      columnIndex,
    ]
  );

  const [columnEvalContextPreviewKey, setColumnEvalContextPreviewKey] =
    useState<ColumnEvalContextKey>();

  const onRowClick = useCallback(
    (row: TableRow, columnKey: string) => {
      setColumnEvalContextPreviewKey({
        elementId,
        columnKey,
        rowIndex: getTableRowIndex(row),
        data: row,
      });
    },
    [elementId]
  );

  return (
    <form css={formLayoutCss} onSubmit={onSubmit}>
      <div css={scrollContainerCss}>
        <div css={formFieldCss}>
          <label htmlFor="column-name">Name</label>
          <input
            name="column-name"
            type="text"
            value={columnName}
            onChange={onChangeColumnName}
          />
          <div
            css={formFieldHintCss(prohibitedColumnNames.includes(columnName))}
          >
            The column name "{columnName}" is already used by another column.
          </div>
        </div>

        <div css={formFieldCss}>
          <label htmlFor="column-type">Data type</label>
          <select
            name="column-type"
            value={columnType}
            onChange={onChangeColumnType}
          >
            <option value="string">Text (string)</option>
            <option value="number">Number</option>
            <option value="boolean">Checkbox (boolean)</option>
            <option value="function">Function (computed)</option>
            <option value="formula">Formula (computed)</option>
          </select>
        </div>

        {columnType !== "boolean" && (
          <div css={formFieldCss}>
            <label htmlFor="column-alignment">Alignment</label>
            <select
              name="column-alignment"
              value={columnAlignment}
              onChange={onChangeColumnAlignment}
            >
              <option value="left">Left</option>
              <option value="center">Center</option>
              <option value="right">Right</option>
            </select>
          </div>
        )}

        {columnType === "function" && (
          <EditorController>
            <div css={formFieldCss}>
              <label htmlFor="column-name">Function</label>
              <div data-has-field-border>
                <Editor
                  language="code"
                  placeholder="Enter JavaScript here..."
                  initialValue={columnContent}
                  onChange={onChangeColumnContent}
                  onFocus={noop}
                />
              </div>
            </div>

            <ColumnPreview preview={preview}>
              <TablePreview
                elementId={elementId}
                columnPreview={operation === "create" ? preview : undefined}
                onRowClick={onRowClick}
              />
            </ColumnPreview>

            <ColumnEvalContextPreview
              previewKey={columnEvalContextPreviewKey}
            />
          </EditorController>
        )}

        {columnType === "formula" && (
          <EditorController>
            <div css={formFieldCss}>
              <label htmlFor="column-name">Formula</label>
              <Editor
                language="formula"
                placeholder="Enter formula here..."
                initialValue={columnContent}
                onChange={onChangeColumnContent}
                onFocus={noop}
              />
            </div>

            <ColumnPreview preview={preview}>
              <TablePreview
                elementId={elementId}
                columnPreview={operation === "create" ? preview : undefined}
                onRowClick={onRowClick}
              />
            </ColumnPreview>

            <ColumnEvalContextPreview
              previewKey={columnEvalContextPreviewKey}
            />
          </EditorController>
        )}
      </div>

      <div css={formButtonsCss}>
        <button type="button" css={cancelButtonCss} onClick={onCancel}>
          Cancel
        </button>
        <button type="submit" css={submitButtonCss} disabled={!canCommit}>
          {commitLabel}
        </button>
      </div>
    </form>
  );
};

export default ColumnConfigurationForm;
