import { isDevelopment } from "../utils/isDevelopment";
import { isElectron } from "../utils/isElectron";

export type FeatureFlag =
  | "filesystem"
  | "filesystem:export"
  | "filesystem:import"
  | "filesystem:import:table"
  | "require";

export const isFeatureEnabled = (feature: FeatureFlag) => {
  if (feature === "filesystem:import:table") {
    return isElectron() || isDevelopment();
  }

  if (feature === "filesystem:import" || feature === "filesystem:export") {
    return isElectron() || isDevelopment();
  }

  return isElectron();
};
