import _, { camelCase } from "lodash";
import { selectorFamily, useRecoilValue } from "recoil";
import * as formula from "formula";
import builtIns from "../built-ins";
import { BuiltInSuggestion, ElementSuggestion } from "../types";
import { DEFAULT_ELEMENT_NAME } from "./constants";
import { elementNameByIdState, useElementKind } from "./core";
import { previousElementsState } from "./eval";
import { getBuiltInsFromLibrary } from "../utils/getBuiltInsFromLibrary";

const previousElementSuggestionsState = selectorFamily<
  ElementSuggestion[],
  string
>({
  key: "document/previousElementSuggestions",
  get:
    (elementId: string) =>
    ({ get }) => {
      const previousElements = get(previousElementsState(elementId));

      return previousElements.map((e, index) => {
        const name = get(elementNameByIdState(e.id));
        const num = index + 1;
        const slug =
          name === DEFAULT_ELEMENT_NAME ? `$(${num})` : camelCase(name);

        return {
          id: e.id,
          num,
          name,
          slug,
        };
      });
    },
});

export const usePreviousElementSuggestions = (elementId: string) =>
  useRecoilValue(previousElementSuggestionsState(elementId));

export const useBuiltInSuggestions = (
  elementId: string
): BuiltInSuggestion[] => {
  const elementKind = useElementKind(elementId);

  if (elementKind === "code") {
    return [
      ...builtIns.map((builtIn) => ({
        id: builtIn.name,
        name: builtIn.name,
        slug: builtIn.name,
      })),
      ...getBuiltInsFromLibrary(_, "_."),
    ];
  }

  if (elementKind === "formula") {
    return getBuiltInsFromLibrary(formula);
  }

  return [];
};
