import { css } from "@emotion/react";
import {
  compact,
  isBoolean,
  isEmpty,
  isNumber,
  isObject,
  toString,
  uniq,
} from "lodash";
import { FC, Fragment } from "react";
import { Colors } from "../../../styles";
import { ResultDisplayComponent } from "../types";
import { ObjectDisplay } from "./ObjectDisplay";

type RowValue = Record<string, unknown>;

interface RowDisplayProps {
  index: number;
  columns: string[];
  value: RowValue;
}

const rowNumberCellCss = css`
  background-color: ${Colors.neutral100};
  color: ${Colors.neutral900};
`;

const rowCellCss = (cellValue: unknown) => [
  isBoolean(cellValue) &&
    css`
      text-align: center;
    `,
  isNumber(cellValue) &&
    css`
      text-align: right;
    `,
];

const RowIndex: FC<{ index: number }> = ({ index }) => (
  <td key="__row_number__" css={rowNumberCellCss}>
    {index + 1}
  </td>
);

const RowDisplay: FC<RowDisplayProps> = ({ index, columns, value }) => (
  <Fragment>
    <RowIndex index={index} />
    {columns.map((column, index) => (
      <td key={index} css={rowCellCss(value[column])}>
        {isBoolean(value[column]) ? (
          <input disabled type="checkbox" checked={Boolean(value[column])} />
        ) : (
          toString(value[column])
        )}
      </td>
    ))}
  </Fragment>
);

const tableCss = css`
  width: 100%;
  border-collapse: collapse;

  & > thead > tr > th {
    text-align: center;
    background-color: ${Colors.neutral100};
  }

  & tr > * {
    margin: 0;
    padding: 7px 5px;
    border-top: none;
    border-left: none;

    &:not(:last-child) {
      border-right: 1px solid ${Colors.neutral300};
    }
  }

  & tr {
    margin: 0;
    padding: 0;
    border: none;
  }

  & > thead > tr > *,
  & > tbody > tr:not(:last-child) > * {
    border-bottom: 1px solid ${Colors.neutral300};
  }
`;

export const TableDisplay: ResultDisplayComponent<unknown[]> = ({ value }) => {
  const columns = uniq(
    compact(value.flatMap((v) => (isObject(v) ? Object.keys(v) : undefined)))
  );

  return (
    <table css={tableCss}>
      {!isEmpty(columns) && (
        <thead>
          <tr>
            <th key="__row_number__" />
            {columns.map((column, index) => (
              <th key={index}>{column}</th>
            ))}
          </tr>
        </thead>
      )}
      <tbody>
        {value.map((v, index) => (
          <tr key={index}>
            {isObject(v) ? (
              <RowDisplay
                index={index}
                columns={columns}
                value={v as RowValue}
              />
            ) : (
              <Fragment>
                <RowIndex index={index} />
                <td colSpan={columns.length}>
                  <ObjectDisplay value={v} />
                </td>
              </Fragment>
            )}
          </tr>
        ))}
      </tbody>
    </table>
  );
};
