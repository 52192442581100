import colors from "../styles/colors";
import { ElementKind } from "../types";

export const getElementKindIconColor = (kind: ElementKind) => {
  if (kind === "text") {
    return colors.sky[800];
  }
  if (kind === "code") {
    return colors.amber[500];
  }
  if (kind === "table") {
    return colors.green[800];
  }
  if (kind === "json") {
    return colors.indigo[800];
  }
  if (kind === "csv") {
    return colors.emerald[800];
  }
  if (kind === "formula") {
    return colors.amber[500];
  }
  return colors.stone[800];
};
