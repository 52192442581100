import { FC, memo, useCallback, useState } from "react";
import { Icon } from "@mdi/react";
import { mdiPlus } from "@mdi/js";
import Menu, { MenuContainer, MenuItem, MenuSeparator } from "./Menu";
import { useAddElement } from "../state";
import { createTextElement } from "../utils/createTextElement";
import { createCodeElement } from "../utils/createCodeElement";
import { createTableElement } from "../utils/createTableElement";
import { createJsonElement } from "../utils/createJsonElement";
import { createCsvElement } from "../utils/createCsvElement";
import { createFormulaElement } from "../utils/createFormulaElement";
import { css } from "@emotion/react";
import { baseButtonCss, Colors, transition } from "../styles";
import { getElementKindIconPath } from "../utils/getElementKindIconPath";
import { TooltipWrapper } from "./Tooltip";

interface Props {
  /** The previous element or undefined if the button occurs before any element */
  elementId: string | undefined;
}

const containerCss = css`
  display: flex;
  align-items: center;
  gap: 5px;
`;

const addElementButtonCss = css`
  ${baseButtonCss}
  width: 30px;
  height: 30px;
  margin-left: 10px;

  transition: ${transition("background-color")};

  &:hover {
    background-color: ${Colors.neutral200};
  }
`;

const promptCss = css`
  display: none;
  border: none;
  background-color: ${Colors.transparent};

  &:focus {
    box-shadow: none !important;
  }
`;

const AddElementButton: FC<Props> = memo(({ elementId }) => {
  const [isAddMenuOpen, setAddMenuOpen] = useState(false);

  const onToggleAddMenu = () => setAddMenuOpen((b) => !b);
  const onHideAddMenu = () => setAddMenuOpen(false);

  const addElement = useAddElement(elementId);

  const onAddTextElement = useCallback(() => {
    onHideAddMenu();
    addElement(createTextElement());
  }, [addElement]);

  const onAddCodeElement = useCallback(() => {
    onHideAddMenu();
    addElement(createCodeElement());
  }, [addElement]);

  const onAddTableElement = useCallback(() => {
    onHideAddMenu();
    addElement(createTableElement());
  }, [addElement]);

  const onAddJsonElement = useCallback(() => {
    onHideAddMenu();
    addElement(createJsonElement());
  }, [addElement]);

  const onAddCsvElement = useCallback(() => {
    onHideAddMenu();
    addElement(createCsvElement());
  }, [addElement]);

  const onAddFormulaElement = useCallback(() => {
    onHideAddMenu();
    addElement(createFormulaElement());
  }, [addElement]);

  return (
    <div css={containerCss}>
      <MenuContainer>
        <TooltipWrapper position="east" content="Add an element">
          {(childAttrs) => (
            <button
              {...childAttrs}
              type="button"
              css={addElementButtonCss}
              onClick={onToggleAddMenu}
            >
              <Icon path={mdiPlus} color={Colors.neutral400} size={2} />
            </button>
          )}
        </TooltipWrapper>

        {isAddMenuOpen && (
          <Menu onHide={onHideAddMenu}>
            <MenuItem
              label="Add text"
              icon={getElementKindIconPath("text")}
              onClick={onAddTextElement}
            />

            <MenuSeparator />

            <MenuItem
              label="Add code"
              icon={getElementKindIconPath("code")}
              onClick={onAddCodeElement}
            />

            <MenuItem
              label="Add formula"
              icon={getElementKindIconPath("formula")}
              onClick={onAddFormulaElement}
            />

            <MenuSeparator />

            <MenuItem
              label="Add table"
              icon={getElementKindIconPath("table")}
              onClick={onAddTableElement}
            />
            <MenuItem
              label="Add JSON"
              icon={getElementKindIconPath("json")}
              onClick={onAddJsonElement}
            />
            <MenuItem
              label="Add CSV"
              icon={getElementKindIconPath("csv")}
              onClick={onAddCsvElement}
            />
          </Menu>
        )}
      </MenuContainer>

      <input css={promptCss} type="text" />
    </div>
  );
});

export default AddElementButton;
