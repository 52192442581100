import { css } from "@emotion/react";
import { FC, Fragment } from "react";
import { Icon } from "@mdi/react";
import { mdiClose } from "@mdi/js";
import useClipboard from "react-use-clipboard";
import {
  modalBodyCss,
  modalCloseButtonContainerCss,
  modalCloseButtonCss,
  modalCss,
  modalHeaderContainerCss,
  modalPositionerCss,
  modalSizeCss,
} from "./Modal";
import { baseButtonCss, Colors, flexboxCss, linkButtonCss } from "../styles";
import { SharingResult } from "../state";
import Text from "./Text";
import { capitalize } from "lodash";
import { useImportFromIntentURL } from "../state/routes";

interface Props {
  result: SharingResult | undefined;
  onDismiss: () => void;
}

const sharingResultContainerCss = css`
  display: flex;
  align-items: center;
  justify-content: space-between;
  border: 1px solid ${Colors.neutral300};
  padding-left: 10px;
  border-radius: 3px;
`;

const copyToClipboardButtonCss = css`
  ${linkButtonCss}
  padding: 10px;
`;

const dismissButtonCss = css`
  ${baseButtonCss}
`;

const SharingResultModal: FC<Props> = ({ result, onDismiss }) => {
  const secret = result?.status === "success" ? result.secret : "";

  const [isSecretCopied, setSecretCopied] = useClipboard(secret, {
    successDuration: 5000,
  });

  const importFromIntentURL = useImportFromIntentURL(secret);

  const [isIntentCopied, setIntentCopied] = useClipboard(importFromIntentURL, {
    successDuration: 5000,
  });

  if (!result) {
    return null;
  }

  const title = capitalize(result.status);

  const summary =
    result.status === "success"
      ? `Notebook has been successfully shared.`
      : `Unable to share notebook.`;

  return (
    <div css={modalPositionerCss}>
      <div css={[modalCss, modalSizeCss({ width: "800px" })]}>
        <div css={modalHeaderContainerCss}>
          <div>{title}</div>
          <div css={modalCloseButtonContainerCss}>
            <button type="button" css={modalCloseButtonCss} onClick={onDismiss}>
              <Icon path={mdiClose} size={0.7} color={Colors.neutral600} />
            </button>
          </div>
        </div>

        <div
          css={[modalBodyCss, flexboxCss({ direction: "column", gap: "20px" })]}
        >
          <Text align="center" type="subTitle">
            {summary}
          </Text>

          {result.status === "success" && (
            <Fragment>
              <div css={flexboxCss({ direction: "column", gap: "5px" })}>
                <Text type="small">
                  Use this URL to import the shared notebook:
                </Text>

                <div css={sharingResultContainerCss}>
                  <Text monospace color={Colors.neutral600}>
                    {importFromIntentURL}
                  </Text>

                  <button
                    type="button"
                    css={copyToClipboardButtonCss}
                    onClick={setIntentCopied}
                  >
                    {isIntentCopied ? "Copied" : "Copy to clipboard"}
                  </button>
                </div>
              </div>

              <div css={flexboxCss({ direction: "column", gap: "5px" })}>
                <Text type="small">
                  Or this ID to import the shared notebook:
                </Text>

                <div css={sharingResultContainerCss}>
                  <Text monospace color={Colors.neutral600}>
                    {result.secret}
                  </Text>

                  <button
                    type="button"
                    css={copyToClipboardButtonCss}
                    onClick={setSecretCopied}
                  >
                    {isSecretCopied ? "Copied" : "Copy to clipboard"}
                  </button>
                </div>
              </div>
            </Fragment>
          )}

          <div css={flexboxCss({ justify: "end" })}>
            <button type="button" css={dismissButtonCss} onClick={onDismiss}>
              Dismiss
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SharingResultModal;
