import {
  camelCase,
  isEmpty,
  isError,
  isNumber,
  isString,
  takeRightWhile,
  takeWhile,
  trim,
} from "lodash";
import { noWait, selectorFamily, useRecoilValue } from "recoil";
import builtIns from "../built-ins";
import { ElementReferenceError } from "../errors";
import RequireIsUnsupportedError from "../errors/RequireIsUnsupportedError";
import { isFeatureEnabled } from "../feature-flags";
import { Element } from "../types";
import { isCodeElement } from "../utils/isCodeElement";
import { isCsvElement } from "../utils/isCsvElement";
import { isFormulaElement } from "../utils/isFormulaElement";
import { isJsonElement } from "../utils/isJsonElement";
import { isTableElement } from "../utils/isTableElement";
import { isTextElement } from "../utils/isTextElement";
import {
  elementByIdState,
  elementNumberByIdState,
  elementNameByIdState,
  elementsState,
} from "./core";
import { formulaElementResultState } from "../elements/formula/state";
import { tableDataState } from "../elements/table/state";
import { RelativeElementKey, EvalContext, IndexOffsetKey } from "./types";
import { codeElementResultState } from "../elements/code/state";

const elementByIndexState = selectorFamily<Element | undefined, number>({
  key: "document/elementByIndex",
  get:
    (index) =>
    ({ get }) =>
      get(elementsState)[index],
});

const indexOfElementState = selectorFamily<number, string>({
  key: "document/indexOfElement",
  get:
    (elementId: string) =>
    ({ get }) => {
      const elements = get(elementsState);
      return elements.findIndex((e) => elementId === e.id);
    },
});

const elementByIndexOffsetState = selectorFamily<
  Element | undefined,
  IndexOffsetKey
>({
  key: "document/elementByIndexOffset",
  get:
    ({ elementId, offset }) =>
    ({ get }) => {
      const indexOfSource = get(elementsState).findIndex(
        (element) => element.id === elementId
      );

      const indexOfTarget = indexOfSource + offset;

      if (indexOfTarget >= 0) {
        return get(elementsState)[indexOfTarget];
      }
    },
});

const elementByNameState = selectorFamily<Element | undefined, string>({
  key: "document/elementByName",
  get:
    (name) =>
    ({ get }) =>
      get(elementsState).find((element) => element.name === name),
});

const relativeElementState = selectorFamily<
  Element | undefined,
  RelativeElementKey
>({
  key: "document/relativeElement",
  get:
    ({ indexOrName, elementId }) =>
    ({ get }) => {
      if (isNumber(indexOrName)) {
        // Absolute indexing
        if (indexOrName >= 0) {
          const element = get(elementByIndexState(indexOrName - 1));
          return element;
        }
        // Relative indexing
        else {
          const element = get(
            elementByIndexOffsetState({ elementId, offset: indexOrName })
          );
          return element;
        }
      } else if (isString(indexOrName)) {
        const element = get(elementByNameState(indexOrName));
        return element;
      }
    },
});

const canReferenceElementState = selectorFamily<
  Error | true,
  RelativeElementKey
>({
  key: "document/canReferenceElement",
  get:
    ({ indexOrName, elementId }) =>
    ({ get }) => {
      const referencerIndex = get(indexOfElementState(elementId));
      const referencerNumber = referencerIndex + 1;

      if (isNumber(indexOrName)) {
        // No referencing by number larger than any existent element
        const maxValidElementNumber = get(elementsState).length;
        if (indexOrName > maxValidElementNumber) {
          throw new ElementReferenceError(
            `Element ${indexOrName} does not exist`
          );
        }

        // No referencing of subsequent elements by number
        if (indexOrName > referencerNumber) {
          return new ElementReferenceError(
            `Cannot reference element ${indexOrName} from element ${referencerNumber}`
          );
        }

        // No self-referencing by number
        if (indexOrName === referencerNumber) {
          return new ElementReferenceError(
            `Cannot reference element ${indexOrName} from itself`
          );
        }
      } else if (isString(indexOrName)) {
        const element = get(elementByNameState(indexOrName));
        if (!element) {
          throw new ElementReferenceError(
            `Element by the name of "${indexOrName}" does not exist`
          );
        }

        const referenceeIndex = get(indexOfElementState(element.id));

        // No referencing of subsequent elements by name
        if (referencerIndex < referenceeIndex) {
          return new ElementReferenceError(
            `Cannot reference element "${indexOrName}" from element ${referencerNumber}`
          );
        }

        // No self-referencing by name
        if (referenceeIndex === referencerIndex) {
          return new ElementReferenceError(
            `Cannot reference element "${indexOrName}" from itself`
          );
        }
      }

      // The reference is allowed
      return true;
    },
});

const elementValueState = selectorFamily<unknown, RelativeElementKey>({
  key: "document/elementValue",
  get:
    (key) =>
    ({ get }) => {
      const element = get(relativeElementState(key));

      if (!element) {
        return;
      }

      if (isTextElement(element)) {
        return element.content;
      }

      if (isCodeElement(element)) {
        return get(codeElementResultState(element.id)).result;
      }

      if (isFormulaElement(element)) {
        return get(formulaElementResultState(element.id)).result;
      }

      if (isTableElement(element)) {
        return get(tableDataState(element.id));
      }

      if (isJsonElement(element)) {
        if (isEmpty(element.data)) {
          return undefined;
        }

        try {
          return JSON.parse(element.data);
        } catch (e: unknown) {
          return e;
        }
      }

      if (isCsvElement(element)) {
        return (
          element.data
            // Split up the CSV into rows
            .split("\n")
            // Filter out empty rows
            .filter((rowAsString) => !isEmpty(trim(rowAsString)))
            // Split into columns
            .map((line) => line.split(","))
        );
      }
    },
});

const previousElementValueState = selectorFamily<unknown, RelativeElementKey>({
  key: "document/previousElementValue",
  get:
    (key) =>
    ({ get }) => {
      const canReference = get(canReferenceElementState(key));

      if (isError(canReference)) {
        return canReference;
      }

      return get(elementValueState(key));
    },
});

export const useElementError = (elementId: string) => {
  const element = useRecoilValue(elementByIdState(elementId));

  if (isCodeElement(element)) {
    throw new Error(
      `useElementError cannot be used on a "code" element. Use useCodeElementResult instead.`
    );
  }

  const elementNumber = useRecoilValue(elementNumberByIdState(elementId));

  const result = useRecoilValue(
    elementValueState({ elementId, indexOrName: elementNumber })
  );

  return isError(result) ? result : undefined;
};

export const previousElementsState = selectorFamily<Element[], string>({
  key: "previousElements",

  get:
    (elementId: string) =>
    ({ get }) => {
      const elements = get(elementsState);
      return takeWhile(elements, (e) => e.id !== elementId);
    },
});

export const subsequentElementsState = selectorFamily<Element[], string>({
  key: "subsequentElements",

  get:
    (elementId: string) =>
    ({ get }) => {
      const elements = get(elementsState);
      return takeRightWhile(elements, (e) => e.id !== elementId);
    },
});

export const subsequentElementCamelCaseNamesState = selectorFamily<
  string[],
  string
>({
  key: "subsequentElementCamelCaseNames",
  get:
    (elementId: string) =>
    ({ get }) => {
      const subsequentElements = get(subsequentElementsState(elementId));

      const subsequentElementCamelCaseNames = subsequentElements.map((se) =>
        camelCase(get(elementNameByIdState(se.id)))
      );

      return subsequentElementCamelCaseNames;
    },
});

export const evalContextByIdState = selectorFamily<EvalContext, string>({
  key: "document/evalContextById",
  get:
    (elementId: string) =>
    ({ get }) => {
      const getValueSync = (indexOrName: number | string) => {
        try {
          const value = get(
            noWait(previousElementValueState({ indexOrName, elementId }))
          );

          if (value.state === "loading") {
            return value.toPromise();
          }

          return value.contents;
        } catch (error: unknown) {
          console.error(`Failed to getValueSync(${indexOrName})`, error);
          return undefined;
        }
      };

      let requireInEval = (moduleName: string) => {
        throw new RequireIsUnsupportedError(moduleName);
      };

      if (isFeatureEnabled("require")) {
        requireInEval = require("./eval-filesystem").requireInEval;
      }

      const previousElements = get(previousElementsState(elementId));

      const evalContext = Object.assign(
        { $: getValueSync },

        { require: requireInEval },

        // Provide all built-ins
        ...builtIns.map((builtIn) => ({
          [builtIn.name]: builtIn.apply,
        })),

        // Provide all camelCaseNamed previous elements
        ...previousElements.map((pe, idx) => {
          const key = camelCase(get(elementNameByIdState(pe.id)));
          const elementNumber = idx + 1;
          const val = getValueSync(elementNumber);
          return {
            [key]: val,
          };
        })
      );

      return evalContext;
    },
});
