import {
  FC,
  createContext,
  useContext,
  PropsWithChildren,
  useMemo,
  useEffect,
} from "react";

interface Listener {
  onInsert: (value: string) => void;
}

interface ContextProps extends Listener {
  subscribe: (listener: Listener) => void;
  unsubscribe: (listener: Listener) => void;
}

const defaultContextValue: ContextProps = {
  subscribe: (listener: Listener) => {
    throw new Error("Do not use the default context");
  },

  unsubscribe: (listener: Listener) => {
    throw new Error("Do not use the default context");
  },

  onInsert: (value) => {
    throw new Error("Do not use the default context");
  },
};

const Context = createContext<ContextProps>(defaultContextValue);

export const EditorController: FC<PropsWithChildren> = ({ children }) => {
  const contextValue = useMemo(() => {
    let listeners: Listener[] = [];

    return {
      subscribe: (listener: Listener) => {
        listeners = [...listeners, listener];
      },

      unsubscribe: (listener: Listener) => {
        listeners = listeners.filter((l) => l !== listener);
      },

      onInsert: (value: string) => {
        listeners.forEach((listener) => {
          listener.onInsert(value);
        });
      },
    };
  }, []);

  return <Context.Provider value={contextValue}>{children}</Context.Provider>;
};

export const useListener = (listener: Listener) => {
  const controller = useContext(Context);

  useEffect(() => {
    controller.subscribe(listener);

    return () => {
      controller.unsubscribe(listener);
    };
  }, [controller, listener]);
};

export const useDispatcher = () => {
  const controller = useContext(Context);
  return controller as Listener;
};
