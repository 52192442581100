import {
  FC,
  createContext,
  useContext,
  PropsWithChildren,
  useState,
  useEffect,
} from "react";

const Context = createContext<number>(0);

export const AwaitProvider: FC<PropsWithChildren> = ({ children }) => {
  const [count, setCount] = useState(0);

  useEffect(() => {
    const handle = setInterval(() => {
      setCount((c) => c + 1);
    }, 300);

    return () => {
      clearInterval(handle);
    };
  }, []);

  return <Context.Provider value={count}>{children}</Context.Provider>;
};

export const useAwaitCount = () => useContext(Context);
