import {
  DefaultValue,
  selectorFamily,
  useRecoilState,
  useRecoilValue,
} from "recoil";
import _ from "lodash";
import { isCodeElement } from "../../utils/isCodeElement";
import { CodeElement, CodeElementAppearance, EvalResult } from "../../types";
import {
  elementByIdState,
  evalContextByIdState,
  subsequentElementCamelCaseNamesState,
} from "../../state";
import { isEmpty } from "lodash";
import evalCode from "../../utils/evalCode";
import { handleEvalError } from "../../utils/handleEvalError";

export const codeElementByIdState = selectorFamily<CodeElement, string>({
  key: "document/codeElementById",
  get:
    (elementId) =>
    ({ get }) => {
      const element = get(elementByIdState(elementId));

      if (!isCodeElement(element)) {
        throw new Error(`Element kind is not code element "${elementId}"`);
      }

      return element;
    },

  set:
    (elementId) =>
    ({ set }, update) => {
      if (update instanceof DefaultValue) {
        return;
      }

      set(elementByIdState(elementId), update);
    },
});

export const useCodeElement = (elementId: string) =>
  useRecoilValue(codeElementByIdState(elementId));
export const useCodeElementState = (elementId: string) =>
  useRecoilState(codeElementByIdState(elementId));

export const codeElementAppearanceState = selectorFamily<
  CodeElementAppearance,
  string
>({
  key: "codeElementAppearance",
  get:
    (elementId: string) =>
    ({ get }) => {
      const element = get(codeElementByIdState(elementId));
      return element.appearance ?? "code-and-result";
    },
  set:
    (elementId) =>
    ({ set }, update) => {
      if (update instanceof DefaultValue) {
        return;
      }

      set(codeElementByIdState(elementId), (element) => ({
        ...element,
        appearance: update,
      }));
    },
});

export const useCodeElementAppearance = (elementId: string) =>
  useRecoilValue(codeElementAppearanceState(elementId));
export const useCodeElementAppearanceState = (elementId: string) =>
  useRecoilState(codeElementAppearanceState(elementId));

export const codeElementResultState = selectorFamily<EvalResult, string>({
  key: "document/codeElementResult",
  get:
    (elementId: string) =>
    ({ get }) => {
      const { code } = get(codeElementByIdState(elementId));

      if (isEmpty(code)) {
        return { result: undefined, isEmptyResult: true };
      }

      const evalContext = get(evalContextByIdState(elementId));

      try {
        const result = evalCode(code, { ...evalContext, _ }, elementId);
        return { result };
      } catch (e: unknown) {
        const subsequentElementCamelCaseNames = get(
          subsequentElementCamelCaseNamesState(elementId)
        );

        return handleEvalError(e, subsequentElementCamelCaseNames);
      }
    },
});

export const useCodeElementResult = (elementId: string) =>
  useRecoilValue(codeElementResultState(elementId));
