import { v4 as uuid4 } from "uuid";
import { Document, TextElement } from "../types";

export const createEmptyDocument = () =>
  ({
    id: uuid4(),
    elements: [
      {
        id: uuid4(),
        kind: "text",
        content: "Hello Calcula!",
      } as TextElement,
    ],
  } as Document);
