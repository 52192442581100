import { css } from "@emotion/react";
import { isError, isNumber, isString, omit, toNumber } from "lodash";
import { useMemo } from "react";
import type { FormatterProps } from "react-data-grid";
import {
  useDigitsToDisplay,
  useFunctionColumnResult,
  useTableColumnAlignment,
} from "../../../../state";
import { errorCss, Typography } from "../../../../styles";
import { TableColumnAlignment, TableRow } from "../../../../types";
import { useElementId } from "../../../../components/ElementIdContext";
import { useColumnPreview } from "../ColumnPreviewContext";
import { ROW_NUMBER_COLUMN } from "../constants";
import { getJustifyContent } from "../utils/getJustifyContent";

const containerCss = (align: TableColumnAlignment | undefined) => css`
  display: flex;
  align-items: center;
  justify-content: ${getJustifyContent(align)};
  font-size: ${Typography.baseFontSize};
  padding: 0 5px;
`;

export default function FunctionFormatter<TRow extends TableRow, TSummaryRow>({
  row,
  column,
}: FormatterProps<TRow, TSummaryRow>) {
  const elementId = useElementId();
  const digitsToDisplay = useDigitsToDisplay();
  const align = useTableColumnAlignment({ elementId, columnKey: column.key });

  const preview = useColumnPreview(column.key);

  const functionContext = useMemo(
    () => ({
      elementId,
      column: { key: column.key },
      row: omit(row, ROW_NUMBER_COLUMN.key),
      rowIndex: toNumber(row[ROW_NUMBER_COLUMN.key]),
      preview,
    }),
    [row, column, preview, elementId]
  );

  const { result } = useFunctionColumnResult(functionContext);

  if (isError(result)) {
    console.error(result);
    return (
      <div css={[containerCss(align), errorCss]} title={result.message}>
        {result.message}
      </div>
    );
  }

  if (isString(result)) {
    return (
      <div css={containerCss(align)} title={result}>
        {result}
      </div>
    );
  }

  const formatted = isNumber(result)
    ? result.toFixed(digitsToDisplay)
    : JSON.stringify(result, undefined, 2);

  return (
    <div css={containerCss(align)} title={formatted}>
      {formatted}
    </div>
  );
}
