import { selectorFamily, useRecoilValue } from "recoil";
import Fuse from "fuse.js";

import { Searchable, SearchResults } from "../types";
import { toSearchable } from "../utils/toSearchable";
import { documentsState } from "./core";
import { isNumber } from "lodash";

export const searchResultsState = selectorFamily<SearchResults, string>({
  key: "searchResults",
  get:
    (query: string) =>
    ({ get }) => {
      const documents = get(documentsState);
      const searchables = documents.flatMap((document) =>
        document.elements.map((element) => toSearchable(element, document))
      );

      const db = new Fuse<Searchable>(searchables, {
        includeScore: true,
        ignoreLocation: true,
        keys: ["name", "content"],
      });

      const result = db
        .search(query, { limit: 20 })
        .filter(({ score }) => (isNumber(score) ? score < 0.1 : true));

      return result;
    },
});

export const useSearchResults = (query: string) =>
  useRecoilValue(searchResultsState(query));
