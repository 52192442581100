import { useRecoilCallback } from "recoil";
import { v4 as uuid4 } from "uuid";
import { useTrackAction } from "../observability";
import { getDocumentTitle } from "../utils/getDocumentTitle";
import { prepareDocumentsForImport } from "../utils/prepareDocumentsForImport";

import { documentsState } from "./core";
import { usePrompt } from "./prompt";

const API_ROOT =
  process.env.NODE_ENV === "production"
    ? "https://store.zapier.com/api"
    : "/api";

type SharingSuccess = {
  secret: string;
  status: "success";
};

type SharingFailure = {
  message: string;
  status: "failure";
};

export type SharingResult = SharingSuccess | SharingFailure | undefined;

export const useShare = () => {
  const prompt = usePrompt();

  return useRecoilCallback(
    ({ snapshot }) =>
      async (): Promise<SharingResult> => {
        const documents = await snapshot.getPromise(documentsState);

        const result = await prompt({
          message: "Choose the pages to share",
          okLabel: "Share selected",
          type: "select",
          items: documents.map((document) => ({
            label: getDocumentTitle(document),
            value: document.id,
          })),
        });

        console.log({ result });

        if (result.status !== "ok") {
          return;
        }

        const documentsToShare = documents.filter((document) =>
          result.value.includes(document.id)
        );

        const secret = uuid4();
        const url = `${API_ROOT}/records?secret=${secret}`;

        const headers = {
          "Content-Type": "application/json",
        };

        try {
          await fetch(url, {
            method: "POST",
            mode: "cors",
            headers,
            body: JSON.stringify({ documents: documentsToShare }),
          });

          return {
            status: "success",
            secret,
          };
        } catch (error: unknown) {
          if (error instanceof Error) {
            console.error(`Failed to share to ${url}`, { secret });
            console.error(error);

            return {
              status: "failure",
              message: error.message,
            };
          }

          return {
            status: "failure",
            message: `Unknown sharing error occurred: ${JSON.stringify(error)}`,
          };
        }
      },
    []
  );
};

export const useImportFromShare = () => {
  const trackAction = useTrackAction();

  return useRecoilCallback(
    ({ set }) =>
      async (secret: string) => {
        const headers = {};

        const url = `${API_ROOT}/records?secret=${secret}`;

        const result = await fetch(url, {
          mode: "cors",
          headers,
        });

        const { documents: documentsToImport } = await result.json();

        const importedDocuments = prepareDocumentsForImport(documentsToImport);

        set(documentsState, (documents) => [
          ...documents,
          ...importedDocuments,
        ]);

        trackAction("import:from-share");
      },
    [trackAction]
  );
};
