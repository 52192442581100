import { css } from "@emotion/react";
import { FC } from "react";
import { Colors } from "../../styles";
import colors from "../../styles/colors";

interface Props {
  error: Error | undefined;
}

const resultCss = css`
  margin: 0;
  padding: 5px;
  border-radius: 2px;
  border-top: 1px solid ${Colors.neutral300};
  background-color: ${colors.red[50]};
  color: ${colors.red[700]};
  width: 100%;
  overflow: auto;
  box-sizing: border-box;
`;

const ErrorDetails: FC<Props> = ({ error }) =>
  error ? <pre css={resultCss}>{error.message}</pre> : null;

export default ErrorDetails;
