import { get, toNumber } from "lodash";
import { BuiltIn } from "./types";

const builtIn: BuiltIn = {
  name: "productBy",
  description:
    "Use the `productBy` function to multiply the given property of all objects in an array",
  examples: ["productBy([{ val: 1 }, { val: 2 }, { val: 3 }], 'val')"],
  apply: (values: Record<string, unknown>[], propertyName: string) =>
    values.reduce((acc, obj) => acc * toNumber(get(obj, propertyName)), 1),
};

export default builtIn;
