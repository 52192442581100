import { css, Global } from "@emotion/react";
import { FC, Fragment } from "react";
import { RecoilRoot } from "recoil";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import DocumentRenderer from "./components/DocumentRenderer";
import NavBar from "./components/NavBar";
import Sidebar from "./components/Sidebar";
import { Colors, Shadows, transition, Typography } from "./styles";
import Prompt from "./components/Prompt";
import { useImportFromIntent } from "./state/routes";
import { DocView } from "./components/DocView";
import { UpgradePrompt } from "./components/UpgradePrompt";
import colors from "./styles/colors";
import { AirlyticsConnector } from "./observability";
import { Toasts } from "./toasts";
import { AwaitProvider } from "./components/AwaitContext";

const globalCss = css`
  body {
    width: 100%;
    height: 100vh;
    background-color: ${Colors.neutral100};
    font-family: ${Typography.fontFamily};
  }

  input[type="text"],
  input[type="number"],
  textarea,
  [data-slate-editor="true"] {
    font-family: ${Typography.fontFamily};
    position: relative;
    box-sizing: border-box;
    border: 1px solid ${Colors.transparent};
    box-shadow: ${Shadows.elevation0};

    transition: ${transition("box-shadow")};
    z-index: 1;

    &:focus {
      outline: none;
      box-shadow: 0 0 0px 2px ${Colors.active800};
    }

    &::placeholder {
      font-family: ${Typography.fontFamily};
    }
  }

  pre {
    margin: 0;
  }

  button > svg,
  a > svg {
    min-width: 14px;
    min-height: 14px;
  }

  a {
    text-decoration: none;
    color: ${Colors.active700};
  }

  ul,
  ol {
    margin: 0;
    padding-left: 20px;

    li {
      margin: 5px 0;
      font-size: ${Typography.baseFontSize};
    }
  }

  ul > li {
    list-style-type: "✓ ";
  }

  code {
    font-family: ${Typography.fontFamily};
    font-weight: bold;
    background-color: ${Colors.neutral200};
    padding: 1px;
    border-radius: 2px;
    padding: 0 3px;
  }

  pre {
    font-family: ${Typography.fontFamily};
  }

  del {
    color: ${colors.rose[400]};
    text-decoration: none;
    position: relative;
    margin: 0 5px;
  }

  del:after {
    content: " ";
    font-size: inherit;
    display: block;
    position: absolute;
    right: 0;
    left: 0;
    top: 50%;
    width: 120%;
    left: -10%;
    border-bottom: 1.5px solid ${colors.rose[400]};
    transform: rotate(5deg);
  }
`;

const containerCss = css`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100vh;
`;

const layoutCss = css`
  display: flex;
  align-items: stretch;
  width: 100%;
  height: 100%;
  overflow: hidden;
`;

const Initializer: FC = () => {
  useImportFromIntent();

  return null;
};

const App: FC = () => (
  <Fragment>
    <Global styles={globalCss} />

    <AwaitProvider>
      <RecoilRoot>
        <Initializer />
        <AirlyticsConnector />

        <DndProvider backend={HTML5Backend}>
          <div css={containerCss}>
            <NavBar />
            <div css={layoutCss}>
              <Sidebar />
              <DocumentRenderer />
              <DocView />
            </div>
          </div>

          <Prompt />
          <UpgradePrompt />
          <Toasts />
        </DndProvider>
      </RecoilRoot>
    </AwaitProvider>
  </Fragment>
);

export default App;
