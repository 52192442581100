import { css } from "@emotion/react";
import { FC, PropsWithChildren } from "react";
import { Colors, TypographicStyles } from "../../../styles";
import colors from "../../../styles/colors";
import { Anchor } from "./Anchor";
import * as headers from "./Header";
import * as lists from "./List";

const hrCss = css`
  border-top: none;
  border-bottom: 1px solid ${Colors.neutral300};
  margin: 30px 0;
`;

const HR: FC = () => <hr css={hrCss} />;

const codeCss = css`
  ${TypographicStyles.small}

  color: ${colors.sky[900]};
`;

const Code: FC<PropsWithChildren> = ({ children }) => (
  <code css={codeCss}>{children}</code>
);

const overrides = {
  h1: headers.H1,
  h2: headers.H2,
  h3: headers.H3,
  h4: headers.H4,
  h5: headers.H5,
  h6: headers.H6,
  hr: HR,
  code: Code,
  ul: lists.UL,
  ol: lists.OL,
  li: lists.LI,
  a: Anchor,
};

export { overrides };
