import { FC, PropsWithChildren } from "react";
import Text from "../../Text";

export const H1: FC<PropsWithChildren> = ({ children, ...props }) => (
  <Text tag="h1" type="title" size="16px" {...props}>
    {children}
  </Text>
);

export const H2: FC<PropsWithChildren> = ({ children, ...props }) => (
  <Text tag="h2" type="subTitle" size="14px" {...props}>
    {children}
  </Text>
);

export const H3: FC<PropsWithChildren> = ({ children, ...props }) => (
  <Text tag="h3" type="smallBold" size="12px" {...props}>
    {children}
  </Text>
);

export const H4: FC<PropsWithChildren> = ({ children, ...props }) => (
  <Text tag="h4" type="smallSemibold" {...props}>
    {children}
  </Text>
);

export const H5: FC<PropsWithChildren> = ({ children, ...props }) => (
  <Text tag="h5" type="fineBold" {...props}>
    {children}
  </Text>
);

export const H6: FC<PropsWithChildren> = ({ children, ...props }) => (
  <Text tag="h6" type="fineSemibold" {...props}>
    {children}
  </Text>
);
