import { css } from "@emotion/react";

import colors from "./colors";

const neutralBase = colors.stone;
const activeBase = colors.sky;
const brandBase = colors.amber;
const successBase = colors.emerald;
const infoBase = colors.blue;
const warningBase = colors.orange;
const dangerBase = colors.red;

export const Colors = {
  transparent: "transparent",
  white: "#fff",
  black: neutralBase[900],
  currentColor: "currentColor",

  neutral50: neutralBase[50],
  neutral100: neutralBase[100],
  neutral200: neutralBase[200],
  neutral300: neutralBase[300],
  neutral400: neutralBase[400],
  neutral500: neutralBase[500],
  neutral600: neutralBase[600],
  neutral700: neutralBase[700],
  neutral800: neutralBase[800],
  neutral900: neutralBase[900],

  active50: activeBase[50],
  active100: activeBase[100],
  active200: activeBase[200],
  active300: activeBase[300],
  active400: activeBase[400],
  active500: activeBase[500],
  active600: activeBase[600],
  active700: activeBase[700],
  active800: activeBase[800],
  active900: activeBase[900],

  success50: successBase[50],
  success100: successBase[100],
  success200: successBase[200],
  success300: successBase[300],
  success400: successBase[400],
  success500: successBase[500],
  success600: successBase[600],
  success700: successBase[700],
  success800: successBase[800],
  success900: successBase[900],

  info50: infoBase[50],
  info100: infoBase[100],
  info200: infoBase[200],
  info300: infoBase[300],
  info400: infoBase[400],
  info500: infoBase[500],
  info600: infoBase[600],
  info700: infoBase[700],
  info800: infoBase[800],
  info900: infoBase[900],

  warning50: warningBase[50],
  warning100: warningBase[100],
  warning200: warningBase[200],
  warning300: warningBase[300],
  warning400: warningBase[400],
  warning500: warningBase[500],
  warning600: warningBase[600],
  warning700: warningBase[700],
  warning800: warningBase[800],
  warning900: warningBase[900],

  danger50: dangerBase[50],
  danger100: dangerBase[100],
  danger200: dangerBase[200],
  danger300: dangerBase[300],
  danger400: dangerBase[400],
  danger500: dangerBase[500],
  danger600: dangerBase[600],
  danger700: dangerBase[700],
  danger800: dangerBase[800],
  danger900: dangerBase[900],

  brand: brandBase[400],
  brand50: brandBase[50],
  brand900: brandBase[900],
};

export const Shadows = {
  elevation0: "none",
  elevation2: "1px 1px 0px 0px rgba(0, 0, 0, 0.06)",
  elevation3: "2px 2px 0px 0px rgba(0, 0, 0, 0.06)",
  elevation5: "2px 2px 0px 0px rgba(0, 0, 0, 0.12)",
  elevation6: "3px 3px 0px 0px rgba(0, 0, 0, 0.18)",
  elevation10: "5px 5px 0px 0px rgba(0, 0, 0, 0.20)",
};

export const TypographicStyles = {
  title: css`
    font-weight: bold;
  `,

  subTitle: css`
    font-weight: 600;
  `,

  normal: css`
    font-size: 14px;
  `,

  small: css`
    font-size: 12px;
  `,

  smallBold: css`
    font-weight: bold;
    font-size: 12px;
  `,

  smallSemibold: css`
    font-weight: 600;
    font-size: 12px;
  `,

  fine: css`
    font-size: 11px;
  `,

  fineBold: css`
    font-weight: bold;
    font-size: 11px;
  `,

  fineSemibold: css`
    font-weight: 600;
    font-size: 11px;
  `,
};

export type TypographicStyleNames = keyof typeof TypographicStyles;

const USE_HANDWRITTEN_FONTS = false;

export const Typography = {
  fontFamily: USE_HANDWRITTEN_FONTS
    ? "'Nanum Pen Script', monospace"
    : "monospace",

  baseFontSize: "14px",
  smallFontSize: "12px",
  fineFontSize: "11px",

  semiboldFontWeight: 600,
};

export const transitionDuration = "200ms";
export const transitionTimingFunction = "ease-in-out";

export const transition = (...attrs: string[]) =>
  attrs
    .map((attr) => `${attr} ${transitionDuration} ${transitionTimingFunction}`)
    .join(", ");

export const baseButtonCss = css`
  background: ${Colors.transparent};
  border: 1px solid ${Colors.neutral300};
  box-sizing: border-box;
  box-shadow: ${Shadows.elevation5};
  color: ${Colors.neutral700};
  font-family: ${Typography.fontFamily};
  font-weight: 900;
  font-size: 12px;
  border-radius: 2px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px;

  transition: ${transition("box-shadow", "background-color")};

  &:hover {
    background-color: ${Colors.neutral200};
    box-shadow: ${Shadows.elevation6};
  }
`;

export const flatButtonCss = css`
  ${baseButtonCss}
  border-color: ${Colors.transparent};
  border-width: 1px;
  box-shadow: ${Shadows.elevation0};

  &:hover {
    background-color: ${Colors.neutral200};
  }
`;

export const primaryButtonCss = css`
  background-color: ${Colors.neutral600};
  color: ${Colors.white};

  &:hover {
    background-color: ${Colors.neutral700};
  }
`;

export const secondaryButtonCss = css`
  background-color: ${Colors.neutral100};
`;

export const disabledButtonCss = css`
  background-color: ${Colors.neutral100};
  color: ${Colors.neutral400};
  cursor: not-allowed;

  &:hover {
    background-color: ${Colors.neutral100};
  }
`;

export const linkButtonCss = css`
  font-size: ${Typography.smallFontSize};
  font-family: ${Typography.fontFamily};
  display: inline-flex;
  align-items: center;
  border: none;
  background-color: ${Colors.transparent};
  text-decoration: underline;
  color: ${Colors.active700};
  cursor: pointer;
  padding: 0;
`;

type FlexboxProps = {
  direction?: "row" | "column";
  justify?: "space-between" | "center" | "start" | "end";
  align?: "center";
  gap?: string;
};

export const flexboxCss = ({
  direction,
  justify,
  align,
  gap,
}: FlexboxProps) => [
  css`
    display: flex;
  `,
  direction &&
    css`
      flex-direction: ${direction};
    `,
  justify &&
    css`
      justify-content: ${justify};
    `,
  align &&
    css`
      align-items: ${align};
    `,
  gap &&
    css`
      gap: ${gap};
    `,
];

export const marginCss = (margin: string) => css`
  margin: ${margin};
`;

export const errorCss = css`
  background-color: ${colors.red[50]};
  color: ${colors.red[700]};
`;
