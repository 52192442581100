import { css } from "@emotion/react";
import { isEmpty } from "lodash";
import { FC } from "react";
import { Icon } from "@mdi/react";
import { mdiPlus } from "@mdi/js";
import { useActiveDocumentTitle, useElements } from "../state";
import AddElementButton from "./AddElementButton";
import DocumentTitle from "./DocumentTitle";
import ElementRenderer from "./ElementRenderer";
import { Colors } from "../styles";
import Text from "./Text";

interface Props {}

const containerCss = css`
  width: 100%;
  flex: 1 1 auto;
  overflow: auto;
`;

const scrollCss = css`
  padding: 40px;
  display: flex;
  max-width: 1080px;
  flex-direction: column;
  gap: 10px;
  margin: 0 auto;
`;

const emptyDocumentMessageCss = css`
  color: ${Colors.neutral500};
`;

const mockAddButtonCss = css`
  border: 1px solid ${Colors.neutral300};
`;

const EmptyDocumentMessage: FC = () => (
  <Text type="normal" css={emptyDocumentMessageCss}>
    <em>
      Looks like this page is empty. Click the{" "}
      <Icon
        path={mdiPlus}
        size={0.5}
        css={mockAddButtonCss}
        color={Colors.neutral400}
      />{" "}
      button to add an element.
    </em>
  </Text>
);

const DocumentRenderer: FC<Props> = () => {
  const elements = useElements();
  const title = useActiveDocumentTitle();

  return (
    <div css={containerCss}>
      <DocumentTitle title={`${title} • Calcula`} />

      <div css={scrollCss}>
        {isEmpty(elements) && <EmptyDocumentMessage />}
        <AddElementButton elementId={undefined} />

        {elements.map((element, index) => (
          <ElementRenderer element={element} index={index} key={element.id} />
        ))}
      </div>
    </div>
  );
};

export default DocumentRenderer;
