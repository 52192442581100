import { css } from "@emotion/react";
import { FC, PropsWithChildren } from "react";
import { Icon } from "@mdi/react";
import { mdiClose } from "@mdi/js";
import { baseButtonCss, Colors, Shadows, Typography } from "../styles";

interface Props {
  onClose: () => void;
  title: string;
}

const modalPositionerCss = css`
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 100000;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.25);
`;

const modalCss = css`
  position: relative;
  border-radius: 3px;
  background-color: ${Colors.neutral100};
  box-shadow: ${Shadows.elevation10};
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  width: 100%;
  margin: 20px;
`;

const closeButtonContainerCss = css`
  position: absolute;
  right: -10px;
  top: -10px;
  z-index: 100001;
`;

const buttonCss = css`
  ${baseButtonCss}
  padding: 5px;
  width: 100%;
  flex: 0 0 auto;
  box-sizing: border-box;
  background-color: ${Colors.neutral100};
  box-shadow: ${Shadows.elevation5};
`;

const headerContainerCss = css`
  padding: 5px;
  border-bottom: 1px solid ${Colors.neutral300};
  font-size: ${Typography.fineFontSize};
  color: ${Colors.neutral700};
`;

const ExpandedElementModal: FC<PropsWithChildren<Props>> = ({
  onClose,
  title,
  children,
}) => {
  return (
    <div css={modalPositionerCss}>
      <div css={modalCss}>
        <div css={headerContainerCss}>
          {title}
          <div css={closeButtonContainerCss}>
            <button type="button" css={buttonCss} onClick={onClose}>
              <Icon path={mdiClose} size={0.7} color={Colors.neutral600} />
            </button>
          </div>
        </div>
        {children}
      </div>
    </div>
  );
};

export default ExpandedElementModal;
