import { css } from "@emotion/react";
import { useElement } from "../state";
import { Typography } from "../styles";
import colors from "../styles/colors";
import { ElementComponent } from "./types";

const containerCss = css``;

const headerCss = css`
  background-color: ${colors.orange[50]};
  color: ${colors.orange[800]};
  padding: 10px;
  border-bottom: 1px solid ${colors.orange[200]};
  font-size: ${Typography.smallFontSize};
`;

const valueCss = css`
  padding: 10px;
`;

const UnknownElement: ElementComponent = ({ elementId }) => {
  const element = useElement(elementId);
  return (
    <div css={containerCss}>
      <div css={headerCss}>Unknown element kind: {element.kind}</div>
      <pre css={valueCss}>{JSON.stringify(element, null, 2)}</pre>
    </div>
  );
};

export default UnknownElement;
