import { css } from "@emotion/react";
import { isEmpty } from "lodash";
import { ChangeEvent, FC, useCallback, useEffect, useRef } from "react";
import {
  useSearchOpenState,
  useSearchQueryState,
  useSelectDocument,
} from "../../state";
import { useSearchResults } from "../../state/search";
import { Colors, Shadows } from "../../styles";
import { SearchResult } from "../../types";
import { searchInputCss } from "../Form";
import DocumentSearchResult from "./DocumentSearchResult";

const containerCss = css`
  position: relative;
  width: 100%;
`;

const resultsCss = css`
  position: absolute;
  width: 100%;
  display: flex;
  flex-direction: column;
  top: calc(100% + 3px);
  min-height: 100%;
  max-height: 400px;
  overflow-y: auto;
  overflow-x: hidden;
  z-index: 1000;
  background-color: ${Colors.white};
  border: 1px solid ${Colors.neutral400};
  border-radius: 3px;
  box-sizing: border-box;
  padding: 10px;
  box-shadow: ${Shadows.elevation10};
`;

const DocumentSearch: FC = () => {
  const inputRef = useRef<HTMLInputElement>(null);

  const [query, setQuery] = useSearchQueryState();
  const onQueryChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      setQuery(e.target.value);
    },
    [setQuery]
  );

  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.focus();
      inputRef.current.select();
    }
  }, []);

  const [, setSearchOpen] = useSearchOpenState();
  const onBlur = useCallback(() => {
    setSearchOpen(false);
  }, [setSearchOpen]);

  const results = useSearchResults(query);

  const containerRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    const listener = (e: Event) => {
      if (!containerRef.current?.contains(e.target as Node)) {
        onBlur();
      }
    };

    document.body.addEventListener("focus", listener);
    document.body.addEventListener("mousedown", listener);

    return () => {
      document.body.removeEventListener("focus", listener);
      document.body.removeEventListener("mousedown", listener);
    };
  }, [onBlur]);

  const onSelectDocument = useSelectDocument();
  const onOpen = useCallback(
    (result: SearchResult) => {
      setSearchOpen(false);
      onSelectDocument(result.item.documentId);
      window.location.hash = result.item.id;
    },
    [setSearchOpen, onSelectDocument]
  );

  return (
    <div ref={containerRef} css={containerCss}>
      <input
        ref={inputRef}
        type="text"
        css={searchInputCss}
        placeholder="Start typing to search all pages…"
        onChange={onQueryChange}
        value={query}
      />
      {!isEmpty(query) && (
        <div css={resultsCss} role="menu">
          {isEmpty(results) && <em>No results found</em>}
          {results.map((result) => (
            <DocumentSearchResult
              result={result}
              key={result.item.id}
              onOpen={onOpen}
            />
          ))}
        </div>
      )}
    </div>
  );
};

export default DocumentSearch;
