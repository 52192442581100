import { v4 as uuid4 } from "uuid";
import { Document } from "../types";
import { EXAMPLE_LABELS } from "./constants";

interface Options {
  labels?: string[];
}

const DEFAULT_OPTIONS: Options = {
  labels: EXAMPLE_LABELS,
};

export const prepareExampleDocument = (
  document: Document,
  options: Options = DEFAULT_OPTIONS
): Document => ({
  ...document,
  labels: options.labels ?? DEFAULT_OPTIONS.labels,
  id: uuid4(),
  elements: document.elements.map((element) => ({
    ...element,
    id: uuid4(),
  })),
});
