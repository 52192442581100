import { css } from "@emotion/react";
import { isEmpty, take } from "lodash";
import { ChangeEvent, FC, memo, useCallback, useState } from "react";
import InfiniteScroll from "react-infinite-scroller";
import {
  useBuiltInSuggestions,
  usePreviousElementSuggestions,
} from "../../state";
import { Colors, Shadows, transition } from "../../styles";
import { BuiltInSuggestion, ElementSuggestion } from "../../types";
import { searchInputCss } from "../Form";
import Text from "../Text";
import { useDispatcher } from "./EditorController";

interface Props {
  elementId: string;
}

const containerCss = css`
  position: relative;
  width: 100%;
`;

const overlayCss = css`
  position: absolute;
  display: flex;
  flex-direction: column;
  top: 3px;
  width: 100%;
  min-height: 100%;
  max-height: 500px;
  overflow-y: auto;
  overflow-x: hidden;
  z-index: 1000;
  background-color: ${Colors.white};
  border: 1px solid ${Colors.neutral400};
  border-radius: 3px;
  box-sizing: border-box;
  padding: 10px;
  box-shadow: ${Shadows.elevation10};
`;

const filterInputCss = css`
  ${searchInputCss}

  margin-bottom: 10px;
`;

const suggestionCss = css`
  display: flex;
  align-items: baseline;
  justify-content: space-between;
  gap: 10px;
  cursor: pointer;
  padding: 10px;
  box-sizing: border-box;
  border-radius: 3px;
  background-color: ${Colors.transparent};

  transition: ${transition("background-color")};

  &:hover {
    background-color: ${Colors.neutral200};
  }
`;

interface EditorSuggestionProps {
  label: string;
  value: string;
}

const DEFAULT_SUGGESTIONS_COUNT = 25;

const EditorSuggestion: FC<EditorSuggestionProps> = memo(({ label, value }) => {
  const { onInsert } = useDispatcher();

  const onClick = useCallback(() => {
    onInsert(value);
  }, [value, onInsert]);

  return (
    <div css={suggestionCss} role="button" tabIndex={0} onClick={onClick}>
      <Text type="normal">{label}</Text>
      <Text type="small" align="right" monospace color={Colors.neutral600}>
        {value}
      </Text>
    </div>
  );
});

const createFilterFn =
  (query: string) => (suggestion: ElementSuggestion | BuiltInSuggestion) => {
    const safeQuery = query.toLowerCase();

    if (suggestion.name.toLowerCase().includes(safeQuery)) {
      return true;
    }
  };

export const EditorSuggestions: FC<Props> = ({ elementId }) => {
  const suggestions = usePreviousElementSuggestions(elementId);
  const builtInSuggestions = useBuiltInSuggestions(elementId);

  const [filter, setFilter] = useState("");

  const onChangeFilter = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    setFilter(e.target.value);
  }, []);

  const filteredSuggestions = suggestions.filter(createFilterFn(filter));
  const filteredBuiltInSuggestions = builtInSuggestions.filter(
    createFilterFn(filter)
  );

  const [suggestionsLimit, setSuggestionsLimit] = useState(
    DEFAULT_SUGGESTIONS_COUNT
  );
  const onShowMoreSuggestions = useCallback(() => {
    setSuggestionsLimit((limit) => limit + 25);
  }, []);

  const hasMoreSuggestions =
    filteredBuiltInSuggestions.length > suggestionsLimit;

  const filteredBuiltInSuggestionsToRender = take(
    filteredBuiltInSuggestions,
    suggestionsLimit
  );

  return (
    <div css={containerCss}>
      <div css={overlayCss}>
        <InfiniteScroll
          hasMore={hasMoreSuggestions}
          loadMore={onShowMoreSuggestions}
          useWindow={false}
        >
          <input
            type="text"
            css={filterInputCss}
            placeholder="Start typing to filter…"
            value={filter}
            onChange={onChangeFilter}
          />

          <Text type="smallSemibold" color={Colors.neutral500}>
            Previous element values
          </Text>
          {filteredSuggestions.map((suggestion) => (
            <EditorSuggestion
              key={suggestion.id}
              label={`${suggestion.num}. ${suggestion.name}`}
              value={suggestion.slug}
            />
          ))}
          {isEmpty(suggestions) && (
            <Text color={Colors.neutral500} margin="10px">
              <em>There are no previous elements.</em>
            </Text>
          )}
          {isEmpty(filteredSuggestions) && (
            <Text color={Colors.neutral500} margin="10px">
              <em>There are no previous elements matching "{filter}".</em>
            </Text>
          )}

          <Text
            type="smallSemibold"
            color={Colors.neutral500}
            margin="10px 0 0 0"
          >
            Built-in functions
          </Text>

          {filteredBuiltInSuggestionsToRender.map((suggestion) => (
            <EditorSuggestion
              key={suggestion.id}
              label={suggestion.name}
              value={suggestion.slug}
            />
          ))}
          {isEmpty(filteredBuiltInSuggestions) && (
            <Text color={Colors.neutral500} margin="10px">
              <em>There are no built-ins matching "{filter}".</em>
            </Text>
          )}
        </InfiniteScroll>
      </div>
    </div>
  );
};
