import { css } from "@emotion/react";
import { FC, ReactNode, useCallback, useState } from "react";
import { Colors, Shadows } from "../../styles";

type TooltipPosition = "east" | "west";

interface TooltipProps {
  content: ReactNode;
  position?: TooltipPosition;
}

const tooltipCss = (position: TooltipPosition = "west") => [
  css`
    position: absolute;
    background-color: ${Colors.neutral900};
    color: ${Colors.neutral100};
    border-radius: 2px;
    padding: 4px 5px;
    box-shadow: ${Shadows.elevation6};
    white-space: nowrap;
    z-index: 100;
  `,
  position === "west" &&
    css`
      right: 7px;
      margin-right: 100%;
    `,
  position === "east" &&
    css`
      left: 7px;
      margin-left: 100%;
    `,
];

export const Tooltip: FC<TooltipProps> = ({ content, position }) => (
  <div css={tooltipCss(position)}>{content}</div>
);

interface TooltipWrapperChildAttrs {}

interface TooltipWrapperProps {
  children: (childAttrs: TooltipWrapperChildAttrs) => ReactNode;
  content: ReactNode;
  position?: TooltipPosition;
}

const tooltipContainerCss = css`
  position: relative;
`;

export const TooltipWrapper: FC<TooltipWrapperProps> = ({
  children,
  content,
  position,
}) => {
  const [isHover, setHover] = useState(false);

  const onMouseEnter = useCallback(() => {
    setHover(true);
  }, []);

  const onMouseLeave = useCallback(() => {
    setHover(false);
  }, []);

  return (
    <div
      css={tooltipContainerCss}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
    >
      {isHover && <Tooltip content={content} position={position} />}
      {children({})}
    </div>
  );
};
