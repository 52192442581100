import { DEFAULT_ELEMENT_NAME } from "../state";
import { Document, Element, Searchable } from "../types";
import { getDocumentTitle } from "./getDocumentTitle";
import { isCodeElement } from "./isCodeElement";
import { isCsvElement } from "./isCsvElement";
import { isFormulaElement } from "./isFormulaElement";
import { isJsonElement } from "./isJsonElement";
import { isTableElement } from "./isTableElement";
import { isTextElement } from "./isTextElement";

export const toSearchable = (
  element: Element,
  document: Document
): Searchable => {
  const { id } = element;
  const name = element.name ?? DEFAULT_ELEMENT_NAME;

  const common = {
    id,
    name,
    documentId: document.id,
    documentTitle: getDocumentTitle(document),
  };

  if (isTextElement(element)) {
    return {
      ...common,
      content: element.content,
    };
  }

  if (isCodeElement(element)) {
    return {
      ...common,
      content: element.code,
    };
  }

  if (isTableElement(element)) {
    return {
      ...common,
      content: JSON.stringify(element.data, undefined, 2),
    };
  }

  if (isCsvElement(element)) {
    return {
      ...common,
      content: element.data,
    };
  }

  if (isJsonElement(element)) {
    return {
      ...common,
      content: element.data,
    };
  }

  if (isFormulaElement(element)) {
    return {
      ...common,
      content: element.formula,
    };
  }

  throw new Error(`Unable to convert ${element.kind} to a searchable record`);
};
