import { Node } from "slate";

export const serialize = (value: Node[]) =>
  value
    // Return the string content of each paragraph in the value's children.
    .map((n) => Node.string(n))
    // Join them all with line breaks denoting paragraphs.
    .join("\n");

export const deserialize = (value: string) =>
  // Return a value array of children derived by splitting the string.
  value.split("\n").map((line) => {
    return {
      children: [{ text: line }],
    };
  });
