import {
  DefaultValue,
  selectorFamily,
  useRecoilState,
  useRecoilValue,
} from "recoil";
import { TextElement } from "../../types";
import { elementByIdState } from "../../state";
import { isTextElement } from "../../utils/isTextElement";

export const textElementByIdState = selectorFamily<TextElement, string>({
  key: "document/textElementById",
  get:
    (elementId) =>
    ({ get }) => {
      const element = get(elementByIdState(elementId));

      if (!isTextElement(element)) {
        throw new Error(`Element kind is not text element "${elementId}"`);
      }

      return element;
    },

  set:
    (elementId) =>
    ({ set }, update) => {
      if (update instanceof DefaultValue) {
        return;
      }

      set(elementByIdState(elementId), update);
    },
});

export const useTextElement = (elementId: string) =>
  useRecoilValue(textElementByIdState(elementId));
export const useTextElementState = (elementId: string) =>
  useRecoilState(textElementByIdState(elementId));
