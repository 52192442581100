import { css } from "@emotion/react";
import { mdiClose, mdiLock } from "@mdi/js";
import Icon from "@mdi/react";
import { FC, useCallback, useEffect } from "react";
import { UPGRADE_URL } from "../../feature-flags";
import { useTrackAction } from "../../observability";
import { useDismissUpgradePrompt, useIsPromptToUpgradeOpen } from "../../state";
import {
  baseButtonCss,
  Colors,
  flexboxCss,
  primaryButtonCss,
} from "../../styles";
import {
  modalBodyCss,
  modalCloseButtonContainerCss,
  modalCloseButtonCss,
  modalCss,
  modalHeaderContainerCss,
  modalPositionerCss,
} from "../Modal";
import Text from "../Text";

const upgradeButtonCss = css`
  ${baseButtonCss}
  ${primaryButtonCss}
`;

export const UpgradePrompt: FC = () => {
  const isOpen = useIsPromptToUpgradeOpen();
  const onClose = useDismissUpgradePrompt();
  const trackAction = useTrackAction();

  const onUpgrade = useCallback(() => {
    trackAction("upgrade:open-upgrade-url");
    window.open(UPGRADE_URL);
    onClose();
  }, [onClose, trackAction]);

  useEffect(() => {
    if (isOpen) {
      trackAction("upgrade:view-prompt");
    }
  }, [isOpen, trackAction]);

  if (!isOpen) {
    return null;
  }

  return (
    <div css={modalPositionerCss}>
      <div css={modalCss}>
        <div css={modalHeaderContainerCss}>
          <div>Upgrade to PRO</div>
          <div css={modalCloseButtonContainerCss}>
            <button type="button" css={modalCloseButtonCss} onClick={onClose}>
              <Icon path={mdiClose} size={0.7} color={Colors.neutral600} />
            </button>
          </div>
        </div>

        <div
          css={[modalBodyCss, flexboxCss({ direction: "column", gap: "20px" })]}
        >
          <Text type="subTitle">Upgrade to Calcula PRO</Text>

          <Text>
            <ul>
              <li>
                Available as a standalone app for macOS, Windows, and Linux
              </li>
              <li>Pay once, use forever</li>
              <li>Import and export notebooks</li>
              <li>
                Control where your notes are stored on disk
                <br />
                <Text tag="span" type="small" color={Colors.neutral600}>
                  You choose how your notes will be sync'ed and backed up.
                </Text>
              </li>
              <li>License covers two computers</li>
              <li>
                Support for importing <code>npm</code> packages with{" "}
                <code>require()</code>
              </li>
            </ul>
          </Text>

          <Text type="subTitle">
            Upgrade while Calcula is in BETA and receive a{" "}
            <u style={{ textTransform: "uppercase" }}>
              66% early adopters discount
            </u>
            !
          </Text>

          <button type="button" css={upgradeButtonCss} onClick={onUpgrade}>
            Upgrade for <del>$14.99</del>$4.99
          </button>

          <div
            css={flexboxCss({ align: "center", justify: "center", gap: "5px" })}
          >
            <Icon path={mdiLock} size={0.7} color={Colors.neutral600} />
            <Text type="fineBold" color={Colors.neutral600}>
              Payments processed securely by Gumroad. Local taxes may apply.
            </Text>
          </div>
        </div>
      </div>
    </div>
  );
};
