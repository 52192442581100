import { useRecoilCallback } from "recoil";
import { useShowToast } from "../../toasts";
import { jsonElementByIdState } from "./state";

export const useImportJsonData = (elementId: string) => {
  const showToast = useShowToast();

  return useRecoilCallback(
    ({ set }) =>
      async (data: string) => {
        if (data) {
          set(jsonElementByIdState(elementId), (element) => ({
            ...element,
            data,
          }));

          try {
            JSON.parse(data);

            showToast({
              level: "success",
              message: "Successfully imported JSON data",
            });
          } catch {
            showToast({
              level: "warning",
              message: "Imported JSON contains errors",
            });
          }
        }
      },
    [elementId, showToast]
  );
};
