import { PersistStorage } from "recoil-persist";
import { getState, setState } from "../ipc";

export const storage: PersistStorage = {
  getItem(key) {
    return getState(key);
  },

  setItem(key, value) {
    setState(key, value);
  },
};
