import { toNumber } from "lodash";
import { BuiltIn } from "./types";

const builtIn: BuiltIn = {
  name: "toNumber",
  description: "Use the `toNumber` function to coerce values to a number",
  examples: ["toNumber('3.2')"],
  apply: toNumber,
};

export default builtIn;
