import { css } from "@emotion/react";
import { mdiPlus } from "@mdi/js";
import Icon from "@mdi/react";
import { FC } from "react";
import { flatButtonCss } from "../../../styles";

interface Props {
  onAddColumn: () => void;
}

const containerCss = css`
  padding: 3px 3px 0 1px;
  box-sizing: border-box;
  height: 100%;
`;

const addColumnButtonCss = css`
  ${flatButtonCss}

  padding: 5px;
  width: 100%;
  height: 100%;

  &:hover {
    box-shadow: none;
  }
`;

export const AddColumnButton: FC<Props> = ({ onAddColumn }) => (
  <div css={containerCss}>
    <button css={addColumnButtonCss} onClick={onAddColumn}>
      <Icon path={mdiPlus} size={0.7} />
    </button>
  </div>
);
