import { css } from "@emotion/react";
import { mdiImport } from "@mdi/js";
import { isString } from "lodash";
import { Fragment, useCallback, useEffect, useState } from "react";
import { isFeatureEnabled, isFeatureUpgradable } from "../../../feature-flags";
import { useTrackAction } from "../../../observability";
import { useElementError, usePromptToUpgrade } from "../../../state";
import { useShowToast } from "../../../toasts";
import ErrorDetails from "../../../components/ErrorDetails";
import { IsomorphicPickFileMenuItem, MenuItem } from "../../../components/Menu";
import {
  ElementActionsComponent,
  ElementComponent,
} from "../../../components/types";
import JsonEditor from "./JsonEditor";
import { useJsonElementState } from "../state";
import { useImportJsonData } from "../import";

const containerCss = css``;

const JsonElement: ElementComponent = ({ elementId }) => {
  const [element, setElement] = useJsonElementState(elementId);

  const [content, setContent] = useState(element.data);
  const [hasChanged, setHasChanged] = useState(false);

  const maybeError = useElementError(elementId);

  const trackAction = useTrackAction();

  const onBlur = () => {
    if (hasChanged) {
      setElement((s) => ({ ...s, data: content }));
      trackAction("element:edit:json");
    }

    setHasChanged(false);
  };

  const onChange = (value: string) => {
    setContent(value);
    setHasChanged(true);
  };

  const [slateKey, setSlateKey] = useState(Date.now());

  useEffect(() => {
    setContent(element.data);
    setSlateKey(Date.now());
  }, [element.data]);

  return (
    <div css={containerCss}>
      <JsonEditor
        key={slateKey}
        placeholder="Enter JSON here..."
        initialValue={content}
        onChange={onChange}
        onBlur={onBlur}
      />
      <ErrorDetails error={maybeError} />
    </div>
  );
};

const MenuItems: ElementActionsComponent = ({ elementId, closeMenu }) => {
  const importData = useImportJsonData(elementId);
  const promptToUpgrade = usePromptToUpgrade();
  const showToast = useShowToast();

  const onImportData = useCallback(
    async (_filename: string, data: unknown) => {
      closeMenu();

      if (isString(data)) {
        importData(data);
      } else {
        console.error(`Unsupported format`, typeof data);
        showToast({ message: "Unsupported data format", level: "danger" });
      }
    },
    [importData, closeMenu, showToast]
  );

  const onPromptToUpgrade = useCallback(() => {
    closeMenu();
    promptToUpgrade("upgrade:import:json");
  }, [closeMenu, promptToUpgrade]);

  return (
    <Fragment>
      {isFeatureEnabled("filesystem:import") ? (
        <IsomorphicPickFileMenuItem
          label="Import JSON data"
          icon={mdiImport}
          extensions={["json"]}
          onLoaded={onImportData}
        />
      ) : (
        <MenuItem
          label="Import JSON data"
          icon={mdiImport}
          onClick={onPromptToUpgrade}
          isUpgrade={isFeatureUpgradable("filesystem:import")}
        />
      )}
    </Fragment>
  );
};

JsonElement.MenuItems = MenuItems;

export default JsonElement;
