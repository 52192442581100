import {
  AIRLYTICS_ENDPOINT,
  AIRLYTICS_PID,
  TRACKING_ENABLED,
} from "./constants";

type Options = {
  useBeacon?: boolean;
};

export async function trackPageView(
  trackingId: string,
  pathname: string,
  { useBeacon }: Options = {}
) {
  const action = "site:load";

  if (TRACKING_ENABLED) {
    const url = `${AIRLYTICS_ENDPOINT}?pid=${AIRLYTICS_PID}&sid=${trackingId}&pathname=${pathname}&action=${action}`;
    if (useBeacon) {
      navigator.sendBeacon(url);
    } else {
      await fetch(url);
    }
  } else {
    console.info(`Would have tracked page action`, {
      trackingId,
      pathname,
      action,
      useBeacon,
    });
  }
}

export async function trackPageAction(
  trackingId: string,
  pathname: string,
  action: string,
  { useBeacon }: Options = {}
) {
  if (TRACKING_ENABLED) {
    const url = `${AIRLYTICS_ENDPOINT}?pid=${AIRLYTICS_PID}&sid=${trackingId}&pathname=${pathname}&action=${action}`;
    if (useBeacon) {
      navigator.sendBeacon(url);
    } else {
      await fetch(url);
    }
  } else {
    console.info(`Would have tracked page action`, {
      trackingId,
      pathname,
      action,
      useBeacon,
    });
  }
}
