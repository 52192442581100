import {
  FocusEvent,
  KeyboardEvent,
  Suspense,
  useCallback,
  useRef,
  useState,
} from "react";
import { css } from "@emotion/react";
import { useFormulaElementState } from "../state";
import { ElementComponent } from "../../../components/types";
import FormulaResult from "./FormulaResult";
import { EvalResultLoading } from "../../../components/EvalResultDisplay";
import Editor, {
  EditorController,
  EditorSuggestions,
} from "../../../components/Editor";
import { eventLoop } from "../../../utils/eventLoop";
import { useTrackAction } from "../../../observability";

const containerCss = css``;

const FormulaElement: ElementComponent = ({ elementId }) => {
  const [element, setElement] = useFormulaElementState(elementId);
  const [formula, setFormula] = useState(element.formula);

  const containerRef = useRef<HTMLDivElement>(null);
  const [areSuggestionsVisible, setAreSuggestionsVisible] = useState(false);
  const [isFocused, setFocused] = useState(false);

  const trackAction = useTrackAction();

  const onFocus = useCallback(() => {
    setAreSuggestionsVisible(true);
    setFocused(true);
  }, []);

  const onCommitChanges = useCallback(() => {
    if (element.formula !== formula) {
      setElement((s) => ({ ...s, formula }));

      trackAction("element:edit:formula");
    }
  }, [setElement, element.formula, formula, trackAction]);

  const onBlur = useCallback(
    (e: FocusEvent) => {
      if (!containerRef.current?.contains(e.relatedTarget)) {
        setAreSuggestionsVisible(false);
        setFocused(false);

        onCommitChanges();
      }
    },
    [onCommitChanges]
  );

  const onChange = useCallback(async (value: string) => {
    await eventLoop();
    setFormula(value);
  }, []);

  const onKeyDown = useCallback(
    (e: KeyboardEvent<HTMLDivElement>) => {
      if ((e.metaKey || e.ctrlKey) && e.key === "Enter") {
        onCommitChanges();
      }
    },
    [onCommitChanges]
  );

  return (
    <EditorController>
      <div
        ref={containerRef}
        css={containerCss}
        onBlur={onBlur}
        onKeyDown={onKeyDown}
      >
        <Editor
          language="formula"
          placeholder="Enter formula here..."
          initialValue={formula}
          onChange={onChange}
          onFocus={onFocus}
        />

        <Suspense fallback={<EvalResultLoading />}>
          <FormulaResult elementId={elementId} isFocused={isFocused} />
        </Suspense>

        {areSuggestionsVisible && <EditorSuggestions elementId={elementId} />}
      </div>
    </EditorController>
  );
};

export default FormulaElement;
