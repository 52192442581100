import { css } from "@emotion/react";
import { FC } from "react";
import { Colors, TypographicStyles } from "../../styles";

const containerCss = css`
  ${TypographicStyles.small}

  position: absolute;
  bottom: 2px;
  right: 15px;

  padding: 2px;
  color: ${Colors.neutral600};
  border-radius: 2px;
  z-index: 100;
`;

export const RecalculateHotKeyHint: FC = () => (
  <div css={containerCss}>Cmd + Enter to instantly re-evaluate</div>
);
