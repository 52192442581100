import { PersistStorage, recoilPersist } from "recoil-persist";
import { isFeatureEnabled } from "../feature-flags/isFeatureEnabled";

// By default localStorage is used for persistence
let storage: PersistStorage = localStorage;

// If the "filesystem" feature is enabled, then
// dynamically import filesystem storage support.
if (isFeatureEnabled("filesystem")) {
  storage = require("./storage-filesystem").storage;
}

export const { persistAtom } = recoilPersist({ key: "calcula", storage });
