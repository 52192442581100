import {
  DefaultValue,
  selectorFamily,
  useRecoilState,
  useRecoilValue,
} from "recoil";
import { JsonElement } from "../../types";
import { elementByIdState } from "../../state";
import { isJsonElement } from "../../utils/isJsonElement";

export const jsonElementByIdState = selectorFamily<JsonElement, string>({
  key: "document/jsonElementById",
  get:
    (elementId) =>
    ({ get }) => {
      const element = get(elementByIdState(elementId));

      if (!isJsonElement(element)) {
        throw new Error(`Element kind is not json element "${elementId}"`);
      }

      return element;
    },

  set:
    (elementId) =>
    ({ set }, update) => {
      if (update instanceof DefaultValue) {
        return;
      }

      set(elementByIdState(elementId), update);
    },
});

export const useJsonElement = (elementId: string) =>
  useRecoilValue(jsonElementByIdState(elementId));
export const useJsonElementState = (elementId: string) =>
  useRecoilState(jsonElementByIdState(elementId));
