import { css } from "@emotion/react";
import {
  baseButtonCss,
  Colors,
  disabledButtonCss,
  primaryButtonCss,
  transition,
  Typography,
} from "../styles";

export const formFieldLabelCss = css`
  font-size: ${Typography.smallFontSize};
  font-weight: ${Typography.semiboldFontWeight};
  margin: 0;
  padding: 0;
`;

export const formFieldCss = css`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  gap: 5px;
  min-width: 420px;
  margin-bottom: 20px;

  & > label {
    ${formFieldLabelCss}
  }

  & > input,
  & > select,
  & > textarea,
  & > [data-slate-editor="true"] {
    padding: 5px;
    border-radius: 3px;
    background-color: ${Colors.white};
    box-shadow: none;
    border: 1px solid ${Colors.neutral300};
    resize: vertical;

    transition: ${transition("border")};

    &:focus {
      border: 1px solid ${Colors.neutral400};
    }
  }

  & > [data-has-field-border="true"] {
    border-radius: 3px;
    border: 1px solid ${Colors.neutral300};
  }
`;

export const formFieldHintCss = (isVisible: boolean) =>
  isVisible
    ? css`
        color: ${Colors.neutral600};
        font-size: ${Typography.smallFontSize};
      `
    : css`
        display: none;
      `;

export const formButtonsCss = css`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 10px;

  & > button[type="submit"] {
    ${baseButtonCss}
    ${primaryButtonCss}
  }

  & > button[disabled] {
    ${disabledButtonCss}
  }
`;

export const searchInputCss = css`
  padding: 5px;
  border-radius: 2px;
  width: 100%;

  /* Override the default */
  &[type="text"] {
    box-shadow: 0 0 0px 2px ${Colors.neutral500};
  }
`;
