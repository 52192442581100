import { assign, fromPairs, isFunction, omit } from "lodash";
import {
  DefaultValue,
  selectorFamily,
  useRecoilCallback,
  useRecoilState,
  useRecoilValue,
} from "recoil";
import { trackActionCallback } from "../../observability/trackActionCallback";
import {
  TableColumn,
  TableElement,
  TableRow,
  EvalResult,
  ComputedTableRow,
  TableColumnAlignment,
} from "../../types";
import evalCode from "../../utils/evalCode";
import evalFormula from "../../utils/evalFormula";
import { handleEvalError } from "../../utils/handleEvalError";
import { isFormulaColumnPreview } from "../../utils/isFormulaColumnPreview";
import { isFunctionColumnPreview } from "../../utils/isFunctionColumnPreview";
import { isTableElement } from "../../utils/isTableElement";
import { omitTableRowIndex } from "../../utils/omitTableRowIndex";
import { elementByIdState } from "../../state/core";
import {
  evalContextByIdState,
  subsequentElementCamelCaseNamesState,
} from "../../state/eval";
import {
  EvalContext,
  ColumnEvalContext,
  TableColumnKey,
} from "../../state/types";
import { getTableColumnAlignment } from "../../utils/getTableColumnAlignment";

type TableRowsUpdate = TableRow[];

type TableRowsUpdater = (rows: TableRow[]) => TableRowsUpdate;

export const tableElementByIdState = selectorFamily<TableElement, string>({
  key: "document/tableElementById",
  get:
    (elementId) =>
    ({ get }) => {
      const element = get(elementByIdState(elementId));

      if (!isTableElement(element)) {
        throw new Error(`Element kind is not table element "${elementId}"`);
      }

      return element;
    },

  set:
    (elementId) =>
    ({ set }, update) => {
      if (update instanceof DefaultValue) {
        return;
      }

      set(elementByIdState(elementId), update);
    },
});

export const useTableElement = (elementId: string) =>
  useRecoilValue(tableElementByIdState(elementId));
export const useTableElementState = (elementId: string) =>
  useRecoilState(tableElementByIdState(elementId));

export const useUpdateTableRows = (elementId: string) =>
  useRecoilCallback(
    ({ set, snapshot }) =>
      async (updateOrUpdater: TableRowsUpdate | TableRowsUpdater) => {
        if (isFunction(updateOrUpdater)) {
          set(tableElementByIdState(elementId), (element) =>
            assign({}, element, { data: updateOrUpdater(element.data) })
          );
        } else {
          set(tableElementByIdState(elementId), (element) =>
            assign({}, element, { data: updateOrUpdater })
          );
        }

        const trackAction = await snapshot.getPromise(trackActionCallback);
        trackAction("element:edit:table:update-rows");
      },
    [elementId]
  );

export const useInsertTableRowAbove = (elementId: string) =>
  useRecoilCallback(
    ({ set, snapshot }) =>
      async (rowIndex: number) => {
        set(
          tableElementByIdState(elementId),
          (element: TableElement) =>
            ({
              ...element,
              data: element.data.flatMap((row, idx) => {
                if (idx === rowIndex) {
                  return [{}, row] as const;
                }
                return row;
              }),
            } as TableElement)
        );

        const trackAction = await snapshot.getPromise(trackActionCallback);
        trackAction("element:edit:table:insert-row-above");
      },
    [elementId]
  );

export const useInsertTableRowBelow = (elementId: string) =>
  useRecoilCallback(
    ({ set, snapshot }) =>
      async (rowIndex: number) => {
        set(
          tableElementByIdState(elementId),
          (element: TableElement) =>
            ({
              ...element,
              data: element.data.flatMap((row, idx) => {
                if (idx === rowIndex) {
                  return [row, {}] as const;
                }
                return row;
              }),
            } as TableElement)
        );

        const trackAction = await snapshot.getPromise(trackActionCallback);
        trackAction("element:edit:table:insert-row-below");
      },
    [elementId]
  );

export const useAppendTableRow = (elementId: string) =>
  useRecoilCallback(
    ({ set, snapshot }) =>
      async () => {
        set(
          tableElementByIdState(elementId),
          (element: TableElement) =>
            ({
              ...element,
              data: [...element.data, {}],
            } as TableElement)
        );

        const trackAction = await snapshot.getPromise(trackActionCallback);
        trackAction("element:edit:table:append-row");
      },
    [elementId]
  );

export const useDeleteTableRow = (elementId: string) =>
  useRecoilCallback(
    ({ set, snapshot }) =>
      async (rowIndex: number) => {
        set(
          tableElementByIdState(elementId),
          (element: TableElement) =>
            ({
              ...element,
              data: element.data.filter((_row, idx) => idx !== rowIndex),
            } as TableElement)
        );

        const trackAction = await snapshot.getPromise(trackActionCallback);
        trackAction("element:edit:table:delete-row");
      },
    [elementId]
  );

export const useInsertTableColumnBefore = (elementId: string) =>
  useRecoilCallback(
    ({ set, snapshot }) =>
      async (columnIndex: number, columnToInsert: TableColumn) => {
        set(
          tableElementByIdState(elementId),
          (element: TableElement) =>
            ({
              ...element,
              columns: element.columns.flatMap((column, idx) => {
                if (idx === columnIndex) {
                  return [columnToInsert, column] as const;
                }
                return column;
              }),
            } as TableElement)
        );

        const trackAction = await snapshot.getPromise(trackActionCallback);
        trackAction("element:edit:table:insert-column-before");
      },
    [elementId]
  );

export const useInsertTableColumnAfter = (elementId: string) =>
  useRecoilCallback(
    ({ set, snapshot }) =>
      async (columnIndex: number, columnToInsert: TableColumn) => {
        set(
          tableElementByIdState(elementId),
          (element: TableElement) =>
            ({
              ...element,
              columns: element.columns.flatMap((column, idx) => {
                if (idx === columnIndex) {
                  return [column, columnToInsert] as const;
                }
                return column;
              }),
            } as TableElement)
        );

        const trackAction = await snapshot.getPromise(trackActionCallback);
        trackAction("element:edit:table:insert-column-after");
      },
    [elementId]
  );

export const useAppendTableColumn = (elementId: string) =>
  useRecoilCallback(
    ({ set, snapshot }) =>
      async (columnToInsert: TableColumn) => {
        set(
          tableElementByIdState(elementId),
          (element: TableElement) =>
            ({
              ...element,
              columns: [...element.columns, columnToInsert],
            } as TableElement)
        );

        const trackAction = await snapshot.getPromise(trackActionCallback);
        trackAction("element:edit:table:append-column");
      },
    [elementId]
  );

export const useDeleteTableColumn = (elementId: string) =>
  useRecoilCallback(
    ({ set, snapshot }) =>
      async (columnIndex: number) => {
        set(tableElementByIdState(elementId), (element: TableElement) => ({
          ...element,
          columns: element.columns.filter(
            (_column, idx) => idx !== columnIndex
          ),
        }));

        const trackAction = await snapshot.getPromise(trackActionCallback);
        trackAction("element:edit:table:delete-column");
      },
    [elementId]
  );

export const useUpdateTableColumn = (elementId: string) =>
  useRecoilCallback(
    ({ set, snapshot }) =>
      async (columnIndex: number, update: Partial<TableColumn>) => {
        set(tableElementByIdState(elementId), (element: TableElement) => {
          const original = element.columns[columnIndex];

          const updatedColumns = element.columns.map((column, idx) =>
            idx === columnIndex ? Object.assign({}, column, update) : column
          );

          const updatedKey = update.key;
          const didColumnKeyChange = original.key !== updatedKey;

          if (updatedKey && didColumnKeyChange) {
            return {
              ...element,
              data: element.data.map((row) => ({
                ...omit(row, original.key),
                [updatedKey]: row[original.key],
              })),
              columns: updatedColumns,
            };
          }

          return {
            ...element,
            columns: updatedColumns,
          };
        });

        const trackAction = await snapshot.getPromise(trackActionCallback);
        trackAction("element:edit:table:update-column");
      },
    [elementId]
  );

const tableColumnState = selectorFamily<TableColumn, TableColumnKey>({
  key: "tableColumn",
  get:
    ({ elementId, columnKey }) =>
    ({ get }) => {
      const tableElement = get(tableElementByIdState(elementId));
      const { columns } = tableElement;
      const column = columns.find((col) => col.key === columnKey);

      if (!column) {
        throw new Error(
          `Unable to find column ${columnKey} in element ${elementId}`
        );
      }

      return column;
    },
});

export const useTableColumn = (key: TableColumnKey) =>
  useRecoilValue(tableColumnState(key));

const tableColumnAlignmentState = selectorFamily<
  TableColumnAlignment,
  TableColumnKey
>({
  key: "tableColumnAlignment",
  get:
    ({ elementId, columnKey }) =>
    ({ get }) => {
      const tableElement = get(tableElementByIdState(elementId));
      const { columns } = tableElement;
      const column = columns.find((col) => col.key === columnKey);

      return getTableColumnAlignment(column);
    },
});

export const useTableColumnAlignment = (key: TableColumnKey) =>
  useRecoilValue(tableColumnAlignmentState(key));

const tableColumnWidthState = selectorFamily<
  number | undefined,
  TableColumnKey
>({
  key: "tableColumnWidth",
  get:
    ({ elementId, columnKey }) =>
    ({ get }) => {
      const tableElement = get(tableElementByIdState(elementId));
      const { columns } = tableElement;
      const column = columns.find((col) => col.key === columnKey);

      return column?.width ?? undefined;
    },
});

export const useTableColumnWidth = (key: TableColumnKey) =>
  useRecoilValue(tableColumnWidthState(key));

export type ColumnEvalContextKey = {
  elementId: string;
  columnKey: string;
  rowIndex: number;
  data: TableRow;
};

const columnEvalContextState = selectorFamily<
  EvalContext,
  ColumnEvalContextKey | undefined
>({
  key: "table/columnEvalContext",
  get:
    (key) =>
    ({ get }) => {
      if (!key) {
        return {};
      }

      const { elementId, columnKey, rowIndex, data } = key;
      const evalContext = get(evalContextByIdState(elementId));
      const context = {
        columnKey,
        rowIndex,
        data: omitTableRowIndex(data),
        ...evalContext,
      };

      return context;
    },
});

export const useColumnEvalContext = (key: ColumnEvalContextKey | undefined) =>
  useRecoilValue(columnEvalContextState(key));

export const functionColumnResultState = selectorFamily<
  EvalResult,
  ColumnEvalContext
>({
  key: "table/functionColumnValue",
  get:
    (columnEvalContext) =>
    ({ get }) => {
      const {
        elementId,
        column: { key: columnKey },
        rowIndex,
        row,
        preview,
      } = columnEvalContext;

      let code;

      if (preview) {
        code = isFunctionColumnPreview(columnEvalContext.preview)
          ? columnEvalContext.preview?.content
          : "";
      } else {
        const column = get(tableColumnState({ elementId, columnKey }));

        if (!column) {
          throw new Error(
            `Failed to evaluate function column for table element ${elementId} column ${columnKey}`
          );
        }

        if (column.type !== "function") {
          throw new Error(
            `Table column ${columnKey} in element ${elementId} is not a function column`
          );
        }

        code = column.content ?? "";
      }

      try {
        const context = get(
          columnEvalContextState({
            elementId,
            columnKey,
            rowIndex,
            data: row,
          })
        );

        const contextId = `${elementId}--${columnKey}--${rowIndex}`;

        const result = evalCode(code, context, contextId);

        return { result };
      } catch (e: unknown) {
        const subsequentElementCamelCaseNames = get(
          subsequentElementCamelCaseNamesState(elementId)
        );
        return handleEvalError(e, subsequentElementCamelCaseNames);
      }
    },
});

export const useFunctionColumnResult = (context: ColumnEvalContext) =>
  useRecoilValue(functionColumnResultState(context));

export const formulaColumnResultState = selectorFamily<
  EvalResult,
  ColumnEvalContext
>({
  key: "table/formulaColumnValue",
  get:
    (columnEvalContext) =>
    ({ get }) => {
      const {
        elementId,
        column: { key: columnKey },
        rowIndex,
        row,
        preview,
      } = columnEvalContext;

      let formula;

      if (preview) {
        formula = isFormulaColumnPreview(columnEvalContext.preview)
          ? columnEvalContext.preview?.content
          : "";
      } else {
        const column = get(tableColumnState({ elementId, columnKey }));

        if (!column) {
          throw new Error(
            `Failed to evaluate formula column for table element ${elementId} column ${columnKey}`
          );
        }

        if (column.type !== "formula") {
          throw new Error(
            `Table column ${columnKey} in element ${elementId} is not a formula column`
          );
        }

        formula = column.content ?? "";
      }

      try {
        const context = get(
          columnEvalContextState({
            elementId,
            columnKey,
            rowIndex,
            data: row,
          })
        );

        const result = evalFormula(formula, context);

        return { result };
      } catch (e: unknown) {
        const subsequentElementCamelCaseNames = get(
          subsequentElementCamelCaseNamesState(elementId)
        );
        return handleEvalError(e, subsequentElementCamelCaseNames);
      }
    },
});

export const useFormulaColumnResult = (context: ColumnEvalContext) =>
  useRecoilValue(formulaColumnResultState(context));

export const tableDataState = selectorFamily<ComputedTableRow[], string>({
  key: "table/data",
  get:
    (elementId: string) =>
    ({ get }) => {
      const element = get(elementByIdState(elementId));

      if (element.kind !== "table") {
        throw new Error(`Unexpected element type ${elementId}`);
      }

      const { data, columns } = element as TableElement;

      const dataWithComputed = data.map((row, rowIndex) =>
        fromPairs(
          columns.map((column) => {
            if (column.type === "function") {
              const evalContext: ColumnEvalContext = {
                elementId,
                column,
                row,
                rowIndex,
              };

              const { result } = get(functionColumnResultState(evalContext));

              return [column.key, result];
            }

            if (column.type === "formula") {
              const evalContext: ColumnEvalContext = {
                elementId,
                column,
                row,
                rowIndex,
              };

              const { result } = get(formulaColumnResultState(evalContext));

              return [column.key, result];
            }

            if (column.type === "boolean") {
              return [column.key, Boolean(row[column.key])];
            }

            if (column.type === "number") {
              return [column.key, Number(row[column.key])];
            }

            if (column.type === "string") {
              return [column.key, row[column.key] ?? ""];
            }

            return [column.key, row[column.key] ?? ""];
          })
        )
      );

      return dataWithComputed;
    },
});
