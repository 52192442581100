import { css } from "@emotion/react";
import { mdiApplicationOutline, mdiApplicationBracesOutline } from "@mdi/js";
import {
  FC,
  Fragment,
  FocusEvent,
  KeyboardEvent,
  Suspense,
  useCallback,
  useRef,
  useState,
} from "react";
import {
  useCodeElementState,
  useCodeElementAppearance,
  useCodeElementAppearanceState,
} from "../state";
import { eventLoop } from "../../../utils/eventLoop";
import { MenuItem } from "../../../components/Menu";
import {
  ElementActionsComponent,
  ElementComponent,
} from "../../../components/types";
import CodeResult from "./CodeResult";
import { EvalResultLoading } from "../../../components/EvalResultDisplay";
import Editor, {
  EditorSuggestions,
  EditorController,
} from "../../../components/Editor";
import { useTrackAction } from "../../../observability";

const containerCss = css``;

interface LoadingProps {
  elementId: string;
}

const Loading: FC<LoadingProps> = ({ elementId }) => {
  const appearance = useCodeElementAppearance(elementId);
  return <EvalResultLoading hasTopBorder={appearance === "code-and-result"} />;
};

const CodeElement: ElementComponent = ({ elementId }) => {
  const [element, setElement] = useCodeElementState(elementId);
  const [code, setCode] = useState(element.code);

  const containerRef = useRef<HTMLDivElement>(null);
  const [areSuggestionsVisible, setAreSuggestionsVisible] = useState(false);
  const [isFocused, setFocused] = useState(false);

  const trackAction = useTrackAction();

  const onFocus = useCallback(() => {
    setAreSuggestionsVisible(true);
    setFocused(true);
  }, []);

  const onCommitChanges = useCallback(() => {
    if (element.code !== code) {
      setElement((s) => ({ ...s, code }));

      trackAction("element:edit:code");
    }
  }, [setElement, element.code, code, trackAction]);

  const onBlur = useCallback(
    (e: FocusEvent) => {
      if (!containerRef.current?.contains(e.relatedTarget)) {
        setAreSuggestionsVisible(false);
        setFocused(false);

        onCommitChanges();
      }
    },
    [onCommitChanges]
  );

  const onChange = useCallback(async (value: string) => {
    await eventLoop();
    setCode(value);
  }, []);

  const onKeyDown = useCallback(
    (e: KeyboardEvent<HTMLDivElement>) => {
      if ((e.metaKey || e.ctrlKey) && e.key === "Enter") {
        onCommitChanges();
      }
    },
    [onCommitChanges]
  );

  const appearance = useCodeElementAppearance(elementId);

  return (
    <EditorController>
      <div
        ref={containerRef}
        css={containerCss}
        onBlur={onBlur}
        onKeyDown={onKeyDown}
      >
        {appearance === "code-and-result" && (
          <Editor
            language="code"
            placeholder="Enter JavaScript here..."
            initialValue={code}
            onChange={onChange}
            onFocus={onFocus}
          />
        )}

        <Suspense fallback={<Loading elementId={elementId} />}>
          <CodeResult elementId={elementId} isFocused={isFocused} />
        </Suspense>

        {areSuggestionsVisible && <EditorSuggestions elementId={elementId} />}
      </div>
    </EditorController>
  );
};

const MenuItems: ElementActionsComponent = ({ elementId, closeMenu }) => {
  const [appearance, setAppearance] = useCodeElementAppearanceState(elementId);
  const onToggleLayout = () => {
    setAppearance((a) =>
      a === "code-and-result" ? "result-only" : "code-and-result"
    );
  };

  const label =
    appearance === "code-and-result"
      ? "Show result only"
      : "Show code and result";

  const icon =
    appearance === "code-and-result"
      ? mdiApplicationOutline
      : mdiApplicationBracesOutline;

  return (
    <Fragment>
      <MenuItem label={label} icon={icon} onClick={onToggleLayout} />
    </Fragment>
  );
};

CodeElement.MenuItems = MenuItems;

export default CodeElement;
