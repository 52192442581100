import { css } from "@emotion/react";
import { FC, PropsWithChildren } from "react";
import { Colors, TypographicStyles } from "../styles";
import colors from "../styles/colors";

interface Props extends PropsWithChildren {
  color: "upgrade";
}

export const tagCss = css`
  ${TypographicStyles.fineSemibold}
  padding: 2px 5px;
  border-radius: 2px;
`;

const upgradeTagCss = css`
  ${tagCss}
  background-color: ${colors.amber[600]};
  color: ${Colors.white};
`;

export const Tag: FC<Props> = ({ color, children }) => (
  <span css={color === "upgrade" ? upgradeTagCss : tagCss}>{children}</span>
);
