import { FC } from "react";
import { useFormulaElementResult } from "../state";
import EvalResultDisplay from "../../../components/EvalResultDisplay";

interface Props {
  elementId: string;
  isFocused: boolean;
}

const FormulaResult: FC<Props> = ({ elementId, isFocused }) => {
  const result = useFormulaElementResult(elementId);

  return (
    <EvalResultDisplay
      elementId={elementId}
      result={result}
      hasTopBorder
      isFocused={isFocused}
    />
  );
};

export default FormulaResult;
