import { mapKeys } from "lodash";
import { FC, PropsWithChildren, useCallback, MouseEvent } from "react";

interface Props extends PropsWithChildren {
  href?: string;
}

export const Anchor: FC<Props> = ({ children, href, ...props }) => {
  const onClick = useCallback(
    (e: MouseEvent<HTMLAnchorElement>) => {
      if (href?.startsWith("#")) {
        e.preventDefault();

        const target = document.querySelector(href);
        console.log(`scrollTo(${href})`, target);

        if (target) {
          target.previousElementSibling?.scrollIntoView({ behavior: "smooth" });
        }
      }
    },
    [href]
  );

  const theProps = mapKeys(props, (key) =>
    key === "class" ? "className" : key
  );

  return (
    <a {...theProps} href={href} onClick={onClick}>
      {children}
    </a>
  );
};
