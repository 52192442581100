// Implementations are in <repo>/public/electron-ipc.js

type OpenFileProps = {
  extensions?: string[];
  encoding?: "utf8" | "buffer";
};

type OpenFileResult = {
  data: unknown;
  filename: string;
};

interface ElectronIPC {
  getConfig<T>(key: string, defaultValue?: T): T;
  setConfig<T>(key: string, value: T): void;

  getAppPath(): string;

  getState(key: string): null | string;
  setState(key: string, value: string): void;

  requireInEval(moduleName: string): unknown;

  openFile(props: OpenFileProps): OpenFileResult;
}

declare global {
  interface Window {
    electron: ElectronIPC;
  }
}

export const getConfig = <T>(key: string, defaultValue?: T) =>
  window.electron.getConfig(key, defaultValue);

export const setConfig = <T>(key: string, value: T): void =>
  window.electron.setConfig(key, value);

export const getAppPath = () => window.electron.getAppPath();

export const getState = (key: string) => window.electron.getState(key);

export const setState = (key: string, value: string) =>
  window.electron.setState(key, value);

export const requireInEval = (moduleName: string) =>
  window.electron.requireInEval(moduleName);

export const openFile = (props: OpenFileProps) =>
  window.electron.openFile(props);
