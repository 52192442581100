import { css } from "@emotion/react";
import { Component, ErrorInfo, FC, memo } from "react";
import { Colors, Shadows, Typography } from "../styles";
import { Element } from "../types";
import ElementContainer from "./ElementContainer";
import ErrorDetails from "./ErrorDetails";
import useElementComponent from "./useElementComponent";

interface Props {
  element: Element;
  index: number;
}

const ElementRenderer: FC<Props> = memo(({ element, index }) => {
  const Component = useElementComponent(element);

  return (
    <ElementContainer
      elementId={element.id}
      index={index}
      MenuItems={Component.MenuItems}
    >
      <Component elementId={element.id} index={index} />
    </ElementContainer>
  );
});

interface State {
  error?: Error;
  errorInfo?: ErrorInfo;
}

const containerCss = css`
  border: 1px solid ${Colors.neutral300};
  border-radius: 3px;
  background-color: ${Colors.white};
  box-sizing: border-box;
  box-shadow: ${Shadows.elevation3};
`;

const errorBoundaryHeaderCss = css`
  padding: 5px;
  border-bottom: 1px solid ${Colors.neutral300};
  font-size: ${Typography.smallFontSize};
`;

const rawElementCss = css`
  padding: 5px;
  color: ${Colors.neutral600};
`;

class ErrorBoundary extends Component<Props, State> {
  componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    this.setState({ error, errorInfo });
  }

  render() {
    if (this.state?.error) {
      return (
        <div css={containerCss}>
          <div css={errorBoundaryHeaderCss}>
            This element produced the following error:
          </div>
          <pre css={rawElementCss}>
            {JSON.stringify(this.props.element, null, 2)}
          </pre>
          <ErrorDetails error={this.state.error} />
        </div>
      );
    }
    return <ElementRenderer {...this.props} />;
  }
}

export default ErrorBoundary;
