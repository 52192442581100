import { css } from "@emotion/react";
import { FC, PropsWithChildren } from "react";

const listCss = css`
  margin: 0 0 0 10px;
  padding: 0;
`;

const ulCss = css`
  ${listCss}
  list-style-type: none;
`;

const olCss = css`
  ${listCss}
  margin: 0 0 0 30px;
`;

const liCss = css`
  & > a {
    text-decoration: none;
  }
`;

export const UL: FC<PropsWithChildren> = ({ children }) => (
  <ul css={ulCss}>{children}</ul>
);

export const OL: FC<PropsWithChildren> = ({ children }) => (
  <ol css={olCss}>{children}</ol>
);

export const LI: FC<PropsWithChildren> = ({ children }) => (
  <li css={liCss}>{children}</li>
);
