import { css } from "@emotion/react";
import { mdiClose } from "@mdi/js";
import Icon from "@mdi/react";
import { FC } from "react";
import { baseButtonCss, Colors, Shadows, Typography } from "../styles";

export const modalPositionerCss = css`
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 100000;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.25);
`;

export const modalCss = css`
  position: relative;
  border-radius: 3px;
  background-color: ${Colors.neutral100};
  box-shadow: ${Shadows.elevation10};
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  max-height: 80vh;
`;

type ModalSizeProps = {
  width?: string;
};
export const modalSizeCss = ({ width }: ModalSizeProps) => [
  width &&
    css`
      width: ${width};
    `,
];

export const modalButtonsCss = css`
  display: flex;
  gap: 20px;
  width: 100%;
  justify-content: flex-end;
  box-sizing: border-box;
  padding: 10px;
  border-top: 1px solid ${Colors.neutral300};
`;

export const modalCloseButtonContainerCss = css`
  position: absolute;
  right: -10px;
  top: -10px;
  z-index: 100001;
`;

export const modalCloseButtonCss = css`
  ${baseButtonCss}
  padding: 5px;
  width: 100%;
  flex: 0 0 auto;
  box-sizing: border-box;
  background-color: ${Colors.neutral100};
  box-shadow: ${Shadows.elevation5};
`;

export const modalHeaderContainerCss = css`
  padding: 5px;
  border-bottom: 1px solid ${Colors.neutral300};
  font-size: ${Typography.fineFontSize};
  color: ${Colors.neutral700};
`;

export const modalBodyCss = css`
  padding: 20px;
  overflow: auto;

  & button {
    padding: 10px;
    min-width: 100px;
  }
`;

interface ModalHeaderProps {
  onClose: () => void;
  title: string;
}

export const ModalHeader: FC<ModalHeaderProps> = ({ title, onClose }) => (
  <div css={modalHeaderContainerCss}>
    <div>{title}</div>
    <div css={modalCloseButtonContainerCss}>
      <button type="button" css={modalCloseButtonCss} onClick={onClose}>
        <Icon path={mdiClose} size={0.7} color={Colors.neutral600} />
      </button>
    </div>
  </div>
);
