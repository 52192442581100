import { selector } from "recoil";
import { trackPageAction } from "./airlytics";
import { trackingIdState } from "./state";

export const trackActionCallback = selector({
  key: "trackActionCallback",
  get: ({ get, getCallback }) => {
    const trackingId = get(trackingIdState);

    return getCallback(() => async (action: string) => {
      const { pathname } = window.location;
      await trackPageAction(trackingId, pathname, action);
    });
  },
});
