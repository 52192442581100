import { FC, Fragment, useCallback, useState } from "react";
import { css } from "@emotion/react";
import { Icon } from "@mdi/react";
import {
  mdiMenu,
  mdiClose,
  mdiDotsHorizontal,
  mdiExport,
  mdiImport,
  mdiMagnify,
  mdiCloudDownloadOutline,
  mdiCloudUploadOutline,
  mdiCalculatorVariant,
  mdiHelpCircleOutline,
  mdiHelpCircle,
  mdiNumeric,
  mdiFolderCogOutline,
  mdiBugOutline,
  mdiLightbulbOutline,
} from "@mdi/js";
import { useHotkeys } from "react-hotkeys-hook";
import { flatButtonCss, Colors, Shadows, flexboxCss } from "../styles";
import {
  useMenuState,
  useActiveDocumentTitle,
  useExport,
  useImportDocuments,
  useSearchOpenState,
  useActiveDocumentId,
  useDocumentLabels,
  useToggleDocumentation,
  useIsDocumentationOpen,
  digitsToDisplayState,
  usePromptToUpgrade,
} from "../state";
import Text from "./Text";
import Menu, {
  IsomorphicPickFileMenuItem,
  MenuContainer,
  MenuGroup,
  MenuItem,
} from "./Menu";
import DocumentSearch from "./DocumentSearch";
import DocumentLabels from "./DocumentLabels";
import { usePrompt } from "../state/prompt";
import { SharingResult, useImportFromShare, useShare } from "../state";
import SharingResultModal from "./SharingResultModal";
import { useRecoilCallback } from "recoil";
import { isFeatureEnabled, isFeatureUpgradable } from "../feature-flags";
import { configStorageRootState } from "../state/config";
import { TooltipWrapper } from "./Tooltip";
import { isString } from "lodash";
import { useReportBug, useSuggestFeature } from "../hooks";

export const NAVBAR_HEIGHT = "51px";

const containerCss = css`
  border-bottom: 2px solid ${Colors.neutral300};
  background-color: ${Colors.neutral100};
  box-shadow: ${Shadows.elevation3};
  padding: 10px;
  color: ${Colors.neutral600};
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 20px;
  top: 0px;
  position: sticky;
  height: ${NAVBAR_HEIGHT};
  box-sizing: border-box;
  z-index: 100000;
`;

const groupCss = css`
  display: flex;
  align-items: center;
  gap: 10px;
`;

const fillCss = css`
  width: 100%;
  max-width: 500px;
  justify-content: center;
`;

const buttonCss = css`
  ${flatButtonCss}
  width: 30px;
  height: 30px;
`;

const titleCss = css`
  display: flex;
  align-items: center;
  gap: 5px;
  background-color: ${Colors.brand};
  color: ${Colors.brand900};
  border-radius: 3px;
  padding: 3px 5px;
`;

const titleTextCss = css``;

const NavBar: FC = () => {
  const prompt = usePrompt();
  const promptForNumber = usePrompt<number>();
  const promptToUpgrade = usePromptToUpgrade();

  const activeDocumentId = useActiveDocumentId();
  const labels = useDocumentLabels(activeDocumentId);
  const title = useActiveDocumentTitle();

  const [isSidebarOpen, setSidebarOpen] = useMenuState();
  const onToggleSidebar = useCallback(() => {
    setSidebarOpen((b) => !b);
  }, [setSidebarOpen]);

  const [isMenuOpen, setMenuOpen] = useState(false);
  const onToggleMenu = useCallback(() => {
    setMenuOpen((b) => !b);
  }, []);
  const onHideMenu = useCallback(() => {
    setMenuOpen(false);
  }, []);

  const onPromptToUpgradeForImport = useCallback(() => {
    setMenuOpen(false);
    promptToUpgrade("upgrade:notebook:import");
  }, [promptToUpgrade]);

  const performExport = useExport();
  const onExport = useCallback(async () => {
    setMenuOpen(false);

    if (isFeatureEnabled("filesystem")) {
      const result = await prompt({ message: "Please choose a filename" });

      if (result.status === "ok" && result.value) {
        performExport(result.value);
      }
    } else {
      promptToUpgrade("upgrade:notebook:export");
    }
  }, [prompt, performExport, promptToUpgrade]);

  const importDocuments = useImportDocuments();
  const onImportFromFile = async (data: unknown) => {
    setMenuOpen(false);
    if (isString(data)) {
      await importDocuments(data);
    } else {
    }
  };

  const importFromShare = useImportFromShare();
  const onImportFromShare = useCallback(async () => {
    setMenuOpen(false);

    const result = await prompt({
      message: "Provide the shared notebook ID",
      okLabel: "Import",
    });

    if (result.status === "ok") {
      const secret = result.value;

      await importFromShare(secret);
    }
  }, [prompt, importFromShare]);

  const onConfigureStorageRoot = useRecoilCallback(
    ({ snapshot, set }) =>
      async () => {
        setMenuOpen(false);

        if (isFeatureEnabled("filesystem")) {
          const storageRoot = await snapshot.getPromise(configStorageRootState);

          console.log({ storageRoot });

          const result = await prompt({
            message: "Enter the notebook storage path",
            okLabel: "Set",
            initialValue: storageRoot,
          });

          if (result.status === "ok") {
            set(configStorageRootState, result.value);
          }
        } else {
          promptToUpgrade("upgrade:configure-storage");
        }
      },
    [prompt, promptToUpgrade]
  );

  const [isSearchOpen, setSearchOpen] = useSearchOpenState();
  const onToggleSearch = useCallback(() => {
    setSearchOpen((b) => !b);
  }, [setSearchOpen]);

  const onToggleDocumentation = useToggleDocumentation();
  const isDocumentationOpen = useIsDocumentationOpen();

  const documentationButtonIcon = isDocumentationOpen
    ? mdiHelpCircle
    : mdiHelpCircleOutline;

  const share = useShare();
  const [sharingResult, setSharingResult] = useState<
    SharingResult | undefined
  >();
  const onShare = useCallback(async () => {
    setMenuOpen(false);

    const result = await share();
    setSharingResult(result);
  }, [share]);
  const onDismissSharingResults = useCallback(() => {
    setSharingResult(undefined);
  }, []);

  const onConfigureNumberFormat = useRecoilCallback(
    ({ snapshot, set }) =>
      async () => {
        const digitsToDisplay = await snapshot.getPromise(digitsToDisplayState);

        const result = await promptForNumber({
          message: "How many decimal digits should be displayed?",
          type: "number",
          initialValue: digitsToDisplay,
        });

        if (result.status === "ok" && result.value) {
          set(digitsToDisplayState, result.value);
        }
      },
    [promptForNumber]
  );

  const onReportBug = useReportBug();
  const onSuggestFeature = useSuggestFeature();

  useHotkeys("ctrl+k", () => {
    setSearchOpen(true);
  });

  return (
    <Fragment>
      <div css={containerCss}>
        <div css={groupCss}>
          <button type="button" css={buttonCss} onClick={onToggleSidebar}>
            <Icon path={isSidebarOpen ? mdiClose : mdiMenu} size={0.7} />
          </button>

          <div css={titleCss}>
            <Icon
              path={mdiCalculatorVariant}
              color={Colors.currentColor}
              size={0.7}
            />
            <Text type="smallSemibold" css={titleTextCss}>
              CALCULA
            </Text>
          </div>
        </div>

        <div css={[groupCss, fillCss]}>
          {isSearchOpen ? (
            <DocumentSearch />
          ) : (
            <Text
              type="title"
              align="center"
              css={flexboxCss({ align: "center", gap: "5px" })}
            >
              {title}
              <DocumentLabels labels={labels} />
            </Text>
          )}
        </div>

        <div css={groupCss}>
          <TooltipWrapper content="Search">
            {(childAttrs) => (
              <button
                {...childAttrs}
                type="button"
                css={buttonCss}
                onClick={onToggleSearch}
              >
                <Icon path={mdiMagnify} size={0.7} />
              </button>
            )}
          </TooltipWrapper>

          <TooltipWrapper
            content={
              isDocumentationOpen ? "Hide documentation" : "Show documentation"
            }
          >
            {(childAttrs) => (
              <button
                {...childAttrs}
                type="button"
                css={buttonCss}
                onClick={onToggleDocumentation}
              >
                <Icon path={documentationButtonIcon} size={0.7} />
              </button>
            )}
          </TooltipWrapper>

          <MenuContainer>
            <button type="button" css={buttonCss} onClick={onToggleMenu}>
              <Icon path={mdiDotsHorizontal} size={0.7} />
            </button>

            {isMenuOpen && (
              <Menu align="right" onHide={onHideMenu}>
                <MenuGroup label="Preferences" />
                <MenuItem
                  label="Set number formatting"
                  icon={mdiNumeric}
                  onClick={onConfigureNumberFormat}
                />
                <MenuItem
                  label="Set storage path"
                  icon={mdiFolderCogOutline}
                  onClick={onConfigureStorageRoot}
                  isUpgrade={isFeatureUpgradable("filesystem")}
                />

                <MenuGroup label="Filesystem" />
                {isFeatureEnabled("filesystem") ? (
                  <IsomorphicPickFileMenuItem
                    label="Import notebook from file"
                    icon={mdiImport}
                    encoding="utf8"
                    extensions={["json"]}
                    onLoaded={onImportFromFile}
                  />
                ) : (
                  <MenuItem
                    label="Import notebook from file"
                    icon={mdiImport}
                    onClick={onPromptToUpgradeForImport}
                    isUpgrade={isFeatureUpgradable("filesystem")}
                  />
                )}
                <MenuItem
                  label="Export notebook to file"
                  icon={mdiExport}
                  onClick={onExport}
                  isUpgrade={isFeatureUpgradable("filesystem")}
                />

                <MenuGroup label="Sharing" />
                <MenuItem
                  label="Import shared notebook"
                  icon={mdiCloudDownloadOutline}
                  onClick={onImportFromShare}
                />

                <MenuItem
                  label="Share notebook"
                  icon={mdiCloudUploadOutline}
                  onClick={onShare}
                />

                <MenuGroup label="Support" />
                <MenuItem
                  label="Suggest a feature"
                  icon={mdiLightbulbOutline}
                  onClick={onSuggestFeature}
                />
                <MenuItem
                  label="Report a bug"
                  icon={mdiBugOutline}
                  onClick={onReportBug}
                />
              </Menu>
            )}
          </MenuContainer>
        </div>
      </div>

      <SharingResultModal
        result={sharingResult}
        onDismiss={onDismissSharingResults}
      />
    </Fragment>
  );
};

export default NavBar;
