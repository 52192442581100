import { FC } from "react";
import { css } from "@emotion/react";
import { Toast, ToastLevel } from "../types";
import { useDismissToast } from "../state";
import Icon from "@mdi/react";
import {
  mdiAlert,
  mdiAlertCircle,
  mdiCheck,
  mdiClose,
  mdiInformation,
} from "@mdi/js";
import { Colors, flatButtonCss, Shadows } from "../../styles";

interface Props {
  index: number;
  toast: Toast;
}

const containerCss = css`
  display: flex;
  align-items: stretch;
  background-color: ${Colors.white};
  border: 1px solid ${Colors.neutral300};
  box-shadow: ${Shadows.elevation10};
  border-radius: 2px;
`;

const contentCss = css`
  padding: 10px;
  display: flex;
  justify-content: space-between;
  gap: 20px;
  align-items: center;
  width: 100%;
`;

const layoutCss = css`
  display: flex;
  gap: 10px;
  align-items: center;
`;

const dismissButtonCss = css`
  ${flatButtonCss}

  width: 24px;
  height: 24px;
`;

const getLevelColor = (level: ToastLevel) => {
  switch (level) {
    case "success":
      return Colors.success500;
    case "info":
      return Colors.info500;
    case "warning":
      return Colors.warning400;
    case "danger":
      return Colors.danger500;
  }
};

const getLevelIcon = (level: ToastLevel) => {
  switch (level) {
    case "success":
      return mdiCheck;
    case "info":
      return mdiInformation;
    case "warning":
      return mdiAlertCircle;
    case "danger":
      return mdiAlert;
  }
};

const barCss = (level: ToastLevel) => css`
  background-color: ${getLevelColor(level)};
  width: 5px;
  border-top-left-radius: 1px;
  border-bottom-left-radius: 1px;
`;

export const ToastDisplay: FC<Props> = ({ index, toast }) => {
  const dismissToast = useDismissToast(index);

  return (
    <div css={containerCss}>
      <div css={barCss(toast.level)} />
      <div css={contentCss}>
        <div css={layoutCss}>
          <Icon
            size={0.7}
            color={getLevelColor(toast.level)}
            path={getLevelIcon(toast.level)}
          />
          {toast.message}
        </div>
        <button type="button" css={dismissButtonCss} onClick={dismissToast}>
          <Icon path={mdiClose} />
        </button>
      </div>
    </div>
  );
};
