import { isEmpty, last, uniq } from "lodash";
import {
  ChangeEvent,
  FC,
  MouseEvent,
  RefObject,
  useCallback,
  useEffect,
} from "react";
import { SelectPromptOptions } from "../../state/prompt";
import { flexboxCss, linkButtonCss } from "../../styles";
import { formFieldCss, formFieldLabelCss } from "../Form";
import { PromptEditor } from "./types";

interface SelectItemProps {
  index: number;
  label: string;
  value: string;
  isChecked: boolean;
  setChecked: (value: string, isSelected: boolean) => void;
  focusRef?: RefObject<HTMLInputElement>;
}

const SelectItem: FC<SelectItemProps> = ({
  index,
  label,
  value,
  isChecked,
  setChecked,
  focusRef,
}) => {
  const onChangeValue = (event: ChangeEvent<HTMLInputElement>) => {
    setChecked(value, event.target.checked);
  };

  return (
    <div css={flexboxCss({ align: "center", gap: "5px" })}>
      <input
        ref={focusRef}
        id={`prompt-value-${index}`}
        type="checkbox"
        checked={isChecked}
        onChange={onChangeValue}
      />
      <label htmlFor={`prompt-value-${index}`}>{label}</label>
    </div>
  );
};

const updateValues = (values: string[], value: string, isChecked: boolean) => {
  return isChecked
    ? uniq([...values, value])
    : values.filter((v) => v !== value);
};

const SelectEditor: PromptEditor<string[], SelectPromptOptions> = ({
  message,
  value: values,
  setValue: setValues,
  focusRef,
  options: { items },
}) => {
  const setValue = (value: string, isChecked: boolean) => {
    setValues(updateValues(values, value, isChecked));
  };

  useEffect(() => {
    setValues(
      isEmpty(values) ? [""] : isEmpty(last(values)) ? values : [...values, ""]
    );
  });

  const onSelectAll = useCallback(
    (e: MouseEvent<HTMLButtonElement>) => {
      e.preventDefault();
      setValues(items.map(({ value }) => value));
    },
    [setValues, items]
  );

  const onSelectNone = useCallback(
    (e: MouseEvent<HTMLButtonElement>) => {
      e.preventDefault();
      setValues([]);
    },
    [setValues]
  );

  return (
    <div css={formFieldCss}>
      <div css={flexboxCss({ align: "center", justify: "space-between" })}>
        <label css={formFieldLabelCss}>{message}</label>
        <div css={flexboxCss({ gap: "10px" })}>
          <button css={linkButtonCss} onClick={onSelectAll}>
            select all
          </button>
          <button css={linkButtonCss} onClick={onSelectNone}>
            select none
          </button>
        </div>
      </div>

      <div css={flexboxCss({ direction: "column", gap: "5px" })}>
        {items.map(({ label, value }, index) => (
          <SelectItem
            key={index}
            index={index}
            focusRef={index === 0 ? focusRef : undefined}
            label={label}
            value={value}
            isChecked={values.includes(value)}
            setChecked={setValue}
          />
        ))}
      </div>
    </div>
  );
};

export default SelectEditor;
