import { TableColumn, TableColumnAlignment } from "../types";

export const getTableColumnAlignment = (
  column: TableColumn | undefined
): TableColumnAlignment => {
  if (column?.type === "boolean") {
    return "center";
  }

  return column?.align ?? "left";
};
