import { FC } from "react";
import {
  isArray,
  isError,
  isRegExp,
  isString,
  memoize,
  takeWhile,
} from "lodash";
import convert from "react-from-dom";

import { useElementIndex, useElementName } from "../../state";
import isHTML from "../../utils/isHTML";
import { ElementReferenceError, isElementReferenceError } from "../../errors";
import { italicCss, resultCss } from "./styles";
import {
  baseButtonCss,
  Colors,
  errorCss,
  primaryButtonCss,
} from "../../styles";
import getElementTitle from "../../utils/getElementTitle";
import { TableDisplay } from "./displays/TableDisplay";
import { ObjectDisplay } from "./displays/ObjectDisplay";
import isPromise from "../../utils/isPromise";
import { EvalResult } from "../../types";
import { css } from "@emotion/react";
import { isRequireIsUnsupportedError } from "../../errors/RequireIsUnsupportedError";
import { Tag } from "../Tag";
import { RequireIsUnsupportedDisplay } from "./displays/RequireIsUnsupportedDisplay";
import { PromiseDisplay } from "./displays/PromiseDisplay";
import { isDevelopment } from "../../utils/isDevelopment";

interface Props {
  elementId: string;
  result: EvalResult;
  hasTopBorder?: boolean;
  isFocused?: boolean;
}

const htmlResultCss = css`
  button {
    ${baseButtonCss}
    background-color: ${Colors.neutral100};
    padding: 10px;
  }

  button.primary {
    ${primaryButtonCss}
  }
`;

const renderResultHTML = memoize(
  (result: string) => convert(result) as React.ReactNode
);

const EvalResultDisplay: FC<Props> = ({
  elementId,
  result: { result, isEmptyResult },
  hasTopBorder = true,
  isFocused,
}) => {
  const elementIndex = useElementIndex(elementId);
  const elementName = useElementName(elementId);
  const elementTitle = getElementTitle(elementName, elementIndex);

  const containerCss = resultCss(hasTopBorder);

  if (isEmptyResult) {
    return (
      <div css={[resultCss(hasTopBorder), italicCss]}>Nothing to evaluate</div>
    );
  }

  if (isElementReferenceError(result)) {
    return (
      <pre css={[containerCss, errorCss]}>
        Error: {`${(result as ElementReferenceError).message}`}
      </pre>
    );
  }

  if (isRequireIsUnsupportedError(result)) {
    return (
      <div css={containerCss}>
        <RequireIsUnsupportedDisplay error={result}>
          Importing modules with <code>require</code> is a{" "}
          <Tag color="upgrade">PRO</Tag> feature.
        </RequireIsUnsupportedDisplay>
      </div>
    );
  }

  if (isError(result)) {
    // EXPLANATION: log the entire error stack in development
    if (isDevelopment()) {
      console.group("Eval result");
      console.error(result);
      console.groupEnd();
    }

    const stackLines = result.stack?.split("\n") ?? [];
    const relevantStack = takeWhile(stackLines, (line) => line.includes("eval"))
      .map((line) => line.replace(/@.*>/, ""))
      .map((line) => (line.startsWith(" ") ? line : ` ${line}`))
      .map((line) => line.replace("eval", `@ "${elementTitle}"`))
      .join("\n");

    return (
      <pre css={[containerCss, errorCss]}>
        Error: {`${result.message}\n${relevantStack}`}
      </pre>
    );
  }

  if (isPromise(result)) {
    return <PromiseDisplay elementId={elementId} result={result} />;
  }

  if (isString(result) && isHTML(result)) {
    return (
      <div css={[containerCss, htmlResultCss]}>{renderResultHTML(result)}</div>
    );
  }

  if (isRegExp(result)) {
    return <div css={[containerCss]}>RegExp({result.toString()})</div>;
  }

  if (isArray(result)) {
    return (
      <div css={resultCss(hasTopBorder, false)}>
        <TableDisplay value={result} />
      </div>
    );
  }

  return (
    <div css={containerCss}>
      <ObjectDisplay value={result} />
    </div>
  );
};

export default EvalResultDisplay;
