import { css } from "@emotion/react";
import { mdiPlus } from "@mdi/js";
import Icon from "@mdi/react";
import { FC, useCallback } from "react";
import { useAppendTableRow } from "../../../state";
import { flatButtonCss } from "../../../styles";

interface Props {
  elementId: string;
}

const containerCss = css`
  width: 100%;
  padding: 3px;
  box-sizing: border-box;
`;

const addRowButtonCss = css`
  ${flatButtonCss}

  padding: 5px;
  width: 100%;

  &:hover {
    box-shadow: none;
  }
`;

export const AddRowButton: FC<Props> = ({ elementId }) => {
  const appendTableRow = useAppendTableRow(elementId);
  const onAppendTableRow = useCallback(() => {
    appendTableRow();
  }, [appendTableRow]);

  return (
    <div css={containerCss}>
      <button css={addRowButtonCss} onClick={onAppendTableRow}>
        <Icon path={mdiPlus} size={0.7} />
      </button>
    </div>
  );
};
