import { createContext, FC, PropsWithChildren, useContext } from "react";
import { TableColumnPreview } from "../../../state/types";

const Context = createContext<TableColumnPreview | undefined>(undefined);

export const useColumnPreview = (key: string) => {
  const preview = useContext(Context);

  if (preview?.key === key) {
    return preview;
  }

  return;
};

type Props = PropsWithChildren<{ preview: TableColumnPreview }>;

export const ColumnPreview: FC<Props> = ({ children, preview }) => (
  <Context.Provider value={preview}>{children}</Context.Provider>
);
