import {
  DefaultValue,
  selectorFamily,
  useRecoilState,
  useRecoilValue,
} from "recoil";
import { EvalResult, FormulaElement } from "../../types";
import {
  elementByIdState,
  evalContextByIdState,
  subsequentElementCamelCaseNamesState,
} from "../../state";
import { isFormulaElement } from "../../utils/isFormulaElement";
import { isEmpty } from "lodash";
import evalFormula from "../../utils/evalFormula";
import { handleEvalError } from "../../utils/handleEvalError";

export const formulaElementByIdState = selectorFamily<FormulaElement, string>({
  key: "document/formulaElementById",
  get:
    (elementId) =>
    ({ get }) => {
      const element = get(elementByIdState(elementId));

      if (!isFormulaElement(element)) {
        throw new Error(`Element kind is not formula element "${elementId}"`);
      }

      return element;
    },

  set:
    (elementId) =>
    ({ set }, update) => {
      if (update instanceof DefaultValue) {
        return;
      }

      set(elementByIdState(elementId), update);
    },
});

export const useFormulaElement = (elementId: string) =>
  useRecoilValue(formulaElementByIdState(elementId));
export const useFormulaElementState = (elementId: string) =>
  useRecoilState(formulaElementByIdState(elementId));

export const formulaElementResultState = selectorFamily<EvalResult, string>({
  key: "document/formulaElementResult",
  get:
    (elementId: string) =>
    ({ get }) => {
      const { formula } = get(formulaElementByIdState(elementId));

      if (isEmpty(formula)) {
        return { result: undefined, isEmptyResult: true };
      }

      const evalContext = get(evalContextByIdState(elementId));

      try {
        const result = evalFormula(formula, evalContext);
        return { result };
      } catch (e: unknown) {
        const subsequentElementCamelCaseNames = get(
          subsequentElementCamelCaseNamesState(elementId)
        );

        return handleEvalError(e, subsequentElementCamelCaseNames);
      }
    },
});

export const useFormulaElementResult = (elementId: string) =>
  useRecoilValue(formulaElementResultState(elementId));
