import { Column as DataGridColumn } from "react-data-grid";
import { selectorFamily, useRecoilValue } from "recoil";
import { tableElementByIdState } from "../../../../state";
import { TableRow } from "../../../../types";
import { ROW_NUMBER_COLUMN } from "../constants";
import { getDataGridColumn } from "../utils/getDataGridColumn";

const gridColumnsState = selectorFamily<DataGridColumn<TableRow>[], string>({
  key: "table/gridColumns",
  get:
    (elementId) =>
    ({ get }) => {
      const element = get(tableElementByIdState(elementId));

      if (!element) {
        throw new Error(`Unable to get grid rows for ${elementId}`);
      }

      return [
        ROW_NUMBER_COLUMN,
        ...element.columns.map((column) => getDataGridColumn(column)),
      ];
    },
});

export const useColumns = (elementId: string) =>
  useRecoilValue(gridColumnsState(elementId));
