import { css } from "@emotion/react";
import { v4 as uuid4 } from "uuid";
import {
  ChangeEvent,
  createContext,
  FC,
  PropsWithChildren,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { Icon } from "@mdi/react";
import { Colors, marginCss, Shadows, transition, Typography } from "../styles";
import Text from "./Text";
import { Tag } from "./Tag";
import { isElectron } from "../utils/isElectron";
import { openFile } from "../ipc";

interface MenuItemProps {
  label: string;
  icon?: string;
  onClick: () => void;
  isUpgrade?: boolean;
}

const menuItemCss = css`
  background: ${Colors.transparent};
  color: ${Colors.neutral900};
  border: 0;
  border-radius: 2px;
  padding: 10px 10px;
  font-size: 16px;
  font-family: ${Typography.fontFamily};
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: start;
  gap: 10px;
  white-space: nowrap;

  transition: ${transition("background-color")};

  &:hover {
    background-color: ${Colors.neutral200};
  }
`;

const menuLabelCss = css`
  flex: 0 0 auto;
`;

export const MenuItem: FC<MenuItemProps> = ({
  label,
  icon,
  onClick,
  isUpgrade = false,
}) => (
  <button css={menuItemCss} type="button" onClick={onClick}>
    {icon && <Icon path={icon} size={0.7} color={Colors.neutral500} />}
    <span css={menuLabelCss}>{label}</span>
    {isUpgrade && (
      <span css={marginCss("0 0 0 auto")}>
        <span css={marginCss("0 0 0 20px")}>
          <Tag color="upgrade">PRO</Tag>
        </span>
      </span>
    )}
  </button>
);

const fileInputCss = css`
  display: none;
`;

type IsomorphicPickFileMenuItemProps = Omit<MenuItemProps, "onClick"> & {
  onLoaded: (filename: string, data: unknown) => Promise<void>;
  extensions?: string[];
  encoding?: "utf8" | "buffer";
};

const NativePickFileMenuItem: FC<IsomorphicPickFileMenuItemProps> = ({
  label,
  icon,
  onLoaded,
  extensions,
  encoding,
}) => {
  const onClick = useCallback(() => {
    const { filename, data } = openFile({ extensions, encoding });
    onLoaded(filename, data);
  }, [onLoaded, extensions, encoding]);

  return <MenuItem label={label} icon={icon} onClick={onClick} />;
};

const BrowserPickFileMenuItem: FC<IsomorphicPickFileMenuItemProps> = ({
  label,
  icon,
  onLoaded,
  encoding = "utf8",
  extensions = [],
}) => {
  const onSelectFile = (e: ChangeEvent<HTMLInputElement>) => {
    if (e.target.files) {
      // @ts-expect-error
      const [file] = e.target.files;
      const reader = new FileReader();

      reader.addEventListener(
        "load",
        async () => {
          try {
            const data = reader.result;

            if (data) {
              onLoaded(file.name, data);
            }
          } catch (e: unknown) {
            console.error("Failed to load file", e);
          }
        },
        false
      );

      if (file) {
        if (encoding === "utf8") {
          reader.readAsText(file);
        } else if (encoding === "buffer") {
          reader.readAsArrayBuffer(file);
        } else {
          throw new Error(`Unsupported encoding ${encoding}`);
        }
      }
    } else {
      console.warn(`No files selected`);
    }
  };

  const id = useMemo(() => "file-input-" + uuid4(), []);

  return (
    <div>
      <label htmlFor={id} css={menuItemCss}>
        {icon && <Icon path={icon} size={0.7} color={Colors.neutral500} />}
        <span css={menuLabelCss}>{label}</span>
      </label>
      <input
        type="file"
        id={id}
        css={fileInputCss}
        accept={extensions?.join(",")}
        onChange={onSelectFile}
      />
    </div>
  );
};

export const IsomorphicPickFileMenuItem: FC<IsomorphicPickFileMenuItemProps> = (
  props
) =>
  isElectron() ? (
    <NativePickFileMenuItem {...props} />
  ) : (
    <BrowserPickFileMenuItem {...props} />
  );

const menuSeparatorCss = css`
  border-top: 1px solid ${Colors.neutral200};
`;

export const MenuSeparator: FC = () => <div css={menuSeparatorCss} />;

const menuGroupCss = css`
  padding: 5px 10px 0px;
`;

type MenuGroupProps = {
  label: string;
};

export const MenuGroup: FC<MenuGroupProps> = ({ label }) => (
  <div css={menuGroupCss}>
    <Text type="smallSemibold" color={Colors.neutral900}>
      {label}
    </Text>
  </div>
);

const MenuPlacementContext = createContext<Placement>("below");

const useMenuPlacement = () => useContext(MenuPlacementContext);

const menuContainerCss = css`
  position: relative;
`;

type MenuContainerProps = Record<string, any>;

export const MenuContainer: FC<PropsWithChildren<MenuContainerProps>> = ({
  children,
  ...props
}) => {
  const containerRef = useRef<HTMLDivElement>(null);

  const [placement, setPlacement] = useState<Placement>("below");

  useEffect(() => {
    requestAnimationFrame(() => {
      if (containerRef.current) {
        const vh = Math.max(
          document.documentElement.clientHeight ?? 0,
          window.innerHeight ?? 0
        );
        const bounds = containerRef.current.getBoundingClientRect();
        if (bounds.top + bounds.height > vh / 2) {
          setPlacement("above");
        } else {
          setPlacement("below");
        }
      }
    });
  }, []);

  return (
    <div {...props} css={menuContainerCss} ref={containerRef}>
      <MenuPlacementContext.Provider value={placement}>
        {children}
      </MenuPlacementContext.Provider>
    </div>
  );
};

type Placement = "above" | "below";

interface MenuProps {
  onHide: () => void;
  align?: "left" | "right";
  isOffset?: boolean;
}

const MENU_OFFSET = 12;

const menuCss = (
  align: "left" | "right",
  placement: Placement | null,
  isOffset: boolean = false
) => [
  css`
    position: absolute;
    z-index: 1001;
    padding: 10px;
    min-width: 240px;
    display: flex;
    flex-direction: column;
    gap: 5px;
    border-radius: 2px;
    background-color: ${Colors.neutral100};
    border: 1px solid ${Colors.neutral300};
    box-shadow: ${Shadows.elevation10};
  `,
  align === "left" &&
    css`
      left: 5px;
    `,
  align === "right" &&
    css`
      right: 0;
    `,
  placement === "below" &&
    css`
      top: calc(100% + ${isOffset ? MENU_OFFSET : 0}px);
      margin-top: 3px;
    `,
  placement === "above" &&
    css`
      bottom: calc(100% + ${isOffset ? MENU_OFFSET : 0}px);
      margin-bottom: 3px;
    `,
  placement === null &&
    css`
      opacity: 0;
    `,
];

const Menu: FC<PropsWithChildren<MenuProps>> = ({
  children,
  align = "left",
  isOffset,
  onHide,
}) => {
  const menuRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    const listener = (e: Event) => {
      if (!menuRef.current?.contains(e.target as Node)) {
        onHide();
      }
    };

    document.body.addEventListener("focus", listener);
    document.body.addEventListener("mousedown", listener);

    return () => {
      document.body.removeEventListener("focus", listener);
      document.body.removeEventListener("mousedown", listener);
    };
  }, [onHide]);

  const placement = useMenuPlacement();

  return (
    <div
      ref={menuRef}
      css={menuCss(align, placement, isOffset)}
      tabIndex={0}
      role="menu"
      onBlur={onHide}
    >
      {children}
    </div>
  );
};

export default Menu;
