import { TableColumnAlignment } from "../types";

export const normalizeColumnAlignment = (
  alignment: string | undefined
): TableColumnAlignment => {
  switch (alignment) {
    case "left":
      return "left";
    case "right":
      return "right";
    case "center":
      return "center";
    default:
      return "left";
  }
};
