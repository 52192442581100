import { FC } from "react";

import { useCodeElementAppearance, useCodeElementResult } from "../state";
import EvalResultDisplay from "../../../components/EvalResultDisplay";

interface Props {
  elementId: string;
  isFocused: boolean;
}

const CodeResult: FC<Props> = ({ elementId, isFocused }) => {
  const result = useCodeElementResult(elementId);
  const appearance = useCodeElementAppearance(elementId);

  return (
    <EvalResultDisplay
      result={result}
      isFocused={isFocused}
      elementId={elementId}
      hasTopBorder={appearance === "code-and-result"}
    />
  );
};

export default CodeResult;
