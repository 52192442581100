import { useRecoilCallback } from "recoil";
import { useShowToast } from "../../toasts";
import { csvElementByIdState } from "./state";

export const useImportCsvData = (elementId: string) => {
  const showToast = useShowToast();

  return useRecoilCallback(
    ({ set }) =>
      async (data: string) => {
        if (data) {
          set(csvElementByIdState(elementId), (element) => ({
            ...element,
            data,
          }));

          showToast({
            level: "success",
            message: "Successfully imported table data",
          });
        }
      },
    [elementId, showToast]
  );
};
