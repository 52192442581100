import { css } from "@emotion/react";
import { isEmpty, isFunction, isObject } from "lodash";
import { FC, Fragment } from "react";
import { Colors } from "../../styles";
import { Primitive } from "../../types";
import { isPrimitive } from "../../utils/isPrimitive";
import Text from "../Text";

type Value = Primitive | Record<string, unknown> | unknown;

interface Props {
  label?: string;
  value: Value;
  hasIndentation?: boolean;
}

const containerCss = (hasIndentation?: boolean) => css`
  margin-left: ${hasIndentation ? 10 : 0}px;
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

const layoutCss = css`
  display: flex;
  gap: 10px;
`;

const DataView: FC<Props> = ({ label, value, hasIndentation }) => {
  if (isEmpty(label) && isEmpty(value)) {
    return null;
  }

  if (isFunction(value)) {
    return (
      <div css={layoutCss}>
        <Text type="small" monospace color={Colors.neutral600}>
          {label}:
        </Text>
        <Text>
          <em>&lt;function&gt;</em>
        </Text>
      </div>
    );
  }

  if (isPrimitive(value)) {
    return (
      <div css={layoutCss}>
        <Text type="small" monospace color={Colors.neutral600}>
          {label}:
        </Text>
        <Text>{value}</Text>
      </div>
    );
  }

  if (isObject(value)) {
    return (
      <Fragment>
        {!isEmpty(label) && (
          <Text type="small" monospace color={Colors.neutral600}>
            {label}:
          </Text>
        )}
        <div css={containerCss(hasIndentation)}>
          {Object.entries(value).map(([key, val]) => (
            <DataView key={key} label={key} value={val} hasIndentation />
          ))}
        </div>
      </Fragment>
    );
  }

  return null;
};

interface DataViewWrapperProps {
  value: Value;
}

const wrapperCss = css`
  background-color: ${Colors.white};
  border: 1px solid ${Colors.neutral300};
  border-radius: 3px;
  padding: 10px;
  max-height: 300px;
  overflow: auto;
`;

const DataViewWrapper: FC<DataViewWrapperProps> = ({ value }) => (
  <div css={wrapperCss}>
    <DataView value={value} />
  </div>
);

export default DataViewWrapper;
