import { TextEditor } from "react-data-grid";
import { TableColumn } from "../../../../types";
import NumberEditor from "./NumberEditor";

const getEditorForColumn = (column: TableColumn) => {
  switch (column.type) {
    case "string":
      return TextEditor;
    case "number":
      return NumberEditor;
    case "boolean":
      return undefined;
    case "function":
      return undefined;

    default:
      return TextEditor;
  }
};

export default getEditorForColumn;
