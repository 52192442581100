import { css } from "@emotion/react";
import { Colors, Shadows, transition } from "../../../styles";

export const contextMenuCss = css`
  .react-contextmenu-wrapper {
    display: contents;
  }

  .react-contextmenu {
    background-clip: padding-box;
    border-radius: 3px;
    color: ${Colors.neutral800};
    font-size: 16px;
    margin-block-start: 2px;
    margin-block-end: 0;
    margin-inline-start: 0;
    margin-inline-end: 0;
    min-inline-size: 160px;
    outline: none;
    opacity: 0;
    padding: 10px;
    pointer-events: none;
    text-align: start;
    padding: 10px;
    min-width: 200px;
    display: flex;
    flex-direction: column;
    gap: 5px;
    border-radius: 3px;
    background-color: ${Colors.neutral100};
    box-shadow: ${Shadows.elevation10};
    border: 1px solid ${Colors.neutral300};
  }

  .react-contextmenu.react-contextmenu--visible {
    opacity: 1;
    pointer-events: auto;
  }

  .react-contextmenu-item {
    background: ${Colors.transparent};
    color: ${Colors.neutral900};
    border: 0;
    border-radius: 2px;
    padding: 10px 10px;
    font-size: 16px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: start;
    gap: 10px;
    white-space: nowrap;

    transition: ${transition("background-color")};

    &:hover {
      background-color: ${Colors.neutral200};
    }
  }

  .react-contextmenu-item.react-contextmenu-item--active,
  .react-contextmenu-item.react-contextmenu-item--selected {
    background-color: ${Colors.neutral200};
    border-color: ${Colors.neutral200};
    text-decoration: none;
  }

  .react-contextmenu-item.react-contextmenu-item--disabled,
  .react-contextmenu-item.react-contextmenu-item--disabled:hover {
    background-color: transparent;
    color: ${Colors.neutral400};
  }

  .react-contextmenu-item--divider {
    border-top: 1px solid ${Colors.neutral200};
    height: 1px;
  }

  .react-contextmenu-item--divider:hover {
    background-color: transparent;
  }

  .react-contextmenu-item.react-contextmenu-submenu {
    padding: 0;
  }

  .react-contextmenu-item.react-contextmenu-submenu
    > .react-contextmenu-item::after {
    content: "▶";
    display: inline-block;
    position: absolute;
    inset-inline-end: 7px;
  }
`;

export const globalCss = css`
  .rdg-row-number-cell,
  .rdg-column-label-cell {
    background-color: ${Colors.neutral100};
  }

  .rdg-cell {
    padding: 0 5px;
  }

  .rdg-function-cell {
    padding: 0;
  }

  .rdg-context-menu-container {
    position: relative;
  }
`;

export const containerCss = css`
  background-color: ${Colors.neutral100};
`;

export const dataGridCss = (maxHeight: number) => css`
  border: none;
  overflow: auto;
  height: ${maxHeight}px;
  max-height: ${maxHeight}px;
  background-color: ${Colors.neutral100};
`;
