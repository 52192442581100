import { css } from "@emotion/react";
import { FC, memo, useCallback, useState } from "react";
import { useDrag, useDrop } from "react-dnd";
import { Icon } from "@mdi/react";
import {
  mdiDotsHorizontal,
  mdiRenameBox,
  mdiContentCopy,
  mdiTrashCanOutline,
  mdiPlus,
  mdiExport,
  mdiTagMultipleOutline,
} from "@mdi/js";
import { flatButtonCss, Colors, baseButtonCss, Typography } from "../styles";
import Menu, { MenuContainer, MenuItem, MenuSeparator } from "./Menu";
import {
  useAddDocument,
  useDeleteDocument,
  useDocuments,
  useIsDocumentSelected,
  useRenameDocument,
  useDuplicateDocument,
  useReorderDocuments,
  useSelectDocument,
  useMenuState,
  useExportDocument,
  useDocumentLabelsState,
  usePromptToUpgrade,
  useDocumentTitle,
} from "../state";
import Text from "./Text";
import DocumentLabels from "./DocumentLabels";
import { usePrompt } from "../state/prompt";
import { NAVBAR_HEIGHT } from "./NavBar";
import { isEmpty } from "lodash";
import { isFeatureEnabled, isFeatureUpgradable } from "../feature-flags";
import { BUG_REPORT_URL, SUGGEST_FEATURE_URL } from "../hooks";

const menuContainerCss = css`
  height: 100%;
  overflow: auto;
  width: 400px;
  min-width: 300px;
  box-sizing: border-box;
  border-right: 2px solid ${Colors.neutral300};
  background-color: ${Colors.neutral100};

  @media screen and (max-width: 600px) {
    position: absolute;
    z-index: 100;
    height: auto;
    top: ${NAVBAR_HEIGHT};
    bottom: 0;
  }
`;

const menuContentCss = css`
  display: flex;
  flex-direction: column;
  padding: 10px;
  box-sizing: border-box;
  height: 100%;
`;

const documentsListCss = css`
  display: flex;
  flex-direction: column;
  gap: 5px;
  margin: 0 0 20px 0;
`;

const addDocumentButtonCss = css`
  ${baseButtonCss}
  padding: 10px;
  width: 100%;
`;

const documentsListItemCss = css`
  display: flex;
  align-items: start;
  gap: 5px;
  position: relative;
  font-family: ${Typography.fontFamily};

  &:after {
    position: absolute;
    content: "";
    width: 100%;
    bottom: 0;
    border-bottom: 2px solid ${Colors.transparent};
  }
`;

const draggingCss = css`
  cursor: grabbing;
`;

const dropTargetCss = css`
  &:after {
    border-bottom-color: ${Colors.neutral400};
  }
`;

const documentLinkCss = (isDocumentSelected: boolean) => [
  css`
    ${flatButtonCss}
    cursor: pointer;
    font-weight: normal;
    font-size: 14px;
    justify-content: space-between;
    align-items: start;
    text-align: left;
    padding: 5px 10px;
    width: 100%;

    & > svg {
      flex-shrink: 0;
    }
  `,
  isDocumentSelected &&
    css`
      background-color: ${Colors.neutral200};
    `,
];

const documentMenuButtonCss = css`
  ${flatButtonCss}
  height: 30px;
`;

type DocumentListItemProps = {
  documentId: string;
};

const DocumentListItem: FC<DocumentListItemProps> = ({ documentId }) => {
  const promptToUpgrade = usePromptToUpgrade();
  const onSelectDocument = useSelectDocument();
  const onClick = useCallback(() => {
    onSelectDocument(documentId);
  }, [onSelectDocument, documentId]);
  const isDocumentSelected = useIsDocumentSelected(documentId);

  const documentTitle = useDocumentTitle(documentId);

  const [isMenuOpen, setMenuOpen] = useState(false);
  const onToggleMenu = () => setMenuOpen((b) => !b);
  const onHideMenu = () => setMenuOpen(false);

  const prompt = usePrompt();
  const listPrompt = usePrompt<string[]>();

  const renameDocument = useRenameDocument(documentId);
  const onRename = async () => {
    const result = await prompt({
      message: "What is the new title?",
      placeholder: "Page title",
      okLabel: "Update title",
      initialValue: documentTitle,
    });

    if (result.status === "ok") {
      renameDocument(result.value);
    }
  };

  const deleteDocument = useDeleteDocument();
  const onDelete = () => {
    deleteDocument(documentId);
  };

  const duplicateDocument = useDuplicateDocument(documentId);
  const onDuplicate = () => {
    onHideMenu();
    duplicateDocument();
  };

  const exportDocument = useExportDocument();
  const onExport = useCallback(async () => {
    setMenuOpen(false);

    if (isFeatureEnabled("filesystem:export")) {
      const result = await prompt({
        message: "Please choose a filename",
        initialValue: documentTitle ?? "",
        okLabel: "Export",
      });

      if (result.status === "ok" && result.value) {
        exportDocument(documentId, result.value);
      }
    } else {
      promptToUpgrade("upgrade:page:export");
    }
  }, [prompt, exportDocument, documentId, documentTitle, promptToUpgrade]);

  const [labels, setLabels] = useDocumentLabelsState(documentId);
  const hasLabels = !isEmpty(labels);

  const onEditLabels = async () => {
    const result = await listPrompt({
      message: "Edit the labels",
      placeholder: "Label",
      initialValue: labels ?? [""],
      okLabel: "Update labels",
      type: "list",
    });

    if (result.status === "ok") {
      setLabels(result.value.map((label) => label.trim()));
    }
  };

  const [{ isDragging }, drag] = useDrag({
    type: "DOCUMENT_DRAG",
    item: { movingId: documentId },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });

  const onReorderDocuments = useReorderDocuments();
  const [{ isOver }, drop] = useDrop({
    accept: "DOCUMENT_DRAG",
    drop({ movingId }: { movingId: string }) {
      onReorderDocuments(movingId, documentId);
    },
    collect: (monitor) => ({
      isOver: monitor.isOver(),
      canDrop: monitor.canDrop(),
    }),
  });

  return (
    <div
      css={[
        documentsListItemCss,
        isDragging && draggingCss,
        isOver && dropTargetCss,
      ]}
      ref={(ref) => {
        if (ref) {
          drag(ref.firstElementChild);
        }
        drop(ref);
      }}
    >
      <button css={documentLinkCss(isDocumentSelected)} onClick={onClick}>
        {documentTitle}
        <DocumentLabels labels={labels} isSelected={isDocumentSelected} />
      </button>
      <MenuContainer>
        <button
          type="button"
          css={documentMenuButtonCss}
          onClick={onToggleMenu}
        >
          <Icon path={mdiDotsHorizontal} size={0.7} />
        </button>

        {isMenuOpen && (
          <Menu align="right" onHide={onHideMenu}>
            <MenuItem
              label="Rename page"
              icon={mdiRenameBox}
              onClick={onRename}
            />
            <MenuItem
              label="Duplicate page"
              icon={mdiContentCopy}
              onClick={onDuplicate}
            />
            <MenuItem
              label={hasLabels ? "Edit labels" : "Add labels"}
              icon={mdiTagMultipleOutline}
              onClick={onEditLabels}
            />

            <MenuSeparator />

            <MenuItem
              label="Export page"
              icon={mdiExport}
              onClick={onExport}
              isUpgrade={isFeatureUpgradable("filesystem:export")}
            />
            <MenuSeparator />

            <MenuItem
              label="Delete page"
              icon={mdiTrashCanOutline}
              onClick={onDelete}
            />
          </Menu>
        )}
      </MenuContainer>
    </div>
  );
};

const FastDocumentListItem = memo(DocumentListItem);

const headerCss = css`
  color: ${Colors.neutral800};
  margin: 10px 0;
`;

const footerCss = css`
  margin-top: auto;
  padding: 20px 0;
`;

const Sidebar: FC = () => {
  const [isMenuOpen] = useMenuState();

  const documents = useDocuments();
  const addDocument = useAddDocument();

  if (!isMenuOpen) {
    return null;
  }

  return (
    <div css={menuContainerCss}>
      <div css={menuContentCss}>
        <div css={headerCss}>
          <Text type="subTitle">Pages</Text>
        </div>

        <div css={documentsListCss}>
          {documents.map((doc) => (
            <FastDocumentListItem key={doc.id} documentId={doc.id} />
          ))}
        </div>

        <button type="button" css={addDocumentButtonCss} onClick={addDocument}>
          <Icon path={mdiPlus} size={0.7} />
          Add page
        </button>

        <footer css={footerCss}>
          <Text type="fineSemibold" align="center" color={Colors.neutral400}>
            Built by Caleb Peterson
          </Text>
          <Text type="fine" align="center" color={Colors.neutral400}>
            caleb.peterson @ cubicle6 dot com
          </Text>
          <Text
            type="fine"
            align="center"
            textTransform="uppercase"
            margin="5px 0 0 0"
          >
            <a href={SUGGEST_FEATURE_URL} target="_blank" rel="noreferrer">
              Suggest a feature
            </a>
            &nbsp;&bull;&nbsp;
            <a href={BUG_REPORT_URL} target="_blank" rel="noreferrer">
              Report a bug
            </a>
          </Text>
        </footer>
      </div>
    </div>
  );
};

export default Sidebar;
