import { get, isEmpty, isFunction, uniqBy } from "lodash";

const getBuiltInName = (builtIn: any, key: string) => {
  const defaultName = get(builtIn, "name", key);
  return isEmpty(defaultName) ? key : defaultName;
};

export const getBuiltInsFromLibrary = (
  library: Record<string, any>,
  prefix: string = ""
) =>
  uniqBy(
    Object.entries(library)
      .filter(([, value]) => isFunction(value))
      .map(([key, value]) => ({
        id: `${prefix}${key}`,
        name: `${prefix}${getBuiltInName(value, key)}`,
        slug: `${prefix}${key}`,
      })),
    ({ id }) => id
  );
