import { css } from "@emotion/react";
import { toNumber } from "lodash";
import type { FormatterProps } from "react-data-grid";
import { useTableColumn } from "../../../../state";
import { TableColumnAlignment } from "../../../../types";
import { useElementId } from "../../../../components/ElementIdContext";
import { getJustifyContent } from "../utils/getJustifyContent";

const layoutCss = (align: TableColumnAlignment | undefined) => css`
  display: flex;
  align-items: center;
  justify-content: ${getJustifyContent(align)};
  height: 100%;
`;

export default function NumberFormatter<TRow, TSummaryRow>({
  row,
  column,
}: FormatterProps<TRow, TSummaryRow>) {
  const elementId = useElementId();
  const { align } = useTableColumn({ elementId, columnKey: column.key });

  return (
    <div css={layoutCss(align)}>
      {row[column.key as keyof TRow] &&
        toNumber(
          row[column.key as keyof TRow] as unknown as number
        ).toLocaleString()}
    </div>
  );
}
