import { TableColumnAlignment } from "../../../../types";

export const getJustifyContent = (align: TableColumnAlignment | undefined) => {
  if (align === "left") {
    return "flex-start";
  }

  if (align === "center") {
    return "space-around";
  }

  if (align === "right") {
    return "flex-end";
  }

  return "flex-start";
};
