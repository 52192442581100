import { useRecoilCallback, useSetRecoilState } from "recoil";
import { saveAs } from "file-saver";
import ExcelJS from "exceljs";

import { documentsState } from "./core";
import { prepareDocumentsForImport } from "../utils/prepareDocumentsForImport";
import { UnsupportedFormatError } from "../errors";
import { useShowToast } from "../toasts";
import { TableColumn, TableRow } from "../types";
import { normalizeColumnAlignment } from "../utils/normalizeColumnAlignment";
import { tableElementByIdState } from "../elements/table/state";
import { toString } from "lodash";

export const useExport = () =>
  useRecoilCallback(
    ({ snapshot }) =>
      async (filename: string) => {
        const documents = await snapshot.getPromise(documentsState);

        const content = JSON.stringify(documents, null, 2);
        const blob = new Blob([content], {
          type: "text/plain;charset=utf-8",
        });
        saveAs(blob, filename);
      },
    []
  );

export const useSetDocuments = () => useSetRecoilState(documentsState);

export const useExportDocument = () =>
  useRecoilCallback(
    ({ snapshot }) =>
      async (documentId: string, filename: string) => {
        const documents = await snapshot.getPromise(documentsState);

        const document = documents.find((doc) => doc.id === documentId);

        if (document) {
          const content = JSON.stringify(document, null, 2);
          const blob = new Blob([content], {
            type: "text/plain;charset=utf-8",
          });

          saveAs(blob, filename);
        }
      },
    []
  );

export const useImportDocuments = () =>
  useRecoilCallback(
    ({ set }) =>
      async (data: string) => {
        try {
          if (data) {
            const importedDocuments = prepareDocumentsForImport(
              JSON.parse(data)
            );
            set(documentsState, (documents) => [
              ...documents,
              ...importedDocuments,
            ]);
          }
        } catch (e: unknown) {
          console.error("Failed to import documents");
        }
      },
    []
  );

const loadTableData = async (filename: string, data: ArrayBuffer) => {
  const loader = new ExcelJS.Workbook();
  if (filename.endsWith(".xlsx") || filename.endsWith(".xls")) {
    return loader.xlsx.load(data);
  }

  throw new UnsupportedFormatError(
    `Unsupported format encountered when loading "${filename}"`
  );
};

export const useImportExcelData = (elementId: string) => {
  const showToast = useShowToast();

  return useRecoilCallback(
    ({ set }) =>
      async (filename: string, data: ArrayBuffer) => {
        const loaded = await loadTableData(filename, data);

        console.log(`Loaded: ${filename}`);
        console.log("Worksheets:", loaded.worksheets.length);

        const firstWorksheet = loaded.worksheets[0];

        if (firstWorksheet) {
          const columnsToImport: TableColumn[] = firstWorksheet.columns.map(
            (column, columnIndex) => {
              const key = column.key ?? columnIndex.toString();
              return {
                type: "string",
                key,
                name: key,
                align: normalizeColumnAlignment(column.alignment?.horizontal),
              };
            }
          );

          const rowsToImport: TableRow[] = [];
          firstWorksheet.eachRow((row) => {
            let rowToImport = {};

            row.eachCell((cell, columnNumber) => {
              rowToImport = {
                ...rowToImport,
                [columnsToImport[columnNumber - 1].key]: toString(cell.value),
              };
            });

            rowsToImport.push(rowToImport);
          });

          set(tableElementByIdState(elementId), (table) => ({
            ...table,
            columns: columnsToImport,
            data: rowsToImport,
          }));

          showToast({
            level: "success",
            message: `Successfully imported ${rowsToImport.length} rows from ${firstWorksheet.name}`,
          });
        }

        if (loaded.worksheets.length > 1) {
          showToast({
            level: "warning",
            message: `Ignored table data from ${
              loaded.worksheets.length - 1
            } worksheets`,
          });
        }
      },
    [elementId, showToast]
  );
};
