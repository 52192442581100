import { css } from "@emotion/react";
import { FC, Fragment, PropsWithChildren, useCallback, useState } from "react";
import { Icon } from "@mdi/react";
import {
  mdiRenameBox,
  mdiContentCopy,
  mdiTrashCanOutline,
  mdiFullscreen,
  mdiUnfoldMoreHorizontal,
  mdiUnfoldLessHorizontal,
  mdiHelpCircleOutline,
  mdiDotsHorizontal,
} from "@mdi/js";
import {
  baseButtonCss,
  Colors,
  Shadows,
  transition,
  Typography,
} from "../styles";
import {
  useCollapseElement,
  useDeleteElement,
  useDuplicateElement,
  useElementKind,
  useElementName,
  useExpandElement,
  useIsDocumentationAvailable,
  useIsElementCollapsed,
  useIsElementNamed,
  useOpenDocumentation,
  useRenameElement,
} from "../state";
import { getElementKindIconPath } from "../utils/getElementKindIconPath";
import Menu, { MenuContainer, MenuItem, MenuSeparator } from "./Menu";
import getElementTitle from "../utils/getElementTitle";
import { getElementKindIconColor } from "../utils/getElementKindIconColor";
import ExpandedElementModal from "./ExpandedElementModal";
import AddElementButton from "./AddElementButton";
import { ElementActionsComponent } from "./types";
import { usePrompt } from "../state/prompt";
import { ElementIdProvider } from "./ElementIdContext";
import { TooltipWrapper } from "./Tooltip";

type Props = PropsWithChildren<{
  elementId: string;
  index: number;
  MenuItems?: ElementActionsComponent;
}>;

const containerCss = css`
  width: 100%;
  border: 1px solid ${Colors.neutral300};
  border-radius: 3px;
  background-color: ${Colors.white};
  box-sizing: border-box;
  box-shadow: ${Shadows.elevation3};
`;

const layoutCss = css`
  position: relative;
`;

const headerContainerCss = (isNamed: boolean, isCollapsed: boolean) => [
  css`
    padding: 5px;
    font-size: ${Typography.smallFontSize};
    background-color: ${Colors.neutral50};
    color: ${isNamed ? Colors.neutral700 : Colors.neutral500};
    border-radius: 2px;
    display: flex;
    align-items: center;
    gap: 10px;
  `,
  !isCollapsed &&
    css`
      border-bottom: 1px solid ${Colors.neutral300};
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
    `,
];

const elementMenuContainerCss = css`
  position: absolute;
  right: -12px;
  top: -12px;
  display: flex;
  align-items: center;
  gap: 5px;
`;

const elementActionButtonCss = css`
  ${baseButtonCss}
  background-color: ${Colors.neutral100};
  color: ${Colors.neutral400};
  box-shadow: ${Shadows.elevation5};
  border: 1px solid ${Colors.neutral300};
  height: 24px;
  width: 24px;

  transition: ${transition("box-shadow", "background-color")};

  &:hover {
    box-shadow: ${Shadows.elevation6};
  }
`;

const ElementContainer: FC<Props> = ({
  elementId,
  index,
  MenuItems,
  children,
}) => {
  const prompt = usePrompt();

  const elementName = useElementName(elementId);
  const isElementNamed = useIsElementNamed(elementId);

  const [isElementMenuOpen, setElementMenuOpen] = useState(false);

  const onToggleElementMenu = () => setElementMenuOpen((b) => !b);
  const onHideElementMenu = () => setElementMenuOpen(false);

  const renameElement = useRenameElement(elementId);
  const onRenameElement = async () => {
    const result = await prompt({
      message: "What is the new name?",
      initialValue: elementName,
    });

    if (result.status === "ok" && result.value) {
      renameElement(result.value);
    }
  };

  const onDuplicateElement = useDuplicateElement(elementId);

  const deleteElement = useDeleteElement();
  const onDeleteElement = () => {
    deleteElement(elementId);
  };

  const [isMaximized, setMaximized] = useState(false);
  const onMaximize = useCallback(() => {
    setMaximized(true);
  }, []);
  const onDismissMaximized = useCallback(() => {
    setMaximized(false);
  }, []);

  const elementKind = useElementKind(elementId);

  const isElementCollapsed = useIsElementCollapsed(elementId);
  const onCollapseElement = useCollapseElement(elementId);
  const onExpandElement = useExpandElement(elementId);
  const onToggleCollapsed = isElementCollapsed
    ? onExpandElement
    : onCollapseElement;
  const toggleCollapsedIcon = isElementCollapsed
    ? mdiUnfoldMoreHorizontal
    : mdiUnfoldLessHorizontal;

  const elementActions = MenuItems ? (
    <Fragment>
      <MenuSeparator />
      <MenuItems elementId={elementId} closeMenu={onHideElementMenu} />
    </Fragment>
  ) : null;

  const isDocumentationAvailable = useIsDocumentationAvailable(elementKind);
  const openDocumentation = useOpenDocumentation();
  const onOpenDocumentation = useCallback(() => {
    openDocumentation(elementKind);
  }, [elementKind, openDocumentation]);

  return (
    <ElementIdProvider elementId={elementId}>
      {isMaximized && (
        <ExpandedElementModal
          title={getElementTitle(elementName, index)}
          onClose={onDismissMaximized}
        >
          {children}
        </ExpandedElementModal>
      )}

      <div css={layoutCss} id={elementId}>
        <div css={elementMenuContainerCss}>
          <TooltipWrapper content={isElementCollapsed ? "Expand" : "Collapse"}>
            {(childAttrs) => (
              <button
                {...childAttrs}
                css={elementActionButtonCss}
                type="button"
                onClick={onToggleCollapsed}
              >
                <Icon
                  path={toggleCollapsedIcon}
                  color={Colors.neutral600}
                  size={1}
                />
              </button>
            )}
          </TooltipWrapper>

          <TooltipWrapper content="Maximize">
            {(childAttrs) => (
              <button
                {...childAttrs}
                css={elementActionButtonCss}
                type="button"
                onClick={onMaximize}
              >
                <Icon path={mdiFullscreen} color={Colors.neutral600} size={1} />
              </button>
            )}
          </TooltipWrapper>

          {isDocumentationAvailable && (
            <TooltipWrapper content="Open documentation">
              {(childAttrs) => (
                <button
                  {...childAttrs}
                  css={elementActionButtonCss}
                  type="button"
                  onClick={onOpenDocumentation}
                >
                  <Icon
                    path={mdiHelpCircleOutline}
                    color={Colors.neutral600}
                    size={1}
                  />
                </button>
              )}
            </TooltipWrapper>
          )}

          <button
            css={elementActionButtonCss}
            type="button"
            onClick={onToggleElementMenu}
          >
            <Icon path={mdiDotsHorizontal} color={Colors.neutral600} size={1} />
          </button>

          <MenuContainer>
            {isElementMenuOpen && (
              <Menu isOffset align="right" onHide={onHideElementMenu}>
                <MenuItem
                  label="Rename"
                  icon={mdiRenameBox}
                  onClick={onRenameElement}
                />
                <MenuItem
                  label="Duplicate"
                  icon={mdiContentCopy}
                  onClick={onDuplicateElement}
                />
                {elementActions}
                <MenuSeparator />
                <MenuItem
                  label="Delete"
                  icon={mdiTrashCanOutline}
                  onClick={onDeleteElement}
                />
              </Menu>
            )}
          </MenuContainer>
        </div>

        <div css={containerCss}>
          <div css={headerContainerCss(isElementNamed, isElementCollapsed)}>
            <Icon
              path={getElementKindIconPath(elementKind)}
              color={getElementKindIconColor(elementKind)}
              size={0.7}
            />
            <div>{getElementTitle(elementName, index)}</div>
          </div>
          {!isElementCollapsed && children}
        </div>
      </div>

      <AddElementButton elementId={elementId} />
    </ElementIdProvider>
  );
};
export default ElementContainer;
