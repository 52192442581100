import { cloneDeep } from "lodash";
import { v4 as uuid4 } from "uuid";
import { Element } from "../types";

const createElementCopy = (element: Element) => ({
  ...cloneDeep(element),
  ...(element.name ? { name: `Copy of ${element.name}` } : {}),
  id: uuid4(),
});

export default createElementCopy;
