import { Element } from "../types";
import CodeElement from "../elements/code/CodeElement";
import CsvElement from "../elements/csv/CsvElement";
import FormulaElement from "../elements/formula/FormulaElement";
import JsonElement from "../elements/json/JsonElement";
import TableElement from "../elements/table/TableElement";
import TextElement from "../elements/text/TextElement";
import { ElementComponent } from "./types";
import UnknownElement from "./UnknownElement";

type UseElementComponent = (element: Element) => ElementComponent;

const useElementComponent: UseElementComponent = (element) => {
  if (element.kind === "text") {
    return TextElement;
  }

  if (element.kind === "table") {
    return TableElement;
  }

  if (element.kind === "code") {
    return CodeElement;
  }

  if (element.kind === "json") {
    return JsonElement;
  }

  if (element.kind === "csv") {
    return CsvElement;
  }

  if (element.kind === "formula") {
    return FormulaElement;
  }

  return UnknownElement;
};

export default useElementComponent;
