import { css } from "@emotion/react";
import { Colors } from "../../styles";

export const resultCss = (
  hasTopBorder: boolean = true,
  hasPadding: boolean = true
) => [
  css`
    position: relative;
    margin: 0;
    padding: 0;
    background-color: ${Colors.white};
    border-bottom-left-radius: 2px;
    border-bottom-right-radius: 2px;
    color: ${Colors.neutral700};
    overflow: auto;
  `,
  hasPadding &&
    css`
      padding: 5px;
    `,
  hasTopBorder &&
    css`
      border-top: 1px solid ${Colors.neutral300};
    `,
];

export const italicCss = css`
  font-style: italic;
`;
