import { PromptOptions } from "../../state/prompt";
import NumberEditor from "./NumberEditor";
import SelectEditor from "./SelectEditor";
import StringEditor from "./StringEditor";
import StringListEditor from "./StringListEditor";

export const getPromptEditor = (prompt: PromptOptions) => {
  if (prompt.type === "list") {
    return StringListEditor;
  }

  if (prompt.type === "select") {
    return SelectEditor;
  }

  if (prompt.type === "number") {
    return NumberEditor;
  }

  return StringEditor;
};
