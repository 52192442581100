import {
  atom,
  selectorFamily,
  useRecoilCallback,
  useRecoilValue,
} from "recoil";

import formulaMarkdown from "../docs/formula.md";
import lodashMarkdown from "../docs/lodash.md";
import { ElementKind } from "../types";

const isDocumentationOpenState = atom<boolean>({
  key: "isDocumentationOpen",
  default: false,
});

const documentationTargetState = atom<string | undefined>({
  key: "documentationTarget",
  default: undefined,
});

export const useDocumentationTarget = () =>
  useRecoilValue(documentationTargetState);

export const useOpenDocumentation = () =>
  useRecoilCallback(
    ({ set }) =>
      (elementKind: ElementKind) => {
        set(isDocumentationOpenState, true);
        set(documentationTargetState, elementKind);
      },
    []
  );

export const useToggleDocumentation = () =>
  useRecoilCallback(
    ({ set }) =>
      () => {
        set(isDocumentationOpenState, (current) => !current);
      },
    []
  );

export const useIsDocumentationOpen = () =>
  useRecoilValue(isDocumentationOpenState);

const getDocumentationURL = (source: string) => {
  if (source === "code") {
    return lodashMarkdown;
  }

  if (source === "formula") {
    return formulaMarkdown;
  }
};

export const documentationState = selectorFamily<string, string | undefined>({
  key: "documentation",
  get: (source) => async () => {
    if (!source) {
      return `Documentation unavailable`;
    }

    const url = getDocumentationURL(source);

    if (url) {
      const response = await fetch(url);

      return await response.text();
    }

    return `Documentation unavailable for ${source} elements.`;
  },
});

export const useDocumentation = (source: string | undefined) =>
  useRecoilValue(documentationState(source));

export const useDocumentationTitle = (elementKind?: ElementKind) => {
  const defaultElementKind = useDocumentationTarget();

  const kindToUse = elementKind ?? defaultElementKind;

  if (kindToUse === "code") {
    return "Code Elements";
  }

  if (kindToUse === "formula") {
    return "Formula Elements";
  }
};

const isDocumentAvailableState = selectorFamily<boolean, ElementKind>({
  key: "isDocumentAvailable",
  get: (kind) => () => {
    if (kind === "code") {
      return true;
    }

    if (kind === "formula") {
      return true;
    }

    return false;
  },
});

export const useIsDocumentationAvailable = (elementKind: ElementKind) =>
  useRecoilValue(isDocumentAvailableState(elementKind));
